import React from 'react'

import LeadInstantResponseSettings from './LeadInstantResponseSettings'
import { InstantResponseSettingsProps } from './types'
import {
  useInstantResponder,
  useSMSIntegrationData,
} from 'src/containers/Settings/InstantResponders/hooks'
import { useLocationContext } from 'src/contexts/LocationContext'
import Constants from 'src/lib/Constants'
import { formatPhoneNumber } from 'src/utils'

type SMSInstantResponseSettingsProps = InstantResponseSettingsProps

const SMSInstantResponseSettings: React.FC<SMSInstantResponseSettingsProps> = ({
  config: { isEnabled, responderId, responderName, template, sendVCard },
}) => {
  const { activeLocation, locationId } = useLocationContext()

  const { onToggle, onUpdateMessage, isToggling, isUpdatingMessage } =
    useInstantResponder({
      isEnabled,
      locationId,
      source: responderName,
      responderId,
    })
  const { isEnabled: shouldEnable } = useSMSIntegrationData({ locationId })

  let tooltipMessage = `In order to use the SMS instant response feature, you'll need your ${Constants.Branding.companyName} text marketing number. Contact our Support team at (844) 202-2015 to receive this number.`
  let leadSourceName = 'SMS instant response'
  const enableToggling =
    shouldEnable && !!activeLocation.primaryRentedPhoneNumberId

  if (enableToggling) {
    const formattedPhoneNumber = formatPhoneNumber(
      activeLocation.primaryRentedPhoneNumber!
    )

    tooltipMessage =
      'This is your text marketing number. Advertise this number, and anyone who texts you will receive your Instant Response text!'
    leadSourceName = `${leadSourceName} ${formattedPhoneNumber}`
  }

  return (
    <LeadInstantResponseSettings
      leadSourceName={leadSourceName}
      connectable={false}
      responderName={responderName}
      enabled={shouldEnable && isEnabled}
      message={template}
      onEnablementChange={onToggle}
      isEnabling={isToggling}
      onUpdateMessage={onUpdateMessage}
      isUpdatingMessage={isUpdatingMessage}
      messageLabel="Text response to incoming SMS:"
      maxLength={160}
      isToggleDisabled={!enableToggling}
      sendVCard={sendVCard}
      tooltipMessage={tooltipMessage}
      snippets={[
        {
          label: 'Location Name',
          value: `{{locationName}}`,
          tooltip: activeLocation.name,
        },
        {
          label: 'Location Phone Number',
          value: `{{locationPhoneNumber}}`,
          tooltip: formatPhoneNumber(activeLocation.phoneNumber),
        },
      ]}
    />
  )
}

export default SMSInstantResponseSettings
