import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'

import {
  useMessagingHubData,
  UseMessagingHubData,
} from 'src/contexts/MhContext/hooks'
import { UseWAFLayout, useWAFLayout } from 'src/hooks/useWAFLayout'
import { EmptyFn, EmptyPromiseFn } from 'src/utils/interfaces'

interface MHContextInterface extends UseWAFLayout, UseMessagingHubData {
  isSidedrawerOpen: boolean
}

const MhContext = createContext<MHContextInterface>({
  segments: [],
  // UseMessagingHubLayout
  contentValidColWidth: 6,
  sidebarValidColWidth: 6,
  isDesktop: false,
  isMediumDesktop: false,
  isLargeDesktop: false,
  isExtraLargeDesktop: false,

  //UseMessagingHubData
  conversationsListIsLoading: true,
  hasMoreConversations: false,
  conversations: [],
  openConversationId: undefined,
  isZeroState: false,
  loadingConversationContactDetails: false,
  openConversationContactDetails: {
    name: '',
    firstName: '',
    lastName: '',
    tagIds: [],
  },
  loadingConversationMessages: false,
  isOpenConversationChangeInFlight: false,
  messages: [],
  infoEvents: [],
  isUnreplyable: false,
  docTypeToReply: '',
  conversationListFilter: '',
  setConversationListFilter: EmptyFn,
  handleSendMessage: EmptyPromiseFn,
  refetchOpenConversationContactDetails: EmptyPromiseFn,
  getMoreConversations: EmptyPromiseFn,
  setOpenConversationId: EmptyFn,
  setIsOpenConversationChangeInFlight: EmptyFn,

  // Context-Provided values
  mutateConversationArchived: EmptyPromiseFn,
  mutateConversationRead: EmptyPromiseFn,

  // Control side contact pane open/close
  isSidedrawerOpen: true,
})

export const MhContextProvider: React.FCWithChildren = ({ children }) => {
  const { search } = useLocation()
  const layout = useWAFLayout()
  const data = useMessagingHubData()

  const [isSidedrawerManuallyOpen, setIsSidedrawerManuallyOpen] = useState(
    layout.isDesktop
  )

  /**
   * The side drawer should be forced to close if the currently open
   * conversation is the zero state or if it is an unconfirmed review
   */
  const isSidedrawerOpen = useMemo(() => {
    const { openConversationId } = data

    if (!openConversationId) {
      return false
    }

    return isSidedrawerManuallyOpen
  }, [data, isSidedrawerManuallyOpen])

  useEffect(() => {
    const searchParams = new URLSearchParams(search)

    if (searchParams.get('open-contact-profile')) {
      setIsSidedrawerManuallyOpen(
        searchParams.get('open-contact-profile') === 'true'
      )
    }
  }, [search])

  return (
    <MhContext.Provider
      value={{
        ...layout,
        ...data,
        isSidedrawerOpen,
      }}
    >
      {children}
    </MhContext.Provider>
  )
}

/**
 * Use this hook to use data and methods to manipulate
 */
const useMhContext = () => useContext(MhContext)

export default useMhContext
