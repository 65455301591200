import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useGetLocationFeatureFlag } from 'src/api'
import BusinessInfoStep from 'src/containers/Settings/Business/SmsRegistration/BusinessInfoStep'
import FinishStep from 'src/containers/Settings/Business/SmsRegistration/FinishStep'
import OverviewStep from 'src/containers/Settings/Business/SmsRegistration/OverviewStep'
import PrimaryContactInfoStep from 'src/containers/Settings/Business/SmsRegistration/PrimaryContactInfoStep'
import ReviewAndSubmitStep from 'src/containers/Settings/Business/SmsRegistration/ReviewAndSubmitStep'
import { SmsRegistrationFormProvider } from 'src/containers/Settings/Business/SmsRegistration/SmsRegistrationFormContext'
import { SmsRegistrationWizardSteps } from 'src/containers/Settings/Business/types'
import { useLocationContext } from 'src/contexts/LocationContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import Constants from 'src/lib/Constants'
import Wizard from 'src/stories/Wizard'

const ConfirmationModalBody = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.6rem',
  fontWeight: 400,
  paddingBottom: theme.space(8),
}))

const SmsRegistration: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const navigate = useNavigate()
  const { activeLocation } = useLocationContext()
  const { showModal, closeModal } = useModalNotificationsContext()

  const { data: textingEnabledFeatureFlag } = useGetLocationFeatureFlag({
    locationId: activeLocation.id,
    featureFlagName: Constants.FeatureFlags.isTextingEnabled,
    defaultValue: 0,
  })

  const smsFeatureCodeEnabled = textingEnabledFeatureFlag.value

  const nextStep = () => {
    setCurrentStep((index) => index + 1)
  }
  const previousStep = () => setCurrentStep((index) => index - 1)
  const confirmExitWizard = () => {
    if (
      [
        SmsRegistrationWizardSteps.OVERVIEW,
        SmsRegistrationWizardSteps.FINISH,
      ].includes(currentStep)
    ) {
      exitWizard()

      return
    }

    showModal({
      dataCy: 'sms-registration-unsaved-modal',
      title: 'Unsaved Changes',
      headerColor: 'accent_2',
      headerTitleFontSize: '2.2rem',
      headerTitleFontWeight: 500,

      customBody: (
        <ConfirmationModalBody>
          Are you sure you want to leave this page? Changes you made will not be
          saved.
        </ConfirmationModalBody>
      ),
      modalActionsOptions: {
        callToAction: {
          action: 'danger',
          label: 'Leave page',
          onClick: () => {
            exitWizard()
            closeModal()
          },
        },
        cancel: {
          label: 'Cancel',
          onClick: () => {
            closeModal()
          },
        },
      },
    })
  }

  const exitWizard = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const stepLabels = [
    { label: 'Overview' },
    {
      label: 'Business info',
      disabled: currentStep < SmsRegistrationWizardSteps.BUSINESS_INFO,
    },
    {
      label: 'Primary contact info',
      disabled: currentStep < SmsRegistrationWizardSteps.PRIMARY_CONTACT_INFO,
    },
    {
      label: 'Review and submit',
      disabled: currentStep < SmsRegistrationWizardSteps.REVIEW_AND_SUBMIT,
    },
  ]

  const stepContent = [
    <OverviewStep nextStep={nextStep} />,
    <BusinessInfoStep nextStep={nextStep} previousStep={previousStep} />,
    <PrimaryContactInfoStep nextStep={nextStep} previousStep={previousStep} />,
    <ReviewAndSubmitStep
      nextStep={nextStep}
      previousStep={previousStep}
      goToStep={(step: SmsRegistrationWizardSteps) => setCurrentStep(step)}
    />,
    <FinishStep nextStep={exitWizard} />,
  ]

  /**
   * Abort wizard if exists a previous sms registration process,
   * avoiding redirection when wizard is active and registrationStatus
   * has been updated.
   */
  useEffect(() => {
    if (
      activeLocation.registrationStatus &&
      currentStep < SmsRegistrationWizardSteps.REVIEW_AND_SUBMIT
    ) {
      exitWizard()
    }
  }, [activeLocation, currentStep, exitWizard])

  if (
    smsFeatureCodeEnabled &&
    activeLocation.registrationStatus &&
    currentStep < SmsRegistrationWizardSteps.REVIEW_AND_SUBMIT
  ) {
    return null
  }

  return (
    <Wizard
      baseDateAttribute="sms-registration"
      title="SMS Registration"
      onClickGoBack={confirmExitWizard}
      steps={stepLabels}
      currentStepIndex={currentStep}
      onClickStep={(index, step) => setCurrentStep(index)}
      disabled={currentStep === stepLabels.length}
      disableGoBack={currentStep === SmsRegistrationWizardSteps.FINISH}
    >
      <SmsRegistrationFormProvider>
        {stepContent[currentStep]}
      </SmsRegistrationFormProvider>
    </Wizard>
  )
}

export default SmsRegistration
