import { add, isBefore } from 'date-fns'
import { useMemo } from 'react'
import { useMatch, useParams } from 'react-router-dom'

import { Location, useGetLocationFeatureFlag } from 'src/api'
import { LocationInterface } from 'src/client/interfaces/Common'
import Constants from 'src/lib/Constants'
import { SidebarRoutes } from 'src/stories/Sidebar'
import {
  generateLegacyLocationUrl,
  generateLocationUrl,
  isFeatureCodeEnabled,
} from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'

const showSubscriptionTab = (location: LocationInterface) => {
  if (
    isFeatureCodeEnabled(
      Constants.FEATURE_CODES.liveReceptionistOnlyUX,
      location
    )
  ) {
    return false
  } else if (
    isFeatureCodeEnabled(Constants.showSubscriptionTabFeaturedCode, location)
  ) {
    return true
  }

  const { agencyId, accountType, firstActivatedAt } = location

  const isLocalAccount = accountType === Constants.ACCOUNT_TYPES.LOCAL
  const isNationalAccount =
    accountType === Constants.ACCOUNT_TYPES.NATIONAL_TIER_1 ||
    accountType === Constants.ACCOUNT_TYPES.NATIONAL_TIER_2

  return (
    isBefore(
      add(new Date(firstActivatedAt), {
        days: Constants.SUBSCRIPTIONS.activeDaysRequiredToRequestCancellation,
      }),
      new Date()
    ) &&
    !agencyId &&
    (isNationalAccount || isLocalAccount)
  )
}

export const useSidebarRoutes = (locationId: number, locations: Location[]) => {
  const { data: textingEnabledFeatureFlag } = useGetLocationFeatureFlag({
    locationId,
    featureFlagName: Constants.FeatureFlags.isTextingEnabled,
    defaultValue: 0,
  })
  const isTextingEnabled = textingEnabledFeatureFlag.value === 1

  const settingSidebarRoutes: SidebarRoutes[] = useMemo<SidebarRoutes[]>(() => {
    const accountRoutes = [
      {
        to: generateLocationUrl(locationId, '/settings/business'),
        label: 'Business',
      },
    ]

    const billingRoutes = [
      {
        to: generateLocationUrl(locationId, '/settings/billing'),
        label: 'Billing',
      },
    ]

    const locationRoutes = [
      {
        to: generateLocationUrl(locationId, '/settings/team'),
        label: 'Team',
      },
      ...(isTextingEnabled
        ? [
            {
              to: generateLocationUrl(locationId, '/settings/phone'),
              label: 'Phone',
            },
          ]
        : []),
      {
        to: generateLocationUrl(locationId, '/settings/messaging-hub'),
        label: 'Messaging Hub',
      },
      ...(isTextingEnabled
        ? [
            {
              to: generateLocationUrl(locationId, '/settings/integrations'),
              label: 'Integrations',
            },
          ]
        : []),
      ...(isTextingEnabled ? [] : []),
    ]

    const outreachRoutes = [
      ...(isTextingEnabled
        ? [
            {
              to: generateLocationUrl(
                locationId,
                '/settings/instant-responders'
              ),
              label: 'Instant Responders',
            },
          ]
        : []),
    ]

    return [
      { groupTitle: 'Account', routes: accountRoutes },
      { groupTitle: 'Location', routes: locationRoutes },
      ...(isTextingEnabled
        ? [{ groupTitle: 'Outreach', routes: outreachRoutes }]
        : []),
      { groupTitle: 'Billing', routes: billingRoutes },
    ]
  }, [locationId, isTextingEnabled])

  return { settingSidebarRoutes }
}

/** @deprecated */
const useLegacySidebarRoutes = ({
  merchantId,
  locationId,
  locations,
  consumerTerms: { singularCaps: singularConsumerTerm },
}: {
  merchantId: number
  locationId: number
  locations?: LocationInterface[]
  consumerTerms: {
    plural: string
    singular: string
    pluralCaps: string
    singularCaps: string
  }
}) => {
  const campaignSidebarRoutes = useMemo<SidebarRoutes[]>(
    () => [
      {
        groupTitle: 'Engage Your Contacts',
        routes: [
          {
            to: generateLegacyLocationUrl(
              merchantId,
              locationId,
              '/campaigns/content-library?type=offer'
            ),
            label: 'Choose Pre-Designed Campaign',
            button: true,
            buttonProps: { allowLineBreak: true },
          },
          {
            to: generateLegacyLocationUrl(
              merchantId,
              locationId,
              '/campaigns/create-your-own/new?type=custom'
            ),
            label: 'Create your own campaign',
            button: true,
            buttonProps: { outline: true, allowLineBreak: true },
          },
        ],
      },
      {
        routes: [
          {
            to: generateLegacyLocationUrl(
              merchantId,
              locationId,
              '/campaigns/all'
            ),
            label: 'All Campaigns',
          },
        ],
      },
      ...(localStorage.getItem('SP_HAS_CONVERSION_CAMPAIGN_PAGE')
        ? [
            {
              groupTitle: 'Conversion campaigns',
              routes: [
                {
                  isNew: true,
                  to: generateLegacyLocationUrl(
                    merchantId,
                    locationId,
                    '/campaigns/conversion'
                  ),
                  label: 'Manage campaigns',
                },
              ],
            },
          ]
        : []),
      {
        groupTitle: 'One-time Campaigns',
        routes: [
          {
            to: generateLegacyLocationUrl(
              merchantId,
              locationId,
              '/scheduled-campaigns/scheduled'
            ),
            label: 'Scheduled',
          },
          {
            to: generateLegacyLocationUrl(
              merchantId,
              locationId,
              '/scheduled-campaigns/drafts'
            ),
            label: 'Draft',
          },
          {
            to: generateLegacyLocationUrl(
              merchantId,
              locationId,
              '/scheduled-campaigns/sent'
            ),
            label: 'Sent',
          },
        ],
      },
      {
        groupTitle: 'Ongoing Campaigns',
        routes: [
          {
            to: generateLegacyLocationUrl(
              merchantId,
              locationId,
              '/campaigns/lifecycle/prospect'
            ),
            label: `New ${singularConsumerTerm} Campaigns`,
          },
          {
            to: generateLegacyLocationUrl(
              merchantId,
              locationId,
              '/campaigns/lifecycle/active'
            ),
            label: `Active ${singularConsumerTerm} Campaigns`,
          },
          {
            to: generateLegacyLocationUrl(
              merchantId,
              locationId,
              '/campaigns/lifecycle/inactive'
            ),
            label: 'Win Back Campaigns',
          },
        ],
      },
    ],
    [merchantId, locationId, singularConsumerTerm]
  )

  const settingSidebarRoutes = useMemo<SidebarRoutes[]>(() => {
    const accountRoutes = [
      {
        to: generateLegacyLocationUrl(
          merchantId,
          locationId,
          '/settings/business'
        ),
        label: 'Business',
      },
      {
        to: generateLegacyLocationUrl(
          merchantId,
          locationId,
          '/settings#section-settings-email'
        ),
        label: 'Email',
      },
      {
        to: generateLegacyLocationUrl(
          merchantId,
          locationId,
          '/settings#section-settings-phone'
        ),
        label: 'Phone',
      },
      {
        to: generateLegacyLocationUrl(
          merchantId,
          locationId,
          '/email-customization'
        ),
        label: 'Customization',
      },
    ]

    const outreachRoutes = [
      {
        to: generateLegacyLocationUrl(
          merchantId,
          locationId,
          '/settings/review-sites'
        ),
        label: 'Review Sites',
      },
      {
        to: generateLegacyLocationUrl(
          merchantId,
          locationId,
          '/settings#section-settings-instant-responders'
        ),
        label: 'Instant Responders',
      },
    ]

    const billingRoutes = []

    if (locations) {
      const activeLocation = locations.find((l) => l.locationId === +locationId)

      if (activeLocation) {
        if (showSubscriptionTab(activeLocation)) {
          billingRoutes.push({
            to: generateLegacyLocationUrl(
              merchantId,
              locationId,
              '/settings#section-settings-subscriptions'
            ),
            label: 'Subscriptions',
          })
        }

        const hasActiveLRAccount = !!activeLocation?.securusPhoneNumber

        if (hasActiveLRAccount) {
          billingRoutes.push({
            to: generateLegacyLocationUrl(
              merchantId,
              locationId,
              '/settings/billing'
            ),
            label: 'Billing',
          })
        }
      }
    }

    return [
      { groupTitle: 'Account', routes: accountRoutes },
      { groupTitle: 'Outreach', routes: outreachRoutes },
      { groupTitle: 'Billing', routes: billingRoutes },
    ]
  }, [merchantId, locationId, locations])

  const reviewsSidebarRoutes = useMemo<SidebarRoutes[]>(
    () => [
      {
        groupTitle: 'Campaigns',
        routes: [
          {
            to: generateLegacyLocationUrl(
              merchantId,
              locationId,
              '/emails/review-us'
            ),
            label: 'Reviews',
          },
          {
            to: generateLegacyLocationUrl(
              merchantId,
              locationId,
              '/emails/get-feedback'
            ),
            label: 'Feedback',
          },
        ],
      },
    ],
    [merchantId, locationId]
  )

  return {
    campaignSidebarRoutes,
    settingSidebarRoutes,
    reviewsSidebarRoutes,
  }
}

export const useLocationRouteParams = (): Partial<UseLocationRouteParams> => {
  let { locationId, merchantId } = useParams<UseLocationRouteParams>()
  const pathMatch = useMatch('/:merchantId/locations/:locationId/*')

  return {
    locationId: locationId || pathMatch?.params.locationId || '',
    merchantId: merchantId || pathMatch?.params.merchantId || '',
  }
}

export default useLegacySidebarRoutes
