import styled from 'styled-components'

import { ResponderName } from 'src/api'

const ReviewSites = {
  YELP: {
    id: 1,
    name: 'Yelp',
    displayName: 'Yelp',
  },
  GOOGLE: {
    id: 2,
    name: 'Google+',
    displayName: 'Google',
  },
  FACEBOOK: {
    id: 4,
    name: 'Facebook',
    displayName: 'Facebook',
  },
} as const

const customerSourceType = {
  AD_PARTNER: 'ad_partner',
  AGENTZ: 'agentz',
  APPOINTMENT: 'appointment',
  CLOVER: 'clover',
  CONSTANT_CONTACT: 'constant_contact',
  EMAIL_SYNC: 'email_sync',
  FACEBOOK: 'facebook',
  FACEBOOK_ADS: 'facebook_ads',
  FRESHBOOKS: 'freshbooks',
  GOOGLE_MESSAGES: 'google_messages',
  HOMEADVISOR: 'homeadvisor',
  HOUSECALL_PRO: 'housecall_pro',
  JOBNIMBUS: 'jobnimbus',
  LANDING_PAGE: 'landing_page',
  LEAP: 'leap',
  MAILCHIMP: 'mailchimp',
  MANUAL_ADD: 'manual_add',
  PAYPAL: 'paypal',
  PHONE_FORWARDING: 'phone_forwarding',
  PREPAID_OFFER: 'prepaid_offer',
  PUBLIC_API: 'public_api',
  PUBLIC_API_INTEGRATION: 'public_api_integration',
  QUICKBOOKS_DESKTOP: 'quickbooks_desktop',
  QUICKBOOKS_ONLINE: 'quickbooks_online',
  REFERRAL: 'referral',
  SQUARE: 'square',
  STRIPE: 'stripe',
  SYNCHROTEAM: 'synchroteam',
  TRANSACTION_SYNC: 'transaction_sync',
  UPLOADED_LIST: 'uploaded_list',
  ZAPIER: 'zapier',
  THUMBTACK: 'THUMBTACK',
  LIVE_RECEPTIONIST: 'live_receptionist',
}

const StyledSignpostName = styled.strong(({ theme }) => ({
  fontWeight: 500,
  color: theme.colors.primary_2,
}))

// From https://github.com/signpost/glaze/blob/4b3d7786b9affd2cc0b6d0c0f0fc3f253693fdba/app/jsx/Filter.jsx#L209-L238
const mappedCustomerSourceTypes = {
  [customerSourceType.AD_PARTNER]: {
    displayName: 'Ad Partner',
  },
  [customerSourceType.AGENTZ]: {
    displayName: 'Signpost Chat',
    iconName: 'webchat',
  },
  [customerSourceType.APPOINTMENT]: {
    displayName: 'Appointments',
    iconName: 'appointments',
  },
  [customerSourceType.CLOVER]: {
    displayName: 'Clover',
    iconName: 'clover',
  },
  [customerSourceType.CONSTANT_CONTACT]: {
    displayName: 'Constant Contact',
    iconName: 'constant-contact',
  },
  [customerSourceType.EMAIL_SYNC]: {
    displayName: 'Email Sync',
    iconName: 'email',
  },
  [customerSourceType.FACEBOOK]: {
    displayName: 'Facebook',
    iconName: 'facebook',
  },
  [customerSourceType.FACEBOOK_ADS]: {
    displayName: 'Facebook',
    iconName: 'facebook',
  },
  [customerSourceType.FRESHBOOKS]: {
    displayName: 'FreshBooks',
    iconName: 'freshbooks',
  },
  [customerSourceType.GOOGLE_MESSAGES]: {
    displayName: 'Google Messages',
    iconName: 'google',
  },
  [customerSourceType.HOMEADVISOR]: {
    displayName: 'Angi',
    iconName: 'angi-leads',
  },
  [customerSourceType.HOUSECALL_PRO]: {
    displayName: 'Housecall Pro',
    iconName: 'housecallpro',
  },
  [customerSourceType.JOBNIMBUS]: {
    displayName: 'JobNimbus',
    iconName: 'jobnimbus',
  },
  [customerSourceType.LANDING_PAGE]: {
    displayName: 'Offer Landing Page',
    iconName: 'offer-landing-page',
  },
  [customerSourceType.LEAP]: {
    displayName: 'Leap',
    iconName: 'leap',
  },
  [customerSourceType.MAILCHIMP]: {
    displayName: 'Mailchimp',
    iconName: 'mailchimp',
  },
  [customerSourceType.MANUAL_ADD]: {
    displayName: 'Manually Added',
    iconName: 'manually-added',
  },
  [customerSourceType.PAYPAL]: {
    displayName: 'PayPal',
    iconName: 'paypal',
  },
  [customerSourceType.PHONE_FORWARDING]: {
    displayName: 'Phone Forwarding',
    iconName: 'phone-forwarding',
  },
  [customerSourceType.PREPAID_OFFER]: {
    displayName: 'Prepaid Offer',
  },
  [customerSourceType.PUBLIC_API]: {
    displayName: 'Public API',
    iconName: 'public-api',
  },
  [customerSourceType.PUBLIC_API_INTEGRATION]: {
    displayName: 'API',
    iconName: 'public-api',
  },
  [customerSourceType.QUICKBOOKS_DESKTOP]: {
    displayName: 'QuickBooks Desktop',
    iconName: 'quickbooks',
  },
  [customerSourceType.QUICKBOOKS_ONLINE]: {
    displayName: 'QuickBooks Online',
    iconName: 'quickbooks',
  },
  [customerSourceType.REFERRAL]: {
    displayName: 'Referral',
    iconName: 'referral',
  },
  [customerSourceType.SQUARE]: {
    displayName: 'Square',
    iconName: 'square',
  },
  [customerSourceType.STRIPE]: {
    displayName: 'Stripe',
    iconName: 'stripe',
  },
  [customerSourceType.SYNCHROTEAM]: {
    displayName: 'Synchroteam',
    iconName: 'synchroteam',
  },
  [customerSourceType.TRANSACTION_SYNC]: {
    displayName: 'Transaction Sync',
    iconName: 'transaction',
  },
  [customerSourceType.UPLOADED_LIST]: {
    displayName: 'Uploaded List',
    iconName: 'uploaded-list',
  },
  [customerSourceType.ZAPIER]: {
    displayName: 'Zapier',
    iconName: 'zapier',
  },
  [customerSourceType.THUMBTACK]: {
    displayName: 'Thumbtack',
    iconName: 'thumbtack',
  },
  [customerSourceType.LIVE_RECEPTIONIST]: {
    displayName: (
      <>
        Phone (answered by <StyledSignpostName>Signpost</StyledSignpostName>)
      </>
    ),
    iconName: 'phone',
  },
}

const CommDocumentTypes = {
  smsInteraction: 'SmsInteraction',
  remarketingSms: 'RemarketingSms',
  homeAdvisorInteraction: 'HomeAdvisorInteraction',
  agentzInteraction: 'AgentzInteraction',
  agentzComm: 'AgentzComm',
  facebookInteraction: 'FacebookInteraction',
  facebookComm: 'FacebookComm',
  phoneCallInteraction: 'PhoneCallInteraction',
  googleMessagesComm: 'GoogleMessagesComm',
  googleMessagesInteraction: 'GoogleMessagesInteraction',
  thumbtackInteraction: 'ThumbtackInteraction',
  liveReceptionistInteraction: 'LiveReceptionistInteraction',
  review: 'Review',
  feedback: 'Feedback',
}

const accountManagers: Record<number, { source: string }> = {
  888197197: {
    source: 'https://booksignpost.youcanbook.me/?team=Curtis+Cooper',
  },
  888206863: {
    source: 'https://booksignpost.youcanbook.me/?team=Claire+Driscoll',
  },
  888211720: {
    source: 'https://booksignpost.youcanbook.me/?team=Lee+Harrison',
  },
  888206862: {
    source: 'https://booksignpost.youcanbook.me/?team=Chelsea+Slate',
  },
  888206861: {
    source: 'https://booksignpost.youcanbook.me/?team=Tyler+Moehlman',
  },
  888207441: {
    source: 'https://booksignpost.youcanbook.me/?team=Jamie+White',
  },
  888204102: {
    source: 'https://booksignpost.youcanbook.me/?team=Grant+Bell',
  },
  0: {
    source: 'https://booksignpost.youcanbook.me/',
  },
}

const Constants = {
  FeatureFlags: {
    isTextingEnabled: 'is_texting_enabled',
  },
  InstantResponders: {
    sms: {
      defaultMessage:
        'Hi, this is {{locationName}}. Thank you for reaching out about our services. ' +
        'We will call or text you back soon. Reply STOP to unsubscribe.',
    },
    'angi-leads': {
      defaultMessage:
        'Hi, this is {{locationName}}. Thank you for reaching out about our services. ' +
        'We will call or text you back soon. Reply STOP to unsubscribe.',
    },
  } as Record<ResponderName, { defaultMessage: string }>,
  Branding: {
    companyName: 'Signpost',
    companyPhone: '8442022015',
    companySupportEmail: 'ccsupport@signpost.com',
    companyMHUsageLink:
      'https://help.signpost.com/kb-search-results?term=message+hub',
    VideoLinks: {
      welcomeFreeTrial: 'https://www.youtube.com/embed/YANMIzSh_AI',
      welcome: 'https://www.youtube.com/embed/UnnNqsjGfjs',
      setUpThumbtackIR: 'https://www.youtube.com/embed/VTuYdhIFyqI',
      exploreMHFeatures: 'https://www.youtube.com/embed/YwzzdlTL4EI',
      discoverMHCallSummaries: 'https://www.youtube.com/embed/ApLcita3u7c',
      welcomeAccount:
        'https://www.youtube.com/embed/uywTRTFRn-Y?si=z_7d1RVfbD2ekBqx',
    },
    copyRightText: `Signpost, Inc. All Rights Reserved. Signpost™ is property of Signpost, Inc. All other marks are the property of their respective owners.`,
  },
  conversationEvents: {
    maxLimitDaysToReply: {
      FACEBOOK_EVENT: 1,
    },
  },
  links: {
    enableTextingBooking: 'https://signpostmessaging.youcanbook.me/',
  },

  /** @deprecated */
  ReviewSites,
  /** @deprecated */
  CoreReviewSites: [
    ReviewSites.YELP.id,
    ReviewSites.GOOGLE.id,
    ReviewSites.FACEBOOK.id,
  ],

  /** @deprecated */
  RedirectUrls: {
    scheduleACall:
      'https://meetings.hubspot.com/matt-packard/product-in-app-software-booker',
  },

  /** @deprecated */
  FooterLinks: [
    {
      text: '➜ Terms & Conditions',
      url: 'https://terms.signpost.com',
      dataAttribute: 'footer-terms-and-conditions-link',
      openInNewTab: true,
    },
  ],

  /** @deprecated */
  Backend: {
    Endpoints: {
      CORE_CLIENT_PROXY: '/web-app-frontend-proxy',
      BETA_FLAGS: '/web-app-frontend-proxy/beta-flags',
      /** Requests to this endpoint do not occur, Cypress intercepts them and stubs a response */
      CYPRESS_DONE_ENDPOINT: '/web-app-frontend-proxy/cypress-done',
      LAMBDA_PROXY: '/web-app-frontend-proxy/lambda-proxy',
      MEDIA_UPLOAD_URLS: '/web-app-frontend-proxy/media-upload-urls',
      GET_VIDEO_FILE: '/web-app-frontend-proxy/get-video-file',
    },
  },

  /** @deprecated */
  HeaderKeys: {
    rememberMeSessionIdKey: 'x-signpost-remember-me-session-id',
    sessionIdKey: 'x-signpost-session-id',
    bearer: 'authorization',
  },

  /** @deprecated */
  MerchantCenter: {
    Pages: {
      ALL_CAMPAIGNS: 'allCampaigns',
      CONVERSION_CAMPAIGNS: 'conversionCampaigns',
      CAMPAIGN_CONTENT: 'campaignContent',
      NEWSLETTER_CONTENT: 'newsletterContent',
      CAMPAIGNS: 'campaigns',
      CAMPAIGNS_DRAFT: 'campaignsDraft',
      CAMPAIGNS_SCHEDULED: 'campaignsScheduled',
      CAMPAIGNS_SENT: 'campaignsSent',
      CONTACTS: 'contacts',
      CONTENT_LIBRARY: 'contentLibrary',
      CONTENT_LIBRARY_COVID: 'contentLibraryCovid',
      CONTENT_LIBRARY_HOLIDAY: 'contentLibraryHoliday',
      CONTENT_LIBRARY_HOME_SERVICES_HOLIDAY:
        'contentLibraryHomeServicesHoliday',
      DASHBOARD: 'dashboard',
      INSIGHTS: 'insights',
      FEATURED_NEWSLETTER: 'featuredNewsletter',
      FEEDBACK: 'feedback',
      INTEGRATIONS: 'integrations',
      INVOICES: 'invoices',
      LOCATION_REVIEWS: 'locationReviews',
      BUSINESS_SETTINGS: 'businessSettings',
      SMS_REGISTRATION: 'smsRegistration',
      EMAIL_SETTINGS: 'emailSettings',
      PHONE_SETTINGS: 'phoneSettings',
      SUBSCRIPTION_SETTINGS: 'subscriptionSettings',
      BILLING_SETTINGS: 'billingSettings',
      INSTANT_RESPONDERS_SETTINGS: 'instantRespondersSettings',
      CONNECT_THUMBTACK: 'connectThumbtack',
      CUSTOMIZATION: 'customization',
      LOCATIONS: 'locations',
      NCO: 'newCustomerOffer',
      REVIEWS: 'reviews',
      TEXT_INBOX: 'textInbox',
      USER_ACCOUNT_SETTINGS: 'userAccountSettings',
      USER_EMAIL_SETTINGS: 'userEmailSettings',
      MESSAGING_HUB: 'messagingHub',
      OFFER_CONTENT: 'offerContent',
      NCC_CAMPAIGNS: 'nccCampaigns',
      ACC_CAMPAIGNS: 'accCampaigns',
      WBC_CAMPAIGNS: 'wbcCampaigns',

      ContactFilter: {
        COMPLETED_GOAL: 'contactsCompletedGoal',
        RECEIVED_CAMPAIGN: 'contactsReceivedCampaign',
        COMPLETED_NEW_CONTACT_GOAL: 'contactsCompletedNewContactGoal',
      },
      NewsletterWizard: {
        CONTENT: 'newsletterWizardContent',
        OFFER: 'newsletterWizardOffer',
        GOAL: 'newsletterWizardGoal',
        AUDIENCE_AND_SCHEDULE: 'newsletterWizardAudienceAndSchedule',
        PREVIEW: 'newsletterWizardPreview',
        REVIEW: 'newsletterWizardReview',
      },
      OtcWizard: {
        CONTENT: 'otcWizardContent',
        AUDIENCE: 'otcWizardAudience',
        SCHEDULE: 'otcWizardSchedule',
        PREVIEW: 'otcWizardPreview',
        REVIEW: 'otcWizardReview',
      },
    },

    RedirectTypes: {
      LOCATION: 'Location',
      USER: 'User',
    },
  },

  /** @deprecated */
  ACCOUNT_TYPES: {
    AGENCY: 'agency',
    LOCAL: 'local',
    NATIONAL_TIER_1: 'national_tier1',
    NATIONAL_TIER_2: 'national_tier2',
    PARTNERSHIP: 'external_partnership',
    FRANCHISE_CORPORATE: 'franchise_corporate',
  },

  /** @deprecated */
  ACCOUNT_MANAGERS: accountManagers,

  /** @deprecated */
  TOS: {
    releaseDate: '2020-02-03T02:00:00.000Z',
  },

  /** @deprecated */
  CUSTOMERS: {
    LifecycleStage: {
      LEAD: { label: 'Prospective Customer', value: 'LEAD' },
      ACTIVE_CUSTOMER: { label: 'Active Customer', value: 'ACTIVE_CUSTOMER' },
      INACTIVE_CUSTOMER: {
        label: 'Inactive Customer',
        value: 'INACTIVE_CUSTOMER',
      },
      UNHAPPY_CONTACT: { label: 'Unhappy Customer', value: 'UNHAPPY_CONTACT' },
      UNKNOWN: { label: 'Unknown Customer', value: 'UNKNOWN' },
    },
    SourceType: customerSourceType,
    MappedSourceTypes: mappedCustomerSourceTypes,
  },

  /** @deprecated */
  facebookReservedSegmentName: 'facebook leads',

  userHiddenNavbarPages: ['settings', 'email-subscription'],
  locationsHiddenNavbarPages: [
    'testimonials',
    'integrations/connect',
    'settings/business/sms-registration',
  ],

  /** @deprecated */
  showSubscriptionTabFeaturedCode: 'should_allow_immediate_request_to_cancel',

  // This is a provisional list since it is being segmented for now
  /** @deprecated */
  thumbtackUserIds: [
    888194471, 888206209, 888197665, 888205863, 888197968, 888187653, 888200564,
    888194973, 888217152, 888203977, 888180692, 888203564, 888167253, 888217791,
    888151880, 888189263, 888164691, 888202944, 888165370, 888197980, 888203632,
    888177968, 521951, 888185194, 362441, 888199073, 888205415, 888212890,
    888184413, 888180432, 888212218, 888211885, 888174999, 888192586, 888191384,
    888131531, 888168798, 888184729, 888188493, 888191265, 888196304, 888212061,
    888207026, 888189859, 888176641, 888144690, 888194655, 888177204, 888203800,
    888126583, 888204354, 888191523, 888192877, 888204357, 888183141, 888158234,
    503051, 888170248, 888185081,
  ],

  /** @deprecated */
  facebookLoginPermissions: [
    'pages_manage_metadata', // subscribe pages to webhooks (also publishing)
    'pages_manage_posts', // post one-time campaigns
    'pages_messaging', // messaging
    'pages_read_user_content', // capture reviews
    'pages_show_list', // subscribe pages to webhooks
  ],

  /** @deprecated */
  INTEGRATIONS: {
    AGENTZ: 'AGENTZ',
    FACEBOOK: 'FACEBOOK',
    GOOGLE_MESSAGES: 'GOOGLE_MESSAGES',
    HOMEADVISOR: 'HOMEADVISOR',
    THUMBTACK: 'THUMBTACK',
    SMS: 'SMS',
    CORE_DATA: [
      'QUICKBOOKS_ONLINE',
      'QUICKBOOKS_DESKTOP',
      'SQUARE',
      'PAYPAL',
      'STRIPE',
      'FRESHBOOKS',
      'HOUSECALL_PRO',
      'LEAP',
    ],
    STATUS: {
      ACTIVE: 'active',
      ERROR: 'error',
      INACTIVE: 'inactive',
      PENDING: 'pending',
    },
  },

  /** @deprecated - use FeatureFlags instead */
  FEATURE_CODES: {
    // Note: can_upgrade_directly_from_trial is always on for free trial, we're using
    // it in a way that arguably violates the general rule we've had for
    // feature codes:
    // As a general catch-all for small free-trial-specific UI differences.
    // The rule has been that each feature code controls one behavior.
    // We're accepting it as a short-cut for the experimental trial project,
    // but we should be careful about extending that pattern.
    canSeeFreeTrialFomoScreen: 'can_see_free_trial_fomo_screen',
    canUpgradeDirectlyFromTrial: 'can_upgrade_directly_from_trial',
    googleIntegration: 'google_integration',
    facebookIntegration: 'facebook_integration',
    syncHomeadvisor: 'sync_homeadvisor',
    missedCallAutoresponse: 'send_missed_call_autoresponse',
    sendFacebookAutoresponse: 'send_facebook_autoresponse',
    sendHomeadvisorAutoresponse: 'send_homeadvisor_followup_sms',
    sendSMSAutoresponse: 'send_sms_autoresponse',
    syncThumbtack: 'sync_thumbtack',
    sendThumbtackAutoresponse: 'send_thumbtack_autoresponse',
    canSyncGoogleMessages: 'can_sync_google_messages',
    syncGoogleMessages: 'sync_google_messages',
    sendGoogleMessagesAutoresponse: 'send_google_messages_autoresponse',
    liveReceptionistOnlyUX: 'should_show_livereceptionist_only_ux',
    publishPresence: 'publish_presence',
    ivr: 'ivr',
    publishForwardingNumber: 'publish_forwarding_number',
    enableVoiceMail: 'enable_signpost_voicemail',
  },

  /** @deprecated */
  SUBSCRIPTIONS: {
    activeDaysRequiredToRequestCancellation: 90,
  },

  /** @deprecated */
  COMMS: {
    documentTypes: CommDocumentTypes,
    blockedAreaCodeReason: 'BLOCKLISTED_AREA_CODE',
    failedDeliveryReason: 'FAILED',
    interactionTypes: {
      liveSupportOutgoingMessage: 'LIVE_SUPPORT_OUTGOING_MESSAGE',
      liveSupportRequest: 'LIVE_SUPPORT_REQUEST',
      liveSupportMessage: 'LIVE_SUPPORT_MESSAGE',
      reviewUs: 'Review Us',
      consumerFeedback: 'Consumer Feedback',
    },

    /**
     * Document types that (1) are guaranteed to have a phone channel,
      and (2) replies to them in the Messaging Hub should use SMS.
     */
    smsDocumentTypes: [
      CommDocumentTypes.smsInteraction,
      CommDocumentTypes.remarketingSms,
      CommDocumentTypes.homeAdvisorInteraction,
      CommDocumentTypes.liveReceptionistInteraction,
      CommDocumentTypes.thumbtackInteraction,
      CommDocumentTypes.feedback,
      CommDocumentTypes.review,
    ],
    /**
     Document types where outoing reply comms should ideally use Agentz.
     */
    agentzDocumentTypes: [
      CommDocumentTypes.agentzComm,
      CommDocumentTypes.agentzInteraction,
    ],
    /**
     Document types where outoing reply comms should ideally use Facebook.
     */
    facebookDocumentTypes: [
      CommDocumentTypes.facebookComm,
      CommDocumentTypes.facebookInteraction,
    ],
    /**
     Document types where outoing reply comms should ideally use Google Messages.
     */
    googleMessagesDocumentTypes: [
      CommDocumentTypes.googleMessagesComm,
      CommDocumentTypes.googleMessagesInteraction,
    ],
    /**
     Note: We added liveReceptionistDocumentTypes for similarity with other
     interaction types, but as of 2022-08-30, it's not actually used.
     Instead liveReceptionistInteraction is included in smsDocumentTypes
     like homeAdvisorInteraction. So we might be able to delete this array.
     */
    liveReceptionistDocumentTypes: [
      CommDocumentTypes.liveReceptionistInteraction,
    ],
    fakeConversationalTypes: {
      unconfirmedReviews: 'unconfirmedReviewInteraction',
    },
    smsTypes: {
      individual: 'individual',
      serviceRequestFollowup: 'service-request-followup',
      missedCallAcknowledgement: 'missed-call-acknowledgment',
      leadAutoresponse: 'lead-autoresponse',
    },
    commTypes: {
      leadAutoResponse: 'LEAD_AUTORESPONSE',
    },
    manualFeedbackTypes: ['manual-feedback'],
    manualReviewTypes: [
      'manual-review-us-google',
      'manual-review-us-facebook',
      'manual-review-us-yelp',
    ],
    ignoredDocumentTypes: ['Feedback', 'Review'],
    maxLimitDaysToReply: {
      facebook: 1,
      googleMessages: 3,
    },
  },

  /** @deprecated */
  MOBILE_APP: {
    googlePlayUrl:
      'https://play.google.com/store/apps/details?id=com.signpost.mobile.production',
    appStoreUrl: 'https://apps.apple.com/us/app/signpost-mobile/id1527032804',
    schemes: {
      dev: 'signpost-dev',
      production: 'signpost',
    },
  },

  /** @deprecated */
  USER_ROLES: {
    merchant: 'ROLE_MERCHANT_USER',
    admin: 'ROLE_ADMIN',
    dev: 'ROLE_DEV',
    accountManager: 'ROLE_ACCOUNT_MANAGER',
    publicationManager: 'ROLE_PUBLICATION_MANAGER',
    salesTeam: 'ROLE_SALES_TEAM',
    csLeader: 'ROLE_CS_LEADER',
    marketingConfigManager: 'ROLE_MARKETING_CONFIGURATION_MANAGER',
  },

  /** @deprecated */
  INSTANT_RESPONSES: {
    facebook: {
      defaultMessage:
        'Thank you for contacting us. ' +
        'A representative will be with you in a few minutes.',
      deliveryMethod: 'facebook',
      featureCode: 'send_facebook_autoresponse',
      type: 'FACEBOOK',
    },
    missedCall: {
      defaultMessage:
        'Thank you for contacting us. ' +
        'A representative will be with you in a few minutes.',
      deliveryMethod: 'sms',
      featureCode: 'send_missed_call_autoresponse',
      type: 'MISSED_CALL',
    },
    homeadvisor: {
      defaultMessage:
        'Hi, thanks for your inquiry. ' +
        'We will be reaching out to you shortly to see how we can help you.',
      deliveryMethod: 'sms',
      featureCode: 'send_homeadvisor_followup_sms',
      type: 'HOMEADVISOR',
    },
    googleMessages: {
      defaultMessage:
        'Thank you for contacting us. ' +
        'A representative will be with you in a few minutes.',
      deliveryMethod: 'googleMessages',
      featureCode: 'send_google_messages_autoresponse',
      type: 'GOOGLE_MESSAGES',
    },
    thumbtack: {
      defaultMessage:
        'Hi, thanks for your inquiry. ' +
        'We will be reaching out to you shortly to see how we can help you.',
      deliveryMethod: 'sms',
      featureCode: 'send_thumbtack_autoresponse',
      type: 'THUMBTACK',
    },
    sms: {
      defaultMessage:
        'Hi, thanks for your inquiry. ' +
        'We will be reaching out to you shortly to see how we can help you.',
      deliveryMethod: 'sms',
      featureCode: 'send_sms_autoresponse',
      type: 'SMS',
    },
  },

  /** @deprecated */
  CONSUMER_TERMINOLOGY: {
    serviceOffering: {
      BUSINESS: {
        affirmativePastParticiple: 'have done business',
        negativePastParticiple: "haven't done business",
        noun: 'business',
      },
      VISIT: {
        affirmativePastParticiple: 'have visited',
        negativePastParticiple: "haven't visited",
        noun: 'visit',
      },
      STAY: {
        affirmativePastParticiple: 'have stayed',
        negativePastParticiple: "haven't stayed",
        noun: 'stay',
      },
    },
    consumer: {
      CUSTOMER: {
        singular: 'customer',
        plural: 'customers',
        singularCaps: 'Customer',
        pluralCaps: 'Customers',
      },
      PATIENT: {
        singular: 'patient',
        plural: 'patients',
        singularCaps: 'Patient',
        pluralCaps: 'Patients',
      },
      GUEST: {
        singular: 'guest',
        plural: 'guests',
        singularCaps: 'Guest',
        pluralCaps: 'Guests',
      },
      CLIENT: {
        singular: 'src/client',
        plural: 'src/clients',
        singularCaps: 'src/client',
        pluralCaps: 'src/clients',
      },
      STUDENT: {
        singular: 'student',
        plural: 'students',
        singularCaps: 'Student',
        pluralCaps: 'Students',
      },
      MEMBER: {
        singular: 'member',
        plural: 'members',
        singularCaps: 'Member',
        pluralCaps: 'Members',
      },
      PARENT: {
        singular: 'parent',
        plural: 'parents',
        singularCaps: 'Parent',
        pluralCaps: 'Parents',
      },
    },
  },

  /** @deprecated */
  ERRORS: {
    invalidRemarketingCustomer:
      'No existing valid email customer with customerId',
    duplicateRemarketingEmail: 'Duplicate remarketing_emails_sent',
    duplicateSMS: 'Duplicate SMS detected',
    overrideDuplicateSMS:
      "You've already sent a review request to this customer today. Please wait until tomorrow to send another request.",
    invalidSMSCustomer: 'No existing valid sms customer with customerId',
    noValidChannelForReviewRequest: 'No valid channel for review us campaign',
    ineligibleLocation: 'Location is ineligible for the requested action',
    overrideIneligibleLocation:
      'Cannot send text messages without a Call Forwarding Number. Please contact our Support Team to enable.',
    imageUploadError:
      'There has been an error uploading the image, please try again',
    fileTooBig: 'Your file is too large',
    fileSizeExceeded: (fileLimit: string) =>
      `Your file is too large, please choose a file that is under ${fileLimit}. Support for larger files is coming soon.`,
    default: 'Unknown error, please try again or contact us for help.',
    instantResponseNotSent: 'Instant response not sent due to local laws.',
    customerNotSubscribed:
      'Feedback/Review request cannot be sent.  Please subscribe this contact to marketing campaigns',
    contactsUnmergable: 'Contact information belongs on multiple customers',
  },

  /** @deprecated */
  CORE_ERRORS: {
    invalidSmsCustomer: 'No existing valid sms customer with customerId',
  },

  /** @deprecated */
  NOTIFICATIONS: {
    success: 'Request completed successfully',
  },

  /** @deprecated */
  ANALYTICS: {
    UserTypes: {
      MERCHANT: 'merchant',
      CUSTOMER: 'customer',
    },
  },

  MEDIA: {
    maxMediaSizeBytes: {
      FACEBOOK: {
        // 25 MB.
        photo: 26214400,
        video: 26214400,
      } as { photo: number; video: number },
      SMS: {
        // 4 MB.
        photo: 4194304,
        // Testing showed that very large files can fail to upload - 60MB uploads reliably
        video: 62914560,
      } as { photo: number; video: number },
    },
  },

  /** @deprecated */
  Messages: {
    // From Glaze: https://github.com/signpost/glaze/blob/176c07dd06fd7f8bf77a3d32fe7c9a4350713e9b/lib/Constants.js#L1792-L1836
    Estimates: {
      QUERY_TO_ENUM: {
        draft: 'DRAFT', // Enums.ContactEstimates.WorkflowStage.DRAFT
        sent: 'SENT', // Enums.ContactEstimates.WorkflowStage.SENT
        approved: 'APPROVED', // Enums.ContactEstimates.WorkflowStage.APPROVED
        declined: 'DECLINED', // Enums.ContactEstimates.WorkflowStage.DECLINED
      },

      ENUM_TO_QUERY: {
        DRAFT: 'draft',
        SENT: 'sent',
        APPROVED: 'approved',
        DECLINED: 'declined',
      },

      ENUM_TO_DISPLAY: {
        DRAFT: 'Draft',
        SENT: 'Sent',
        APPROVED: 'Approved',
        DECLINED: 'Declined',
      },
    },

    Jobs: {
      QUERY_TO_ENUM: {
        scheduled: 'SCHEDULED', // Enums.ContactJobs.WorkflowStage.SCHEDULED
        in_progress: 'IN_PROGRESS', // Enums.ContactJobs.WorkflowStage.IN_PROGRESS
        completed: 'COMPLETED', // Enums.ContactJobs.WorkflowStage.COMPLETED
        canceled: 'CANCELED', // Enums.ContactJobs.WorkflowStage.CANCELED
      },

      ENUM_TO_QUERY: {
        SCHEDULED: 'scheduled',
        IN_PROGRESS: 'in_progress',
        COMPLETED: 'completed',
        CANCELED: 'canceled',
      },

      ENUM_TO_DISPLAY: {
        SCHEDULED: 'Scheduled',
        IN_PROGRESS: 'In Progress',
        COMPLETED: 'Completed',
        CANCELED: 'Canceled',
      },
    },
    /** From Toshokan: https://github.com/signpost/glaze/blob/176c07dd06fd7f8bf77a3d32fe7c9a4350713e9b/node_modules/toshokan/Enums.js#L675-L681 */
    ConversionType: {
      INCOMING_CALL: 'INCOMING_CALL',
      INCOMING_SMS: 'INCOMING_SMS',
      VISIT_ARBITRARY_LANDING_PAGE: 'VISIT_ARBITRARY_LANDING_PAGE',
      VISIT_SIGNPOST_LANDING_PAGE: 'VISIT_SIGNPOST_LANDING_PAGE',
      LEGACY_CLAIM: 'LEGACY_CLAIM',
    },
    ContentType: {
      ANNOUNCEMENT: 'ANNOUNCEMENT',
      NEWSLETTER: 'NEWSLETTER',
      OFFER: 'OFFER',
    } as const,
  },

  /** @deprecated */
  DEFAULT_TEXT: {
    noFeedbackText: 'The customer left no text with this feedback.',
  },
}

const userHiddenNavbarPagesRegex = Constants.userHiddenNavbarPages.map(
  (hnp) => new RegExp(`/user/[0-9]+/${hnp}`)
)

const locationsHiddenNavbarPagesRegex =
  Constants.locationsHiddenNavbarPages.map(
    (hnp) => new RegExp(`/[0-9]+/${hnp}`)
  )

const hiddenNavbarPagesRegex = userHiddenNavbarPagesRegex.concat(
  locationsHiddenNavbarPagesRegex
)

const getLoginRedirect = (
  page: string
): { path: string; type: string } | undefined => {
  const LoginRedirects = {
    [Constants.MerchantCenter.Pages.ALL_CAMPAIGNS]: {
      path: 'campaigns/all',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.CONVERSION_CAMPAIGNS]: {
      path: 'campaigns/conversion',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.CAMPAIGN_CONTENT]: {
      path: 'campaigns/create-your-own/new',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.NEWSLETTER_CONTENT]: {
      path: 'campaigns/content-library?type=newsletter',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.OFFER_CONTENT]: {
      path: 'campaigns/content-library?type=offer',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.CONTACTS]: {
      path: 'customer-contacts',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.ContactFilter.COMPLETED_GOAL]: {
      path: `customer-contacts?group=Campaigns&campaign_have=have&campaign_action=converted&campaign_conversion_source=any`,
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.ContactFilter.RECEIVED_CAMPAIGN]: {
      path: `customer-contacts?group=Campaigns&campaign_have=have&campaign_action=received&campaign_type=any`,
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.ContactFilter.COMPLETED_NEW_CONTACT_GOAL]: {
      path: `customer-contacts?group=Campaigns&campaign_have=have&campaign_action=converted&campaign_conversion_source=prospect`,
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.CONTENT_LIBRARY]: {
      path: 'campaigns/content-library',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.CONTENT_LIBRARY_COVID]: {
      path: 'campaigns/content-library?category=COVID-19+Updates',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.CONTENT_LIBRARY_HOLIDAY]: {
      path: 'campaigns/content-library?category=Holiday+Guide',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.CONTENT_LIBRARY_HOME_SERVICES_HOLIDAY]: {
      path: `campaigns/content-library?category=Home+Services+Holiday+Guide`,
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.CAMPAIGNS]: {
      path: 'scheduled-campaigns/new',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.CAMPAIGNS_DRAFT]: {
      path: 'scheduled-campaigns/drafts',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.CAMPAIGNS_SCHEDULED]: {
      path: 'scheduled-campaigns/scheduled',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.CAMPAIGNS_SENT]: {
      path: 'scheduled-campaigns/sent',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.DASHBOARD]: {
      path: 'dashboard',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.INSIGHTS]: {
      path: 'insights',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.INVOICES]: {
      path: 'settings/billing',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.FEATURED_NEWSLETTER]: {
      path: 'campaigns/featured-newsletter',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.NCO]: {
      path: 'emails/new-customer-offer',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.FEEDBACK]: {
      path: 'emails/get-feedback',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.REVIEWS]: {
      path: 'emails/review-us',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.TEXT_INBOX]: {
      path: 'campaigns',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.BUSINESS_SETTINGS]: {
      path: 'settings/business',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.SMS_REGISTRATION]: {
      path: 'settings/business/sms-registration',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.EMAIL_SETTINGS]: {
      path: 'settings#section-settings-email',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.PHONE_SETTINGS]: {
      path: 'settings#section-settings-phone',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.BILLING_SETTINGS]: {
      path: 'settings#section-settings-billing',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.SUBSCRIPTION_SETTINGS]: {
      path: 'settings#section-settings-subscriptions',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.INSTANT_RESPONDERS_SETTINGS]: {
      path: 'settings#section-settings-instant-responders',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.CONNECT_THUMBTACK]: {
      path: 'integrations/connect#thumbtack',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.CUSTOMIZATION]: {
      path: 'email-customization',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.INTEGRATIONS]: {
      path: 'integrations',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.MESSAGING_HUB]: {
      path: 'messaging-hub',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.USER_EMAIL_SETTINGS]: {
      path: 'email-subscription',
      type: Constants.MerchantCenter.RedirectTypes.USER,
    },
    [Constants.MerchantCenter.Pages.NCC_CAMPAIGNS]: {
      path: 'campaigns/lifecycle/prospect',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.ACC_CAMPAIGNS]: {
      path: 'campaigns/lifecycle/active',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
    [Constants.MerchantCenter.Pages.WBC_CAMPAIGNS]: {
      path: 'campaigns/lifecycle/inactive',
      type: Constants.MerchantCenter.RedirectTypes.LOCATION,
    },
  }

  return LoginRedirects[page]
}

export { getLoginRedirect, hiddenNavbarPagesRegex }

export default Constants
