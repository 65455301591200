import React, { useRef } from 'react'
import styled, { useTheme } from 'styled-components'

import {
  LocationBusinessType,
  useGetLocationPhoneNumbers,
  useCreateSmsRegistration,
} from 'src/api'
import ReviewContent from 'src/components/Settings/Business/SmsRegistration/ReviewContent'
import SmsRegistrationLayout, {
  StyledCopy,
} from 'src/components/Settings/Business/SmsRegistrationLayout'
import { businessTypeOptions } from 'src/containers/Settings/Business/SmsRegistration/BusinessInfoStep'
import {
  nonSoleProprietorBusinessIndustryOptions,
  representativePositionOptions,
  soleProprietorBusinessIndustryOptions,
} from 'src/containers/Settings/Business/SmsRegistration/PrimaryContactInfoStep'
import useSmsRegistrationFormContext from 'src/containers/Settings/Business/SmsRegistration/SmsRegistrationFormContext'
import { SmsRegistrationWizardSteps } from 'src/containers/Settings/Business/types'
import { useLocationContext } from 'src/contexts/LocationContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import Constants from 'src/lib/Constants'
import { Button } from 'src/stories/Button'
import { SelectOption } from 'src/stories/Select'
import { WizardLayout } from 'src/stories/Wizard'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { formatAddress, formatPhoneNumber } from 'src/utils'

const Link = styled.a(({ theme }) => ({
  fontSize: '1.4rem',
  fontWeight: 500,
  color: theme.colors.primary_2,
}))

const ConfirmationModalBodyTitle = styled.p(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '2.4rem',
  fontWeight: 'bold',
  textAlign: 'left',
  paddingTop: 0,
  marginTop: theme.space(0),
}))

const ConfirmationModalBody = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.4rem',
  fontWeight: 400,
  paddingBottom: 0,
  paddingTop: 0,
  marginTop: 0,
}))

const Wrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(8),
}))

interface ReviewAndSubmitStepProps {
  nextStep: () => void
  previousStep: () => void
  goToStep: (step: SmsRegistrationWizardSteps) => void
}

const ReviewAndSubmitStep: React.FC<ReviewAndSubmitStepProps> = ({
  nextStep,
  previousStep,
  goToStep,
}) => {
  const { handleSubmit, getValues } = useSmsRegistrationFormContext()
  const { locationId } = useLocationContext()
  const {
    data: locationPhoneNumbers = [],
    isFetching,
    isLoading,
  } = useGetLocationPhoneNumbers(locationId)
  const { mutateAsync: createSmsRegistration, isPending } =
    useCreateSmsRegistration(locationId)
  const { showModal, closeModal } = useModalNotificationsContext()
  const formValues = getValues()
  const { isMediumScreen: isDesktop } = useScreenSizes()
  const theme = useTheme()
  const isGettingLocationPhoneNumber = isFetching || isLoading

  const smsSubmitButton = useRef<HTMLButtonElement | null>(null)

  const onSubmit = handleSubmit(async (data) => {
    try {
      await createSmsRegistration({
        addressCity: data.city,
        addressPostalCode: data.postalCode,
        addressState: data.state,
        addressStreet1: data.addressLine1,
        addressStreet2: data.addressLine2,
        businessIdentifier: data.ein?.toString(),
        businessIndustry: data.businessIndustry,
        businessName: data.businessName,
        businessType: data.businessType,
        locationId,
        phoneNumber: locationPhoneNumbers[0].phoneNumber,
        representativeEmailAddress: data.representativeEmail,
        representativeFirstName: data.representativeFirstName,
        representativeLastName: data.representativeLastName,
        representativePhoneNumber: data.representativePhoneNumber,
        representativePosition: data.representativePosition,
        representativeTitle: data.representativeTitle,
        websiteUrl: data.websiteUrl,
      })

      nextStep()
    } catch (error) {
      showModal({
        dataCy: 'sms-registration-resubmit-modal',
        title: 'Submission Error',
        headerColor: 'accent_2',
        headerTitleFontSize: '2.2rem',
        headerTitleFontWeight: 500,
        hideActionButtons: true,
        customBody: (
          <ConfirmationModalBody>
            <ConfirmationModalBodyTitle>
              Unable to submit registration
            </ConfirmationModalBodyTitle>
            <p style={{ fontSize: '1.6rem', marginTop: 0 }}>
              Something went wrong while submitting your registration. Please
              try again.
            </p>
            <br />
            If the error persists, give us a call at{' '}
            <Link href={`tel:${Constants.Branding.companyPhone}`}>
              {formatPhoneNumber(Constants.Branding.companyPhone)}
            </Link>{' '}
            or email{' '}
            <Link href={`mailto:${Constants.Branding.companySupportEmail}`}>
              {Constants.Branding.companySupportEmail}
            </Link>
            .
            <br />
            <br />
            <br />
            <Button
              style={{ width: isDesktop ? theme.space(50) : '100%' }}
              baseDataAttribute={'retry-cta-action'}
              label="Try Again"
              onClick={() => {
                closeModal()

                if (smsSubmitButton.current) {
                  smsSubmitButton.current.click()
                }
              }}
              action="danger"
              type="submit"
            />
          </ConfirmationModalBody>
        ),
      })
    }
  })

  const fallbackString = 'Not provided'
  const isSoleProprietorship =
    formValues.businessType === LocationBusinessType.SOLE_PROPRIETORSHIP

  const businessType =
    businessTypeOptions.find((o) => o.value === formValues.businessType)
      ?.label ?? fallbackString

  const jobPosition =
    representativePositionOptions.find(
      (o) => o.value === formValues.representativePosition
    )?.label ?? fallbackString

  const selectedBusinessIndustryOptions = (isSoleProprietorship
    ? soleProprietorBusinessIndustryOptions
    : nonSoleProprietorBusinessIndustryOptions) as unknown as SelectOption<string>[]
  const businessIndustry =
    selectedBusinessIndustryOptions.find(
      (o) => o.value === formValues.businessIndustry
    )?.label ?? fallbackString

  return (
    <WizardLayout fullWidth>
      <SmsRegistrationLayout
        title="Review your information"
        buttonNext={
          <Button
            reference={smsSubmitButton}
            label="Confirm and Submit"
            baseDataAttribute="sms-registration-review-and-submit-confirm"
            type="button"
            onClick={onSubmit}
            disabled={
              isPending ||
              isGettingLocationPhoneNumber ||
              (!isGettingLocationPhoneNumber && !locationPhoneNumbers.length)
            }
          />
        }
        buttonBack={
          <Button
            action="secondary"
            label="Back"
            baseDataAttribute="sms-registration-review-and-submit-back"
            onClick={() => {
              previousStep()
            }}
          />
        }
      >
        <StyledCopy>
          Please confirm the provided information is correct.
        </StyledCopy>
        <Wrapper>
          <ReviewContent
            title="Business info"
            items={[
              { label: 'Business Name', value: formValues.businessName },
              { label: 'Business Type', value: businessType },
              ...(!isSoleProprietorship
                ? [
                    {
                      label: 'EIN (Tax ID)',
                      value: formValues.ein?.toString() || fallbackString,
                    },
                  ]
                : []),
              {
                label: 'Address',
                value: formatAddress(
                  formValues.addressLine1,
                  formValues.addressLine2 ?? '',
                  formValues.city,
                  formValues.state,
                  formValues.postalCode
                ),
              },
              {
                label: 'Website URL',
                value: formValues.websiteUrl ?? fallbackString,
                unspacedLongText: true,
              },
            ]}
            onClick={() => goToStep(SmsRegistrationWizardSteps.BUSINESS_INFO)}
          />
          <ReviewContent
            title="Primary Contact Info"
            items={[
              {
                label: 'First Name',
                value: formValues.representativeFirstName,
              },
              { label: 'Last Name', value: formValues.representativeLastName },
              ...(isSoleProprietorship
                ? []
                : [
                    {
                      label: 'Job Title',
                      value: formValues.representativeTitle ?? fallbackString,
                    },
                    {
                      label: 'Job Position',
                      value: jobPosition,
                    },
                  ]),
              {
                label: isSoleProprietorship
                  ? 'Mobile phone number'
                  : 'Phone number',
                value: formValues.representativePhoneNumber ?? fallbackString,
              },
              {
                label: 'Email',
                value: formValues.representativeEmail,
                unspacedLongText: true,
              },
              {
                label: 'Business industry',
                value: businessIndustry,
              },
            ]}
            onClick={() =>
              goToStep(SmsRegistrationWizardSteps.PRIMARY_CONTACT_INFO)
            }
          />
        </Wrapper>
      </SmsRegistrationLayout>
    </WizardLayout>
  )
}

export default ReviewAndSubmitStep
