import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import AddContactModal from './AddContactModal'
import { useLocationContext } from 'src/contexts/LocationContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import { UploadedListIcon, ManuallyAddedIcon } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Heading } from 'src/stories/typography'
import { generateLocationUrl } from 'src/utils'

const Header = styled.div<{ isDesktop: boolean }>(({ theme, isDesktop }) => ({
  display: 'flex',
  gap: theme.space(5),
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  padding: `0 ${theme.space(isDesktop ? 8 : 4)}`,
}))

const ButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.space(5),
}))

const ContactsHeader: React.FC = () => {
  const baseDataAttribute = 'contacts-header'
  const { isMediumScreen: isDesktop } = useScreenSizes()
  const navigate = useNavigate()
  const { locationId } = useLocationContext()
  const { showModal, closeModal } = useModalNotificationsContext()

  const uploadContactsUrl = useMemo(
    () => generateLocationUrl(locationId, 'contacts/upload'),
    [locationId]
  )

  return (
    <Header isDesktop={isDesktop}>
      <Heading as="h1" size="large">
        Contacts
      </Heading>
      <ButtonsContainer>
        <Button
          label={isDesktop ? 'Upload Contacts' : 'Upload'}
          icon={UploadedListIcon}
          href={uploadContactsUrl}
          baseDataAttribute={`${baseDataAttribute}-upload-contacts`}
        />
        <Button
          label={isDesktop ? 'Add Contacts' : 'Add'}
          icon={ManuallyAddedIcon}
          onClick={() =>
            showModal({
              title: 'Add contact',
              dataCy: 'contacts-add-contact',
              modalActionsOptions: {
                callToAction: { label: 'Add contact' },
              },
              customBody: (
                <AddContactModal
                  onComplete={(contactId) => {
                    closeModal()

                    if (contactId) {
                      navigate(`./${contactId}`)
                    }
                  }}
                  dataCy="contacts-add-contact"
                  locationId={locationId}
                />
              ),
            })
          }
          baseDataAttribute={`${baseDataAttribute}-add-contact`}
        />
      </ButtonsContainer>
    </Header>
  )
}

export default ContactsHeader
