import styled from 'styled-components'

export const ChannelRow = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: `${theme.space(13)} 1fr`,
  gap: theme.space(3),
}))

export const ChannelColumn = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(3),
}))
