import { useMutation } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

export interface ExtendFacebookTokenParams {
  locationId: number
  accessToken: string
}

export const useExtendFacebookToken = () => {
  const { getClient } = useApiClient()

  return useMutation({
    mutationFn: async (params: ExtendFacebookTokenParams) => {
      return getClient().then((client) =>
        client
          .post<{ accessToken: string }>(
            `/locations/${params.locationId}/integrations/facebook/access-token`,
            {
              accessToken: params.accessToken,
            }
          )
          .then((r) => r.data)
      )
    },
  })
}
