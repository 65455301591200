import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'

import VCardForm from './forms/VCardForm'
import {
  SmsRegistrationStatus,
  useGetLocationPhoneNumbers,
  UpdateLocationMutationParams,
  useUpdateLocation,
  useGetLocationFeatureFlag,
  useCreateLocationVCard,
  useUpdateLocationVCard,
} from 'src/api'
import StatusIndicator from 'src/components/Settings/Business/StatusIndicator'
import { StyledTitleContainer } from 'src/components/Settings/Business/styled'
import HelperTooltip, {
  HelperTooltipWrapper,
} from 'src/components/Settings/common/HelperTooltip'
import PageLayout from 'src/components/WafLayout/PageLayout'
import AddressForm from 'src/containers/Settings/Business/forms/AddressForm'
import DescriptionForm from 'src/containers/Settings/Business/forms/DescriptionForm'
import HoursForm, {
  displayHoursFormatter,
} from 'src/containers/Settings/Business/forms/HoursForm'
import WebsiteForm from 'src/containers/Settings/Business/forms/WebsiteForm'
import { MutationBusinessProps } from 'src/containers/Settings/Business/types'
import { useLocationContext } from 'src/contexts/LocationContext'
import Constants from 'src/lib/Constants'
import { Button } from 'src/stories/Button'
import PageSectionContainer, {
  PageSectionContainerAction,
  PageSectionContainerVariants,
} from 'src/stories/PageSectionContainer'
import SectionContainer, { SectionAction } from 'src/stories/SectionContainer'
import { NewBadgeIcon } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Body, Heading, HeadingProps } from 'src/stories/typography'
import { formatAddress, generateLocationUrl } from 'src/utils'

const A2P10DLC_REGISTRY_CAMPAIGN_URL =
  'https://help.signpost.com/phone-carriers-require-registration-of-eins-for-businesses'

const StyledHoursWrapper = styled.span(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: theme.space(62),
}))

const StyledHours = styled.span(({ theme }) => ({
  width: theme.space(35),
}))

const StyledSeparator = styled.div(({ theme }) => ({
  height: theme.space(8),
}))

const SmsRegistrationTitle = () => {
  return (
    <HelperTooltipWrapper>
      <Heading as="h3" size="large">
        SMS Registration
      </Heading>
      <HelperTooltip>
        To register your Signpost account for texting and SMS marketing, you
        need to provide your 9-digit Employer Identification Number (EIN), which
        can be found on your tax preparation documents.
      </HelperTooltip>
    </HelperTooltipWrapper>
  )
}

const SmsRegistrationSubtitle = () => {
  return (
    <Body as="p">
      To use text marketing features for generating reviews and texting with
      customers, register your Signpost number with your business. This ensures
      compliance with{' '}
      <Button
        size="medium"
        label="The Campaign Registry's A2P 10DLC"
        href={A2P10DLC_REGISTRY_CAMPAIGN_URL}
        shouldOpenInNewTab
        asLink
        displayAsText
      />{' '}
      regulation.
    </Body>
  )
}

const RegistrationStatusText: Record<SmsRegistrationStatus, string> = {
  NOT_STARTED: 'Not registered',
  IN_PROGRESS: 'Registration submitted. Currently pending approval.',
  APPROVED: 'Registration Approved',
  FAILED: `Registration not approved. Please contact ${Constants.Branding.companyName} support at (844) 202-2015, Monday to Friday, 7AM - 6PM MST.`,
}

export interface MutationBusinessInfoProps<
  T = Omit<UpdateLocationMutationParams, 'locationId'>,
  TNewVal = Partial<T>
> extends MutationBusinessProps<T, TNewVal> {
  onSubmitFinished: () => void
}

const Business: React.FC = () => {
  const theme = useTheme()
  const baseDataAttribute = 'settings-business'
  const { locationId, activeLocation } = useLocationContext()
  const { isSmallScreen: isDesktop } = useScreenSizes()

  const {
    mutateAsync: mutateBusinessInfo,
    isPending: isLoadingUpdateLocation,
  } = useUpdateLocation(locationId)
  const { mutateAsync: createVCard, isPending: isCreatingVCard } =
    useCreateLocationVCard(locationId)
  const { mutateAsync: updateVCard, isPending: isUpdatingVCard } =
    useUpdateLocationVCard(locationId)
  const [currentEditingForm, setCurrentEditingForm] = useState('')

  const getIsDisabled = (formName: string) =>
    (currentEditingForm !== '' && currentEditingForm !== formName) ||
    isLoadingUpdateLocation
  const { data: locationPhoneNumbers = [] } =
    useGetLocationPhoneNumbers(locationId)

  const baseProps = {
    isPending: isLoadingUpdateLocation || isCreatingVCard || isUpdatingVCard,
    onSubmitFinished: () => setCurrentEditingForm(''),
  }
  const mutationBusinessInfoProps: MutationBusinessInfoProps = {
    ...baseProps,
    update: async (
      newValue: Partial<Omit<UpdateLocationMutationParams, 'locationId'>>
    ) => {
      await mutateBusinessInfo({
        locationId,
        website: newValue.website,
        addressCity: newValue.addressCity,
        addressState: newValue.addressState,
        addressStreet1: newValue.addressStreet1,
        addressStreet2: newValue.addressStreet2,
        addressZipCode: newValue.addressZipCode,
        description: newValue.description,
        operatingHours: newValue.operatingHours,
        phoneNumber: newValue.phoneNumber,
      })
    },
  }

  const registrationStatus =
    activeLocation.registrationStatus === SmsRegistrationStatus.NOT_STARTED
      ? SmsRegistrationStatus.IN_PROGRESS // NOT_STARTED API status: began on API but pending for Twilio send
      : activeLocation.registrationStatus || SmsRegistrationStatus.NOT_STARTED

  const smsRegistrationPage = generateLocationUrl(
    locationId,
    '/settings/business/sms-registration'
  )

  const { data: textingEnabledFeatureFlag } = useGetLocationFeatureFlag({
    locationId: activeLocation.id,
    featureFlagName: Constants.FeatureFlags.isTextingEnabled,
    defaultValue: 0,
  })

  const smsFeatureCodeEnabled = textingEnabledFeatureFlag.value

  const getActionProps = (formName: string) => ({
    label: currentEditingForm === formName ? 'Cancel' : 'Edit',
    onClick: () => setCurrentEditingForm((f) => (f ? '' : formName)),
    disabled: getIsDisabled(formName),
  })

  const getSectionContainerProps = (formName: string, title: string) => ({
    title,
    titleProps: {
      as: 'h3',
      size: 'medium',
    } as HeadingProps,
    isRounded: true,
    variant: 'inverted' as PageSectionContainerVariants,
    childrenStyle: {
      gap: theme.space(4),
    },
    action: PageSectionContainerAction,
    disabled: getIsDisabled(formName),
    actionProps: {
      ...getActionProps(formName),
    },
    shouldShow: currentEditingForm === formName,
  })

  return (
    <PageLayout
      title="Business Settings"
      subtitle="View and update your business information."
      baseDataAttribute={baseDataAttribute}
    >
      <PageSectionContainer
        title="Business Info"
        isRounded
        isWrapped={isDesktop}
        childrenStyle={{
          gap: theme.space(4),
        }}
      >
        {/* Website */}
        <PageSectionContainer
          subtitle={
            currentEditingForm === 'website' ? undefined : (
              <Body>{activeLocation.website || 'Not provided'}</Body>
            )
          }
          {...getSectionContainerProps('website', 'Website')}
        >
          <WebsiteForm
            {...mutationBusinessInfoProps}
            baseDataAttribute={baseDataAttribute}
          />
        </PageSectionContainer>
        {/* Address */}
        <PageSectionContainer
          subtitle={
            <Body>
              {currentEditingForm === 'address' ? (
                <>By law, every marketing email needs a physical address.</>
              ) : (
                <>
                  {formatAddress(
                    activeLocation.addressStreet1,
                    activeLocation.addressStreet2 ?? '',
                    activeLocation.addressCity,
                    activeLocation.addressState,
                    activeLocation.addressZipCode
                  ) || 'Not provided'}
                </>
              )}
            </Body>
          }
          {...getSectionContainerProps('address', 'Address')}
        >
          <AddressForm
            {...mutationBusinessInfoProps}
            baseDataAttribute={baseDataAttribute}
          />
        </PageSectionContainer>
        {/* vCard */}
        <PageSectionContainer
          subtitle="Easily share your contact information with your customers"
          {...getSectionContainerProps('vCard', 'vCard')}
          title={
            <span style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              <Heading as="h3" size="medium">
                vCard
              </Heading>
              <NewBadgeIcon
                fill={theme.colors.primary_2}
                height={18}
                style={{ verticalAlign: 'sub' }}
              />
            </span>
          }
          titleProps={undefined}
        >
          <VCardForm
            {...baseProps}
            baseDataAttribute={baseDataAttribute}
            update={async (newValue) => {
              if (activeLocation.primaryVCardId) {
                await updateVCard({
                  id: activeLocation.primaryVCardId,
                  locationId: activeLocation.id,
                  addressCity: newValue.addressCity,
                  addressState: newValue.addressState,
                  addressZipCode: newValue.addressZipCode,
                  emailAddress: newValue.emailAddress,
                  addressStreet1: newValue.addressStreet1,
                  addressStreet2: newValue.addressStreet2,
                  firstName: newValue.firstName,
                  lastName: newValue.lastName,
                })

                return
              }

              await createVCard({
                locationId: activeLocation.id,
                addressCity: newValue.addressCity,
                addressState: newValue.addressState,
                addressZipCode: newValue.addressZipCode,
                emailAddress: newValue.emailAddress,
                addressStreet1: newValue.addressStreet1,
                addressStreet2: newValue.addressStreet2,
                firstName: newValue.firstName,
                lastName: newValue.lastName,
              })
            }}
          />
        </PageSectionContainer>
        {/* Description */}
        <PageSectionContainer
          subtitle={
            currentEditingForm === 'description' ? undefined : (
              <Body>{activeLocation.description || 'Not provided'}</Body>
            )
          }
          {...getSectionContainerProps('description', 'Description')}
        >
          <DescriptionForm
            {...mutationBusinessInfoProps}
            baseDataAttribute={baseDataAttribute}
          />
        </PageSectionContainer>
        {/* Hours */}
        <PageSectionContainer
          subtitle={
            <Body>
              {currentEditingForm === 'hours' ? (
                <>Select hours of operation for your business.</>
              ) : (
                <>
                  {displayHoursFormatter(activeLocation.operatingHours).map(
                    (day) => (
                      <StyledHoursWrapper key={day.dayOfWeek}>
                        {day.dayOfWeek}
                        <StyledHours>{day.label}</StyledHours>
                      </StyledHoursWrapper>
                    )
                  )}
                </>
              )}
            </Body>
          }
          {...getSectionContainerProps('hours', 'Hours')}
        >
          <HoursForm
            {...mutationBusinessInfoProps}
            baseDataAttribute={baseDataAttribute}
          />
        </PageSectionContainer>
      </PageSectionContainer>

      <StyledSeparator />

      {!!locationPhoneNumbers.length && (
        <PageSectionContainer
          title={<SmsRegistrationTitle />}
          subtitle={<SmsRegistrationSubtitle />}
          isRounded
          isWrapped={isDesktop}
          action={false}
        >
          <SectionContainer
            variant="primary"
            disabled={!!currentEditingForm && !smsFeatureCodeEnabled}
            action={
              registrationStatus === SmsRegistrationStatus.NOT_STARTED ? (
                <SectionAction
                  label="Register"
                  href={smsRegistrationPage}
                  disabled={!!currentEditingForm}
                />
              ) : undefined
            }
          >
            <StyledTitleContainer>
              {' '}
              <Heading as="h3" size="medium">
                Status
              </Heading>
              <StatusIndicator status={registrationStatus} />
            </StyledTitleContainer>
            <Body>{RegistrationStatusText[registrationStatus]}</Body>
          </SectionContainer>
        </PageSectionContainer>
      )}

      <StyledSeparator />
    </PageLayout>
  )
}

export default Business
