import styled, { useTheme } from 'styled-components'

import { StyledTabsSearchInput } from './styled'
import { useGetConversationStatusAttributes } from 'src/api'
import { StyledHeaderContainer } from 'src/components/MessagingHub/styled'
import ConfirmationModal from 'src/components/Modals/ConfirmationModal'
import { HandleTabConversationChangeFn } from 'src/containers/MessagingHub/types'
import { useLocationContext } from 'src/contexts/LocationContext'
import useMhContext from 'src/contexts/MhContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import Input from 'src/stories/Input'
import { Col, Row } from 'src/stories/Layout'
import Select from 'src/stories/Select'
import StatusIconComponent from 'src/stories/StatusIcon'
import Tooltip from 'src/stories/Tooltip'
import {
  SpamIcon,
  ArchiveIcon,
  OpenMessageIcon,
  StatusTagIcon,
} from 'src/stories/assets'

export const StyledTabsSearchInputContainer = styled.div(({ theme }) => ({
  padding: `0 ${theme.space(4)}`,
}))

const StyledRow = styled(Row)(({ theme }) => ({
  padding: `0 ${theme.space(4)}`,
  alignItems: 'center',
  marginTop: theme.space(3),
  height: theme.space(8),
}))

export const StyledCol = styled(Col)(() => ({
  margin: 0,
}))

const EditActionsContainer = styled.div(({ theme }) => ({
  height: theme.space(11),
  backgroundColor: theme.colors.base_3,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `0 ${theme.space(4)}`,
}))

const ActionsLabel = styled.p(({ theme }) => ({
  margin: 0,
  color: theme.colors.base_100,
  fontWeight: 500,
  fontSize: '1.5rem',
}))

const ActionIconsContainer = styled.div(() => ({ display: 'flex' }))

const ActionIconsDivider = styled.div(({ theme }) => ({
  width: 0,
  marginRight: theme.space(5),
}))

interface ConversationListHeaderProps {
  conversationsTab: string
  unreadConversationsCount: number
  handleTabConversationChange: HandleTabConversationChangeFn
  editMode: boolean
  toggleEditMode: () => void
  selectAll: boolean
  toggleSelectAll: (checked: boolean) => void
  selectedCount: number
  onArchiveClicked: () => void
  onMarkAsSpamClicked: () => void
  onMarkReadClicked: () => void
}

const ConversationListHeader: React.FC<ConversationListHeaderProps> = ({
  conversationsTab,
  unreadConversationsCount,
  handleTabConversationChange,
  editMode,
  toggleEditMode,
  selectAll,
  toggleSelectAll,
  selectedCount,
  onArchiveClicked,
  onMarkAsSpamClicked,
  onMarkReadClicked,
}) => {
  const {
    isDesktop,
    conversationListFilter,
    setConversationListFilter,
    isMediumDesktop,
  } = useMhContext()

  const theme = useTheme()

  const { locationId } = useLocationContext()
  const { data: conversationStatusAttributes = [] } =
    useGetConversationStatusAttributes({ locationId })
  const { showModal, closeModal } = useModalNotificationsContext()

  return (
    <StyledHeaderContainer
      data-cy="conversations-list-header"
      height={theme.space(isDesktop ? 31 : 30)}
      $border="right"
      $isMediumDesktop={isMediumDesktop}
    >
      <StyledCol>
        {editMode ? (
          <EditActionsContainer>
            <ActionsLabel data-cy="mh-conversations-selected">
              <span data-cy="mh-conversations-selected-count">
                {selectedCount}
              </span>{' '}
              selected
            </ActionsLabel>
            <ActionIconsContainer>
              <Tooltip
                text={conversationsTab === 'archived' ? 'Unarchive' : 'Archive'}
                data-cy="mh-archive-button"
                onClick={() => {
                  showModal({
                    dataCy: 'mh-archive-conversation-modal',
                    title: 'Archive',
                    height: 'auto',
                    hideActionButtons: false,
                    modalActionsOptions: {
                      callToAction: {
                        label: 'Accept',
                        onClick: () => {
                          onArchiveClicked()
                          closeModal()
                        },
                      },
                    },
                    customBody: (
                      <ConfirmationModal message="Are you sure you want to archive the conversation(s)?" />
                    ),
                  })
                }}
                widthUnits={22}
                position="bottom"
              >
                <ArchiveIcon fill={theme.colors.primary_2} />
              </Tooltip>

              <ActionIconsDivider />

              <Tooltip
                text="Mark read"
                data-cy="mh-mark-read-button"
                widthUnits={30}
                onClick={onMarkReadClicked}
                position="bottom"
              >
                <OpenMessageIcon fill={theme.colors.primary_2} />
              </Tooltip>

              <ActionIconsDivider />

              <Tooltip
                text={
                  conversationsTab === 'spam'
                    ? 'Mark as not spam'
                    : 'Mark as spam'
                }
                data-cy="mh-mark-as-spam-button"
                widthUnits={30}
                onClick={() => {
                  showModal({
                    dataCy: 'mh-spam-conversation-modal',
                    hideActionButtons: false,
                    title: 'Mark as spam',
                    height: 'auto',
                    modalActionsOptions: {
                      callToAction: {
                        label: 'Accept',
                        action: 'danger',
                        onClick: () => {
                          onMarkAsSpamClicked()
                          closeModal()
                        },
                      },
                    },
                    headerColor: 'accent_2',
                    customBody: (
                      <ConfirmationModal message="Are you sure you want to mark the conversation(s) as spam?" />
                    ),
                  })
                }}
                position="bottom"
              >
                <SpamIcon fill={theme.colors.accent_2} />
              </Tooltip>
            </ActionIconsContainer>
          </EditActionsContainer>
        ) : (
          <StyledTabsSearchInputContainer>
            <StyledTabsSearchInput
              verticallySpaced={false}
              data-cy="conversation-list-search"
              name="conversation-list-search"
              placeholder={`Search name or phone number`}
              value={conversationListFilter}
              onChange={(e) => setConversationListFilter(e.target.value)}
            />
          </StyledTabsSearchInputContainer>
        )}

        <StyledRow data-cy="mh-select-row">
          {editMode ? (
            <>
              <Input
                type="checkbox"
                name="checkbox"
                containerStyle={{
                  margin: 0,
                  marginRight: theme.space(7),
                  width: theme.space(5),
                  height: theme.space(5),
                }}
                data-cy="mh-select-all-checkbox"
                onChange={(e) => {
                  toggleSelectAll(e.target.checked)
                }}
                checked={selectAll}
              />
              <Button
                label={`${selectAll ? 'Unselect' : 'Select'} All`}
                baseDataAttribute="mh-select-all"
                size="large"
                displayAsText
                onClick={() => {
                  toggleSelectAll(!selectAll)
                }}
                style={{ width: '100%' }}
              />
            </>
          ) : (
            <>
              <Select
                dataCy="mh-conversation-list-header-select"
                initialValue={conversationsTab}
                onChange={(value) =>
                  handleTabConversationChange({
                    mode: 'tab',
                    value,
                  })
                }
                options={[
                  {
                    label: 'All conversations',
                    value: 'all',
                  },
                  {
                    label: `Unread (${unreadConversationsCount})`,
                    value: 'unread',
                  },
                  {
                    label: 'Spam',
                    value: 'spam',
                  },
                  {
                    label: 'Archived',
                    value: 'archived',
                  },
                  ...conversationStatusAttributes.map((statusAttribute) => ({
                    label: statusAttribute.label,
                    value: `status-${statusAttribute.id}`,
                    icon: (
                      <StatusIconComponent $hexColor={statusAttribute.color} />
                    ),
                  })),
                  {
                    label: 'Edit Statuses',
                    value: 'editStatuses',
                    icon: (
                      <StatusTagIcon
                        width="10"
                        height="10"
                        fill={theme.colors.base_100}
                      />
                    ),
                  },
                ]}
              />
            </>
          )}
          <Button
            label={editMode ? 'Cancel' : 'Edit'}
            size="large"
            onClick={() => {
              toggleEditMode()
            }}
            baseDataAttribute="mh-toggle-edit-mode"
            displayAsText
            style={{ marginLeft: theme.space(4) }}
          />
        </StyledRow>
      </StyledCol>
    </StyledHeaderContainer>
  )
}

export default ConversationListHeader
