import styled, { CSSObject } from 'styled-components'

import Constants from 'src/lib/Constants'
import { Row } from 'src/stories/Layout'
import {
  FeedbackVPositiveIcon,
  FeedbackPositiveIcon,
  FeedbackNeutralIcon,
  FeedbackNegativeIcon,
  FeedbackVNegativeIcon,
} from 'src/stories/assets'

interface BorderProps {
  $border?: 'left' | 'right'
  $isMediumDesktop?: boolean
}

export const StyledHeaderContainer = styled(Row)<BorderProps>(
  ({ theme, $border, $isMediumDesktop }) => {
    const css: CSSObject = {}
    const border = `1px solid ${theme.colors.base_20}`

    if ($border === 'left') {
      css.borderLeft = border
    } else if ($border === 'right') {
      css.borderRight = $isMediumDesktop ? border : '0px'
    }
    const boxShadowHorizontalOffset = theme.space(0)
    const boxShadowVerticalOffset = theme.space(1)
    const boxShadowBlurRadius = theme.space(1)
    const boxShadowSpreadRadius = theme.space(0)
    const boxShadowColor = theme.colors.base_3

    return {
      borderBottom: border,
      padding: `${theme.space(3)} 0`,
      boxShadow: [
        boxShadowHorizontalOffset,
        boxShadowVerticalOffset,
        boxShadowBlurRadius,
        boxShadowSpreadRadius,
        boxShadowColor,
      ].join(' '),
      ...css,
    }
  }
)

export const StyledContentContainer = styled(Row)<BorderProps>(
  ({ theme, $border, $isMediumDesktop }) => {
    const css: CSSObject = {}
    const border = `1px solid ${theme.colors.base_20}`

    if ($border === 'left') {
      css.borderLeft = border
    } else if ($border === 'right') {
      css.borderRight = $isMediumDesktop ? border : 'none'
    }

    return {
      ...css,
    }
  }
)

export const StyledNewConvoSearchContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '60vw',
  maxWidth: theme.space(125),
}))

export const StyledNewConvoSearchItem = styled.div(({ theme }) => ({
  display: 'flex',
  width: '100%',
  margin: 0,
  padding: `${theme.space(3)}`,
  justifyContent: 'space-between',

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.colors.base_10,
  },
}))

export const extractFeedbackOutcome = (
  denominator: number,
  isPositive: boolean,
  feedbackText?: string,
  rating?: number
) => {
  let feedbackIcon = <></>
  let feedbackOutcomeText = ''
  let finalFeedbackText = feedbackText || Constants.DEFAULT_TEXT.noFeedbackText

  if (typeof rating === 'number') {
    const calcRating = (rating * 5) / denominator

    if (calcRating <= 1) {
      feedbackIcon = <FeedbackVNegativeIcon />
      feedbackOutcomeText = 'Gave you a very negative feedback'
    } else if (calcRating <= 2) {
      feedbackIcon = <FeedbackNegativeIcon />
      feedbackOutcomeText = 'Gave you a negative feedback'
    } else if (calcRating <= 3) {
      feedbackIcon = <FeedbackNeutralIcon />
      feedbackOutcomeText = 'Gave you a neutral feedback'
    } else if (calcRating <= 4) {
      feedbackIcon = <FeedbackPositiveIcon />
      feedbackOutcomeText = 'Gave you a positive feedback'
    } else if (calcRating <= 5) {
      feedbackIcon = <FeedbackVPositiveIcon />
      feedbackOutcomeText = 'Gave you a very positive feedback'
    }
  } else {
    if (isPositive) {
      feedbackIcon = <FeedbackPositiveIcon />
      feedbackOutcomeText = 'Would recommend'
    } else {
      feedbackIcon = <FeedbackNegativeIcon />
      feedbackOutcomeText = 'Would not Recommend'
    }
  }

  return { feedbackIcon, feedbackOutcomeText, feedbackText: finalFeedbackText }
}
