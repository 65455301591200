import { useEffect, useRef, useState, useCallback } from 'react'
import styled, { CSSObject, useTheme } from 'styled-components'

import QuestionTooltip from './QuestionTooltip'
import { ResponderName } from 'src/api'
import ToggleAction, {
  ToggleActionContainer,
} from 'src/components/ToggleAction'
import RestoreDefaultMessageModal from 'src/containers/Settings/InstantResponders/components/RestoreDefaultMessageModal'
import { useLocationContext } from 'src/contexts/LocationContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import Constants from 'src/lib/Constants'
import { Button } from 'src/stories/Button'
import PageSectionContainer from 'src/stories/PageSectionContainer'
import Toggle from 'src/stories/Toggle'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Body, Heading } from 'src/stories/typography'

interface CommonLeadInstantResponseSettingsProps {
  responderName: ResponderName
  leadSourceName: string
  enabled?: boolean
  messageLabel?: string
  message?: string | null
  sendVCard: boolean
  maxLength?: number
  isDesktop?: boolean
  onEnablementChange?: (value: boolean) => void
  isEnabling?: boolean
  onUpdateMessage?: (value: string, sendVCard: boolean) => Promise<void>
  isUpdatingMessage?: boolean
  tooltipMessage?: string
  isToggleDisabled?: boolean
  snippets: { label: string; value: string; tooltip: string }[]
}

interface ConnectableLeadInstantResponseSettingsProps {
  connectable: true
  connected: boolean
  integrationUpdatedAt: string | null
  integrationStatus?: string
  isConnecting?: boolean
  isDisconnecting?: boolean
  onClickConnect?: () => void
  onClickDisconnect?: () => void
}

interface UnconnectableLeadInstantResponseSettingsProps {
  connectable: false
}

export type LeadInstantResponseSettingsProps =
  CommonLeadInstantResponseSettingsProps &
    (
      | ConnectableLeadInstantResponseSettingsProps
      | UnconnectableLeadInstantResponseSettingsProps
    )

const ItemContainer = styled.div<{
  $direction?: CSSObject['flexDirection']
}>(({ theme, $direction = 'row' }) => ({
  display: 'flex',
  alignItems: $direction === 'column' ? 'start' : 'center',
  justifyContent: 'center',
  gap: theme.space(2),
  flexDirection: $direction,
}))

const StyledTextbox = styled.textarea(({ theme }) => ({
  padding: theme.space(4),
  backgroundColor: theme.colors.base_0,
  color: theme.colors.base_100,
  border: `2px solid ${theme.colors.base_20}`,
  fontSize: '1.6rem',
  resize: 'none',
  borderRadius: theme.constants.borderRadius,
  marginTop: theme.space(2),
  width: '100%',
  '&:hover': {
    borderColor: theme.colors.base_50,
  },
  '&:focus': {
    color: theme.colors.base_100,
    outline: 'none',
    border: `2px solid ${theme.colors.primary_1}`,
  },
}))

const StyledButtonsContainer = styled.div<{
  removeSeparator?: boolean
  isDesktop: boolean
}>(({ theme, removeSeparator, isDesktop }) => ({
  marginTop: removeSeparator ? undefined : theme.space(4),
  display: 'flex',
  gap: theme.space(2),
  ...(isDesktop ? { justifyContent: 'start' } : { flexDirection: 'column' }),
}))

const LeadInstantResponseSettings: React.FC<
  LeadInstantResponseSettingsProps
> = ({
  leadSourceName,
  enabled,
  messageLabel,
  message,
  maxLength,
  onUpdateMessage,
  isUpdatingMessage,
  onEnablementChange,
  isEnabling,
  isToggleDisabled,
  responderName,
  snippets,
  ...props
}) => {
  const defaultMessage =
    Constants.InstantResponders[responderName].defaultMessage
  const { activeLocation } = useLocationContext()
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const theme = useTheme()
  const { isSmallScreen: isDesktop } = useScreenSizes()
  const [textboxMessage, setTextboxMessage] = useState('')
  const [isRestoringMessage, setIsRestoringMessage] = useState(false)
  const { showModal, closeModal } = useModalNotificationsContext()
  const [currentSelectionCoordinates, setCurrentSelectionCoordinates] =
    useState<{ start: number; end: number }>()
  const [sendVCard, setSendVCard] = useState(props.sendVCard)

  useEffect(() => {
    setTextboxMessage(
      message && message.trim() !== '' ? message : defaultMessage
    )
  }, [message, defaultMessage])

  useEffect(() => {
    setSendVCard(props.sendVCard)
  }, [props.sendVCard])

  const handleConfirmRestoreMessage = useCallback(async () => {
    closeModal()
    setIsRestoringMessage(true)
    await onUpdateMessage?.(defaultMessage, sendVCard)
    setTextboxMessage(defaultMessage)
    setIsRestoringMessage(false)
  }, [closeModal, onUpdateMessage, defaultMessage, sendVCard])

  const handleRestoreMessage = useCallback(() => {
    showModal({
      title: 'Reset Instant Response Text Message',
      hideActionButtons: false,
      modalActionsOptions: {
        callToAction: {
          label: 'Reset text message',
          onClick: handleConfirmRestoreMessage,
        },
      },
      customBody: (
        <RestoreDefaultMessageModal defaultMessage={defaultMessage} />
      ),
      height: 'auto',
    })
  }, [defaultMessage, handleConfirmRestoreMessage, showModal])

  useEffect(() => {
    const handleSelection = () => {
      const activeElement = document.activeElement

      if (activeElement === textAreaRef.current) {
        const range = {
          start: (activeElement as HTMLTextAreaElement).selectionStart,
          end: (activeElement as HTMLTextAreaElement).selectionEnd,
        }

        setCurrentSelectionCoordinates(range)
      }
    }

    document.addEventListener('selectionchange', handleSelection)

    return () => {
      document.removeEventListener('selectionchange', handleSelection)
    }
  }, [])

  const addSnippet = (val: string) => {
    let newMessage = `${textboxMessage}${val}`

    if (currentSelectionCoordinates) {
      const beforeSelection = textboxMessage.slice(
        0,
        currentSelectionCoordinates.start
      )
      const afterSelection = textboxMessage.slice(
        currentSelectionCoordinates.end
      )

      newMessage = `${beforeSelection}${val}${afterSelection}`.trim()
    }

    setTextboxMessage(newMessage)
  }

  return (
    <PageSectionContainer
      title={
        <ItemContainer>
          <Heading as="h3">{leadSourceName}</Heading>
          <QuestionTooltip tooltipMessage={props.tooltipMessage} />
        </ItemContainer>
      }
      variant="inverted"
      action={ToggleAction}
      actionProps={{
        label: 'Send Instant Response',
        dataCy: `settings-instant-responders-${leadSourceName}-IR-toggle-${
          enabled ? 'disable' : 'enable'
        }`,
        disabled: isToggleDisabled || !!isEnabling,
        checked: isToggleDisabled ? false : !!enabled,
        onClick: () => null,
        onEnablementChange,
      }}
      shouldShow={isToggleDisabled ? false : !!enabled}
      childrenStyle={{ gap: theme.space(2) }}
    >
      <Body fontWeight="bold" color="darker">
        {messageLabel || `Message sent to incoming ${leadSourceName} leads:`}
      </Body>
      <StyledTextbox
        id="unique-id"
        rows={5}
        ref={textAreaRef}
        cols={isDesktop ? 60 : 40}
        maxLength={maxLength}
        placeholder={defaultMessage}
        value={textboxMessage}
        onChange={(e) => setTextboxMessage(e.target.value)}
      />
      {maxLength && (
        <Body size="small" color="light">
          Characters: {textboxMessage.length}/{maxLength}
        </Body>
      )}

      <ToggleActionContainer>
        <Body>
          Send Contact Information &#40;.vCard&#41;
          {!activeLocation.primaryVCardId && (
            <>
              {' '}
              <QuestionTooltip tooltipMessage="To send your contact information, please create one in the Business settings section." />
            </>
          )}
        </Body>
        <Toggle
          dataCy={`settings-instant-responders-${leadSourceName}-IR-toggle-${
            enabled ? 'disable' : 'enable'
          }`}
          disabled={isUpdatingMessage || !activeLocation.primaryVCardId}
          checked={sendVCard}
          onChange={(e) => {
            setSendVCard(e.target.checked)
          }}
        />
      </ToggleActionContainer>

      <Body fontWeight="bold" color="darker">
        Add text snippets:
      </Body>
      <StyledButtonsContainer removeSeparator isDesktop={isDesktop}>
        {snippets.map((snippet) => (
          <Button
            key={snippet.label}
            action="primary"
            outline
            maxHeight={32}
            style={{ width: 'unset' }}
            label={
              <Body customColor="primary_2" fontWeight="bold" as="span">
                {snippet.label}{' '}
                <QuestionTooltip tooltipMessage={snippet.tooltip} />
              </Body>
            }
            onClick={() => addSnippet(snippet.value)}
          />
        ))}
      </StyledButtonsContainer>
      <StyledButtonsContainer isDesktop={isDesktop}>
        <Button
          action="primary"
          maxHeight={32}
          label="Update Response"
          style={{ width: 'unset' }}
          baseDataAttribute={`settings-instant-responders-${leadSourceName}-updateIR`}
          disabled={
            isUpdatingMessage ||
            ((!!textboxMessage ? textboxMessage === message : !message) &&
              sendVCard === props.sendVCard)
          }
          onClick={() => onUpdateMessage?.(textboxMessage, sendVCard)}
        />

        {message && (
          <Button
            asLink
            maxHeight={32}
            style={{ width: 'unset' }}
            baseDataAttribute={`settings-instant-responders-${leadSourceName}-restoreIR`}
            label={
              isRestoringMessage
                ? 'Restoring default message...'
                : 'Restore default message'
            }
            loading={isRestoringMessage}
            onClick={handleRestoreMessage}
          />
        )}
      </StyledButtonsContainer>

      {props.connectable && (
        <Button
          action="danger"
          maxHeight={32}
          maxWidth={theme.space(40)}
          label={props.isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
          loading={props.isDisconnecting}
          onClick={props.onClickDisconnect}
          baseDataAttribute={`settings-instant-responders-${leadSourceName}-disconnect`}
        />
      )}
    </PageSectionContainer>
  )
}

export default LeadInstantResponseSettings
