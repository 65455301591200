import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { Button } from 'src/stories/Button'
import { Heading, Subtitle } from 'src/stories/typography'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const StyledTitle = styled(Heading)(({ theme }) => ({
  color: theme.colors.primary_1,
  margin: `${theme.space(25)} 0 0 0`,
}))

const StyledSubtitle = styled(Subtitle)(({ theme }) => ({
  color: theme.colors.base_50,
  marginTop: 0,
}))

interface Props {
  errorCode: string
  logout: () => void
}

const Auth0Error: React.FC<Props> = ({ errorCode, logout }) => {
  const [, setSearchParams] = useSearchParams()
  const theme = useTheme()

  useEffect(() => {
    setSearchParams({})
  }, [setSearchParams])

  return (
    <Container>
      <StyledTitle size="extraLarge">Something went wrong</StyledTitle>
      <StyledSubtitle size="medium">
        Please contact support and provide the following error code:
      </StyledSubtitle>
      <pre>{errorCode}</pre>

      <Button
        type="button"
        label="Logout"
        style={{ maxWidth: theme.space(25) }}
        onClick={logout}
      />
    </Container>
  )
}

export default Auth0Error
