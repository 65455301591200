import { useAuth0 } from '@auth0/auth0-react'
import { isAxiosError } from 'axios'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { AuthorizedUser, useGetAuthorization } from 'src/api'
import { UserInterface } from 'src/client/interfaces/Common'
import Auth0Error from 'src/components/Auth0Error'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import logger from 'src/utils/logger'

interface AuthContextProps {
  /** @deprecated */
  legacyUser: UserInterface
  handleLogout: () => void
  handleLoginWithRedirect: () => void
  refetchUser: () => Promise<void>
  user: AuthorizedUser
}

export const AuthContext = React.createContext<AuthContextProps>(
  {} as AuthContextProps
)

export const AuthContextProvider: React.FCWithChildren = ({ children }) => {
  const {
    logout,
    loginWithRedirect,
    isLoading: isLoadingAuthInstance,
    isAuthenticated,
    error: auth0Error,
  } = useAuth0()

  const {
    data: user,
    refetch: getAuthorization,
    error: getAuthorizationError,
    isFetching: isFetchingAuthorization,
  } = useGetAuthorization(!isLoadingAuthInstance && isAuthenticated)

  const { pathname, search } = useLocation()

  const [legacyUser] = useState<UserInterface>()

  const handleLogout = useCallback(() => {
    try {
      void logout({ logoutParams: { returnTo: window.location.origin } })
    } catch (error) {
      logger.debug(
        `Logout resource failed. This is most likely because sessions were not provided: ${JSON.stringify(
          error
        )}`
      )
    }
  }, [logout])

  const handleLoginWithRedirect = useCallback(() => {
    // If users are trying no navigate to any page of the MC,
    // a redirect param will be appended to the login URL.
    // The pathnames mentioned below are excluded when attaching
    // the redirect param to the login url
    const exemptRedirections = ['/login', '/logout', '/']
    const loginRedirection = new URL('/login', window.location.origin)
    const extraParams: Record<string, string> = {}

    if (!exemptRedirections.includes(pathname)) {
      const currentUrl = new URL(pathname + search, window.location.origin)

      loginRedirection.searchParams.append('redirect', currentUrl.toString())
    }

    if (pathname === '/login') {
      // If logged out users are landing on the login page, any of the
      // extra params below will be passed as extra params to Auth0's Login page.
      const allowedExtraParams = [
        'isSignup',
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
      ]
      // The rest of the params mentioned below will be kept as part of the redirection URL
      const allowedRedirectParams = [...allowedExtraParams, 'page', 'redirect']
      const searchParams = new URLSearchParams(search)

      allowedRedirectParams.forEach((param) => {
        if (searchParams.has(param))
          loginRedirection.searchParams.append(param, searchParams.get(param)!)
      })

      allowedExtraParams.forEach((param) => {
        if (searchParams.has(param))
          extraParams[param] = searchParams.get(param)!
      })
    }

    void loginWithRedirect({
      authorizationParams: {
        redirect_uri: loginRedirection.toString(),
        ...extraParams,
      },
    })
  }, [loginWithRedirect, pathname, search])

  const refetchUser = useCallback(async () => {
    await getAuthorization()
  }, [getAuthorization])

  useEffect(() => {
    if (!isLoadingAuthInstance && !isAuthenticated && !auth0Error) {
      handleLoginWithRedirect()
    }
  }, [
    user,
    isAuthenticated,
    handleLoginWithRedirect,
    isLoadingAuthInstance,
    auth0Error,
  ])

  useEffect(() => {
    if (
      isAxiosError(getAuthorizationError) &&
      getAuthorizationError.response?.status === 403
    ) {
      logger.debug('User is not authorized to access this resource')

      handleLogout()
    }
  }, [getAuthorizationError, handleLogout, auth0Error])

  if (auth0Error?.message) {
    return <Auth0Error errorCode={auth0Error.message} logout={handleLogout} />
  }

  if (isLoadingAuthInstance || isFetchingAuthorization)
    return <LoadingSpinner />

  if (!user) return null

  const contextValue: AuthContextProps = {
    handleLogout,
    handleLoginWithRedirect,
    legacyUser: legacyUser!, // TODO: Remove when pages that use it are re-enabled
    refetchUser,
    user,
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

const useAuthContext = () => useContext(AuthContext)

export default useAuthContext
