import React, { useCallback, useEffect } from 'react'
import { Controller, FieldPath } from 'react-hook-form'
import styled, { useTheme } from 'styled-components'

import { LocationBusinessType } from 'src/api'
import { StyledWidgetDivider } from 'src/components/Insights/Widgets/styled'
import SmsRegistrationLayout, {
  StyledCopy,
} from 'src/components/Settings/Business/SmsRegistrationLayout'
import { StyledColumnsContainer } from 'src/components/Settings/Business/styled'
import HelperTooltip from 'src/components/Settings/common/HelperTooltip'
import useSmsRegistrationFormContext, {
  SmsRegistrationFormSchema,
} from 'src/containers/Settings/Business/SmsRegistration/SmsRegistrationFormContext'
import { Button } from 'src/stories/Button'
import { HelperWidget } from 'src/stories/HelperWidget'
import Input from 'src/stories/Input'
import SectionContainer from 'src/stories/SectionContainer'
import Select, { SelectOption } from 'src/stories/Select'
import { WizardLayout } from 'src/stories/Wizard'
import { ProTipIcon } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

export const businessTypeOptions: SelectOption<LocationBusinessType>[] = [
  {
    value: LocationBusinessType.SOLE_PROPRIETORSHIP,
    label: 'Sole Proprietor (no EIN)',
  },
  { value: LocationBusinessType.PARTNERSHIP, label: 'Partnership' },
  { value: LocationBusinessType.CORPORATION, label: 'Corporation' },
  { value: LocationBusinessType.CO_OPERATIVE, label: 'Co-Operative' },
  { value: LocationBusinessType.LLC, label: 'LLC' },
  { value: LocationBusinessType.NON_PROFIT, label: 'Non-Profit' },
]

interface BusinessInfoStepProps {
  nextStep: () => void
  previousStep: () => void
}

const StyledDivider = styled(StyledWidgetDivider)(({ theme }) => ({
  marginBottom: theme.space(6),
}))

type FormPath = FieldPath<SmsRegistrationFormSchema>
const fields: FormPath[] = [
  'businessName',
  'businessType',
  'ein',
  'websiteUrl',
  'websiteUrl',
  'addressLine1',
  'addressLine2',
  'city',
  'state',
  'postalCode',
]

const BusinessInfoStep: React.FC<BusinessInfoStepProps> = ({
  nextStep,
  previousStep,
}) => {
  const { register, formState, trigger, control, watch, setValue } =
    useSmsRegistrationFormContext()
  const theme = useTheme()
  const { isMediumScreen } = useScreenSizes()

  const businessType = watch('businessType')

  const onBusinessTypeChange = useCallback(
    async (value: LocationBusinessType) => {
      if (value) {
        if (value === LocationBusinessType.SOLE_PROPRIETORSHIP) {
          setValue('ein', undefined)
        }

        await trigger(fields)
      }
    },
    [trigger, setValue]
  )

  useEffect(() => {
    void onBusinessTypeChange(businessType)
  }, [onBusinessTypeChange, businessType])

  const baseInputProps = {
    containerStyle: { marginBottom: theme.space(6) },
    errors: formState.errors,
  }

  return (
    <WizardLayout
      asideContent={
        <HelperWidget
          title="How can I find my EIN/Tax ID number?"
          icon={ProTipIcon}
          copy={
            <ul>
              <li>Check previous tax filings</li>
              <li>
                Contact the bank where you used the EIN to open a bank account
              </li>
              <li>Locate the IRS-issued notice from your EIN application</li>
              <li>
                Request an EIN search from IRS Business & Specialty Tax line at
                800-829-4933
              </li>
            </ul>
          }
        />
      }
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault()

          const isValid = await trigger(fields)

          if (isValid) {
            nextStep()
          }
        }}
      >
        <SmsRegistrationLayout
          title="Let's enter your business info"
          buttonNext={
            <Button
              label="Continue"
              baseDataAttribute="sms-registration-business-info-continue"
              disabled={Object.entries(formState.errors).some(
                ([key, value]) => {
                  if (fields.includes(key as FormPath)) {
                    return !!value
                  }

                  return false
                }
              )}
              type="submit"
            />
          }
          buttonBack={
            <Button
              action="secondary"
              label="Back"
              baseDataAttribute="sms-registration-business-info-back"
              onClick={previousStep}
            />
          }
        >
          <StyledCopy>
            Please enter your EIN (Employer Identification Number), business
            name, and address exactly as they appear on your tax form to prevent
            registration rejection.
          </StyledCopy>
          <SectionContainer
            style={{
              paddingBottom: theme.space(2),
            }}
          >
            <Input
              label="Business Name:"
              helpLabel="This needs to match the name on your tax forms to avoid registration rejection."
              placeholder="Hobbs LLC dba The Main Street Studio"
              {...register('businessName')}
              {...baseInputProps}
            />
            <StyledColumnsContainer columns={isMediumScreen ? 2 : 1}>
              <Controller
                control={control}
                render={({ field, formState: { errors } }) => {
                  return (
                    <Select
                      label="Business Type:"
                      options={businessTypeOptions}
                      placeholder="Select"
                      errorText={errors.businessType?.message}
                      onChange={(value) => {
                        field.onChange(value)
                        field.onBlur()
                      }}
                      initialValue={field.value}
                      containerStyle={{ marginBottom: theme.space(6) }}
                    />
                  )
                }}
                name={'businessType'}
              />
              <Input
                label={
                  <>
                    {'EIN (Tax ID): '}
                    <HelperTooltip
                      style={{
                        height: theme.space(4),
                      }}
                    >
                      EIN is a 9-digit tax ID assigned by the IRS. Locate your
                      EIN in previous tax filings or business loan financing
                      documents. You can also contact the IRS at 800-829-4933 to
                      retrieve your EIN.
                    </HelperTooltip>
                  </>
                }
                {...register('ein')}
                type="number"
                disabled={
                  businessType === LocationBusinessType.SOLE_PROPRIETORSHIP
                }
                {...baseInputProps}
              />
            </StyledColumnsContainer>
            <Input
              label="Website URL (optional for sole proprietors):"
              {...register('websiteUrl')}
              {...baseInputProps}
            />
            <StyledDivider />
            <Input
              label="Address line 1:"
              {...register('addressLine1')}
              {...baseInputProps}
            />
            <Input
              label="Address line 2 (optional):"
              {...register('addressLine2')}
              {...baseInputProps}
            />
            <StyledColumnsContainer columns={isMediumScreen ? 3 : 1}>
              <Input label="City:" {...register('city')} {...baseInputProps} />
              <Input
                label="State:"
                {...register('state')}
                {...baseInputProps}
              />
              <Input
                label="Zip code:"
                {...register('postalCode')}
                {...baseInputProps}
              />
            </StyledColumnsContainer>
          </SectionContainer>
        </SmsRegistrationLayout>
      </form>
    </WizardLayout>
  )
}

export default BusinessInfoStep
