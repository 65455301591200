import { isBefore, subDays } from 'date-fns'
import { toast } from 'react-toastify'

import {
  Conversation,
  ConversationEvent,
  ConversationEventDirection,
  ConversationEventTypeEnum,
  isConversationEvent,
} from 'src/api'
import { MediaResource } from 'src/client'
import client from 'src/client/client'
import { ErrorObject, Media } from 'src/client/interfaces/Common'
import {
  ConversationEventResource,
  conversationEventTypePredicate,
} from 'src/client/interfaces/ConversationsV3'
import { Segment } from 'src/client/interfaces/Segments'
import { DEFAULT_ZERO_STATE_CONVERSATION_ID } from 'src/contexts/ConversationsListContext/utils'
import Constants from 'src/lib/Constants'
import { FileTooBigError } from 'src/lib/Errors'
import { getCookie } from 'src/utils'
import logger from 'src/utils/logger'

/**
 * Get the outgoing event type based on the most recent event in a conversation.
 * This method will also be used to determining if a conversation is replyable or not.
 *
 * TODO: Future: Handle logic here to determine if a conversation is replyable or not.
 * @param message
 * @returns
 */
export const getOutgoingEventType = (
  message: ConversationEvent
): ConversationEventTypeEnum | undefined => {
  switch (message.direction) {
    case ConversationEventDirection.INCOMING:
      switch (message.type) {
        case ConversationEventTypeEnum.ANGI_LEAD_EVENT:
        case ConversationEventTypeEnum.LR_SUMMARY_EVENT:
        case ConversationEventTypeEnum.LR_VOICEMAIL_EVENT:
        case ConversationEventTypeEnum.SMS_EVENT:
          return ConversationEventTypeEnum.SMS_EVENT
        case ConversationEventTypeEnum.FACEBOOK_EVENT: {
          if (isFacebookUnreplyable(message)) {
            return undefined
          }

          return ConversationEventTypeEnum.FACEBOOK_EVENT
        }
        default:
          return undefined
      }
    case ConversationEventDirection.OUTGOING:
      switch (message.type) {
        case ConversationEventTypeEnum.PHONE_CALL_EVENT:
        case ConversationEventTypeEnum.SMS_EVENT:
          return ConversationEventTypeEnum.SMS_EVENT
        case ConversationEventTypeEnum.FACEBOOK_EVENT: {
          if (isFacebookUnreplyable(message)) {
            return undefined
          }

          return ConversationEventTypeEnum.FACEBOOK_EVENT
        }
        default:
          return undefined
      }
    default:
      return undefined
  }
}

export const isFacebookUnreplyable = (message: ConversationEvent) => {
  const facebookUnreplyable =
    isConversationEvent(message, 'FACEBOOK_EVENT') &&
    isBefore(
      message.timestamp ? new Date(message.timestamp) : new Date(),
      subDays(
        new Date(),
        Constants.conversationEvents.maxLimitDaysToReply.FACEBOOK_EVENT
      )
    )

  return facebookUnreplyable
}

export const getLegacyDocumentType = (message: ConversationEventResource) => {
  switch (message.eventType) {
    case 'AGENTZ':
      return message.eventDirection === 'incoming'
        ? Constants.COMMS.documentTypes.agentzInteraction
        : Constants.COMMS.documentTypes.agentzComm
    case 'ANGILEADS':
      return Constants.COMMS.documentTypes.homeAdvisorInteraction
    case 'FACEBOOK':
      return message.eventDirection === 'incoming'
        ? Constants.COMMS.documentTypes.facebookInteraction
        : Constants.COMMS.documentTypes.facebookComm
    case 'FEEDBACK':
      return Constants.COMMS.documentTypes.feedback
    case 'GOOGLE':
      return message.eventDirection === 'incoming'
        ? Constants.COMMS.documentTypes.googleMessagesInteraction
        : Constants.COMMS.documentTypes.googleMessagesComm
    case 'LIVE_RECEPTIONIST':
      return Constants.COMMS.documentTypes.liveReceptionistInteraction
    case 'PHONE_CALL':
      return Constants.COMMS.documentTypes.phoneCallInteraction
    case 'PUBLIC_REVIEW':
    case 'REVIEW':
      return Constants.COMMS.documentTypes.review
    case 'SMS':
      return Constants.COMMS.documentTypes.smsInteraction
    case 'THUMBTACK':
      return Constants.COMMS.documentTypes.thumbtackInteraction
  }
}

export const isGoogleMessagesUnreplyable = (
  listOfMessages?: ConversationEventResource[]
) => {
  if (!listOfMessages?.length) return false

  const googleMessagesUnreplyable =
    conversationEventTypePredicate(listOfMessages[0], 'GOOGLE') &&
    isBefore(
      listOfMessages[0].timestamp
        ? new Date(listOfMessages[0].timestamp)
        : new Date(),
      subDays(new Date(), Constants.COMMS.maxLimitDaysToReply.googleMessages)
    )

  return googleMessagesUnreplyable
}

export const isSmsAreaCodeUnreplyable = (
  listOfMessages?: ConversationEventResource[]
) => {
  if (!listOfMessages?.length) return false

  const smsAreaCodeUnreplyable = Constants.COMMS.smsDocumentTypes.some(
    (sdt) =>
      sdt === getLegacyDocumentType(listOfMessages[0]) &&
      listOfMessages[0].failureDetails?.reason ===
        Constants.COMMS.blockedAreaCodeReason
  )

  return smsAreaCodeUnreplyable
}

export const handleUploadMedia = async (medias: File[]): Promise<Media[]> => {
  const [fulfilled, rejected] = await MediaResource.bulkUploadMedia(
    medias,
    'SMS'
  )

  rejected.forEach(({ reason }) => {
    let errorMessage = Constants.ERRORS.imageUploadError

    if (reason instanceof FileTooBigError) {
      errorMessage = reason.errorMessageToDisplay
    }

    logger.error(errorMessage)
    toast.error(errorMessage)
  })

  return fulfilled.map((f) => f.value)
}

export const isEditableConversationItem = (conversationItem: Conversation) =>
  conversationItem.id !== DEFAULT_ZERO_STATE_CONVERSATION_ID

export type SegmentInterfaceWithIsPrimary = Segment & {
  isPrimary: boolean
}

export const sortSegments = (
  segments: Segment[],
  segmentIds: number[],
  primarySegmentId: number
): SegmentInterfaceWithIsPrimary[] => {
  let primarySegment: SegmentInterfaceWithIsPrimary[] = []

  const segmentsWithPrimary =
    segments.reduce(
      (a, segment) => {
        const hasSegmentId = segmentIds.some(
          (segmentId) => segmentId === segment.id
        )
        const isPrimary = segment.id === primarySegmentId

        const mappedSegment: SegmentInterfaceWithIsPrimary = {
          ...segment,
          isPrimary,
        }

        if (isPrimary) {
          primarySegment = [mappedSegment]
        }

        if (hasSegmentId && !isPrimary) {
          a.push(mappedSegment)
        }

        return a
      },

      [] as SegmentInterfaceWithIsPrimary[]
    ) || []

  return primarySegment.concat(
    segmentsWithPrimary.sort((a, b) => a.name.localeCompare(b.name))
  )
}

export const handleInflightErrors = (
  error: ErrorObject,
  defaultLogMessage: string,
  useOverride = false,
  isCustomerNotMarketingSubscribed: boolean
) => {
  let errorMessage = 'Message not sent, something went wrong. Please try again.'
  let logLevel: keyof typeof logger = 'error'

  if (error instanceof Error && error.message === 'Customer opted out') {
    errorMessage = `Message not sent, ${error.message}`
    logLevel = 'info'
  }

  // https://github.com/signpost/biscuit/blob/0169a114b392819b748a1be4797e46ceaab35671/src/redux/actions/Comms.js#L434
  if (error.status === 403) {
    // only occurs for internal users in production
    errorMessage = 'Your logged in user is not authorized to send messages'
    logLevel = 'info'
  } else if (error.status === 409) {
    errorMessage = "Message not sent, duplicated messages can't be sent"
    logLevel = 'info'
  } else if (error.status === 422) {
    // TODO: Improve this error check. Make it look for some other detail on the
    // error that definitely means "reached limit".
    // 422 could be other "invalid input" errors as well. But in practice,
    // it seems to only appear if we have a new bug with inputs, or when "reached limit".
    errorMessage =
      'Message not sent, You have reached the limit of messages sent ' +
      'to this contact without a response.'
    logLevel = 'info'
  } else if (
    error.status === 404 &&
    error.data.message === Constants.CORE_ERRORS.invalidSmsCustomer
  ) {
    if (isCustomerNotMarketingSubscribed) {
      errorMessage = Constants.ERRORS.customerNotSubscribed
      logLevel = 'info'
    }
  }

  if (useOverride) {
    if (error.data.message === Constants.ERRORS.duplicateSMS) {
      errorMessage = Constants.ERRORS.overrideDuplicateSMS
    } else if (error.data.message === Constants.ERRORS.ineligibleLocation) {
      errorMessage = Constants.ERRORS.overrideIneligibleLocation
    }
  }

  toast.error(errorMessage)

  if (logLevel) {
    logger[logLevel](defaultLogMessage, { error })
  }
}

export const displayUserFriendlyMessage = (errorMessage?: string) => {
  if (!errorMessage) {
    return ''
  } else if (errorMessage === Constants.COMMS.blockedAreaCodeReason) {
    return Constants.ERRORS.instantResponseNotSent
  } else {
    return 'Message not delivered'
  }
}

export const reportProcessDone = async () => {
  const isE2eTestRunning = getCookie('e2eTesting') === 'e2eTestsRunning'

  logger.debug('Reporting process done', { isE2eTestRunning })
  if (isE2eTestRunning) {
    try {
      await client.get(Constants.Backend.Endpoints.CYPRESS_DONE_ENDPOINT, {
        params: { resource: 'handleSendMessage' },
      })
    } catch {}
  }
}
