import React, { useEffect, useRef, useState } from 'react'
import styled, {
  CSSObject,
  DefaultTheme,
  ThemedStyledProps,
  useTheme,
} from 'styled-components'

import { Body } from './typography'
import { useClickOutside } from 'src/hooks/useClickOutside'
import { ArrowDownIcon } from 'src/stories/assets'

const StyledSelectContainer = styled.div<{ $disabled?: boolean }>(
  ({ theme, $disabled }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    flexGrow: 1,
    cursor: $disabled ? 'auto' : 'pointer',
  })
)

const StyledLabel = styled.label<Pick<SelectProps, 'hideLabel'>>(
  ({ theme, hideLabel }) => ({
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginBottom: theme.space(1),
    visibility: hideLabel ? 'hidden' : undefined,
  })
)

const StyledHelpLabel = styled.label(({ theme }) => ({
  fontSize: '1.4rem',
  color: theme.colors.base_40,
  fontWight: '400',
  marginBottom: theme.space(1),
}))

const StyledValidationErrorText = styled.label(({ theme }) => ({
  marginTop: theme.space(2),
  color: theme.colors.accent_2,
  fontSize: '1.3rem',
}))

const Wrapper = styled.div((props) => ({
  position: 'relative',
  display: 'inline-block',
  width: 'auto',
}))

interface StyledSelectProps {
  $error?: boolean
  $height?: string
  $selected?: boolean
  darkBackground?: boolean
  disabled: boolean
}

const sharedSelectStyles = ({
  theme,
  $error,
  $height,
  $selected,
  darkBackground,
  disabled,
}: ThemedStyledProps<StyledSelectProps, DefaultTheme>): CSSObject => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.space(2),
  height: $height || theme.space(11),
  padding: `0 ${theme.space(3)}`,
  backgroundColor: darkBackground ? theme.colors.base_10 : theme.colors.base_0,
  color: $error ? theme.colors.accent_2 : theme.colors.base_100,
  // Not using space function since this specific 2px is being set
  // to compensate the 1px added in each side by the border.
  width: $selected ? '100%' : `calc(100% - 2px)`,
  // Not using space function since this specific 1px is being set
  // to compensate the 1px added on the left side by the border.
  marginLeft: !$selected ? '1px' : undefined,
  // Not using space function since this specific 1px is being set
  // to compensate the 1px added on the right side by the border.
  marginRight: !$selected ? '1px' : undefined,
  border: `${$selected ? '2px' : '1px'} solid ${
    $error
      ? theme.colors.accent_2
      : $selected
      ? theme.colors.primary_1
      : theme.colors.base_20
  }`,
  borderRadius: theme.constants.borderRadius,
  fontSize: '1.5rem',

  '&:hover': {
    borderColor: $error
      ? theme.colors.accent_2
      : $selected
      ? theme.colors.primary_1
      : disabled
      ? theme.colors.base_20
      : theme.colors.base_50,
  },

  '&:focus': {
    color: theme.colors.base_100,
    outline: 'none',
    border: `2px solid ${
      $error ? theme.colors.accent_2 : theme.colors.primary_1
    }`,
  },
})

const SelectDiv = styled.div<StyledSelectProps>(sharedSelectStyles)
const SelectInput = styled.input<StyledSelectProps>((props) => ({
  ...sharedSelectStyles(props),
  paddingRight: props.theme.space(8),
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}))

const OptionsContainer = styled.div<{ $openDirection?: 'up' | 'down' }>(
  ({ theme, $openDirection = 'down' }) => ({
    marginTop: theme.space(2),
    zIndex: theme.zIndexes.select,
    position: 'absolute',
    bottom: $openDirection === 'up' ? '100%' : undefined,
    left: '0',
    width: '100%',
  })
)

const Options = styled.ul(({ theme }) => ({
  padding: 0,
  margin: 0,
  paddingTop: theme.space(1),
  paddingBottom: theme.space(1),
  background: theme.colors.base_0,
  border: `1px solid ${theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
  boxSizing: 'border-box',
  color: theme.colors.base_100,
  fontSize: '1.5rem',
  maxHeight: theme.space(70),
  overflowY: 'auto',
}))

const Option = styled.li<{ selected?: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  gap: theme.space(2),
  alignItems: 'center',
  listStyle: 'none',
  height: theme.space(8),
  lineHeight: theme.space(8),
  background: selected ? theme.colors.primary_2_10 : theme.colors.base_0,
  fontWeight: selected ? 500 : 400,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  paddingLeft: theme.space(4),
  ':hover': {
    background: selected ? theme.colors.primary_2_15 : theme.colors.base_5,
  },
}))

const InputWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
}))

const Divider = styled.div(({ theme }) => ({
  height: theme.space(0.5),
  background: theme.colors.base_5,
  borderRadius: '0px',
}))

export interface SelectOption<T extends string | number = string> {
  label: string
  value: T
  icon?: JSX.Element
}

export interface SelectProps<TValue extends string | number = string> {
  dataCy?: string
  label?: string
  helpLabel?: string
  hideLabel?: boolean
  errorText?: string
  height?: string
  disabled?: boolean
  darkBackground?: boolean
  openedOptionsDirection?: 'up' | 'down'
  inputStyle?: React.CSSProperties
  containerStyle?: React.CSSProperties
  options: SelectOption<TValue>[]
  onChange?: (value: TValue) => void
  initialValue?: TValue
  placeholder?: string
  children?: React.ReactNode
  searchable?: boolean
  selectAllOnFocus?: boolean
}

const placeholderSentry = 'PLACEHOLDER_SENTRY'

const Select = <TValue extends string | number = string>({
  label,
  helpLabel,
  hideLabel,
  errorText,
  openedOptionsDirection = 'down',
  inputStyle,
  containerStyle,
  options,
  height,
  disabled = false,
  darkBackground = false,
  initialValue,
  onChange,
  dataCy = 'select',
  placeholder,
  children,
  searchable = false,
  selectAllOnFocus = false,
}: SelectProps<TValue>) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)

  const [selectedOption, setSelectedOption] = useState<
    TValue | typeof placeholderSentry | undefined
  >(
    placeholder
      ? placeholderSentry
      : options.find((o) => o.value === initialValue)?.value ??
          options[0]?.value
  )
  const [inputValue, setInputValue] = useState<string>()

  const selectRef = useRef<HTMLDivElement>(null)

  useClickOutside({ ref: selectRef, onClickOutside: () => setIsOpen(false) })

  const toggling = () => {
    if (!disabled) {
      setIsOpen((value) => !value)
    }
  }

  const availableOptions =
    inputValue && searchable
      ? options.filter((o) =>
          o.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      : options

  const onOptionClicked = (value: TValue) => () => {
    setSelectedOption(value)
    setInputValue(availableOptions.find((o) => o.value === value)?.label)
    setIsOpen(false)
    onChange?.(value)
  }

  useEffect(() => {
    const newOption = options.find((o) => o.value === initialValue)?.value

    if (newOption) {
      setSelectedOption(newOption)
    }

    // Disabling the eslint rule since we only want to run this effect once, when the component mounts.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const displayValue =
    selectedOption === placeholderSentry
      ? placeholder
      : availableOptions.find((o) => o.value === selectedOption)?.label

  return (
    <StyledSelectContainer
      style={containerStyle}
      data-cy={dataCy}
      $disabled={disabled}
    >
      {label && (
        <StyledLabel data-cy={dataCy + '-label'} hideLabel={hideLabel}>
          {label}
        </StyledLabel>
      )}
      {helpLabel && <StyledHelpLabel>{helpLabel}</StyledHelpLabel>}
      <Wrapper data-cy={dataCy + '-wrapper'}>
        {!searchable ? (
          <SelectDiv
            data-cy={dataCy + '-input'}
            onClick={toggling}
            $error={!!errorText}
            $height={height}
            $selected={isOpen}
            disabled={disabled}
            darkBackground={darkBackground}
            style={inputStyle}
          >
            <Body ellipsis color="darker" size="medium">
              {displayValue}
            </Body>
            <ArrowDownIcon stroke={theme.colors.base_50} />
          </SelectDiv>
        ) : (
          <InputWrapper>
            <SelectInput
              data-cy={dataCy + '-input'}
              onClick={toggling}
              $error={!!errorText}
              $height={height}
              $selected={isOpen}
              disabled={disabled}
              darkBackground={darkBackground}
              style={inputStyle}
              value={inputValue ?? placeholder}
              onChange={(e) => setInputValue(e.target.value)}
              onFocus={(e) => selectAllOnFocus && e.target.select()}
            />
            <ArrowDownIcon
              stroke={theme.colors.base_50}
              style={{ position: 'absolute', right: theme.space(3) }}
            />
          </InputWrapper>
        )}
        {isOpen && !!availableOptions.length && (
          <OptionsContainer
            ref={selectRef}
            $openDirection={openedOptionsDirection}
            data-cy={dataCy + '-options-container'}
          >
            <Options data-cy={dataCy + '-options'}>
              {availableOptions.map(
                ({ label: optionLabel, value, icon }, idx, arr) => (
                  <React.Fragment key={`${optionLabel}-${value}`}>
                    <Option
                      onClick={onOptionClicked(value)}
                      selected={selectedOption === value}
                      data-cy={
                        dataCy +
                        `-option-${optionLabel
                          .split(' ')
                          .filter(Boolean)
                          .join('-')}`
                      }
                    >
                      {icon}
                      {optionLabel}
                    </Option>
                    {idx + 1 !== arr.length && <Divider />}
                  </React.Fragment>
                )
              )}
              {children && <Divider />}
              {children}
            </Options>
          </OptionsContainer>
        )}
      </Wrapper>
      {errorText && (
        <StyledValidationErrorText data-cy={dataCy + `-error-text`}>
          {errorText}
        </StyledValidationErrorText>
      )}
    </StyledSelectContainer>
  )
}

export default Select
