import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { useTheme } from 'styled-components'

import ContactsTagsSection from './ContactsTagsSection'
import ContactInfoSection from 'src/components/MessagingHub/ContactDetailsPane/ContactInfoSection'
import ContactLists from 'src/components/MessagingHub/ContactDetailsPane/ContactListsSection'
import LifecycleSection from 'src/components/MessagingHub/ContactDetailsPane/LifecycleSection'
import NoteSection from 'src/components/MessagingHub/ContactDetailsPane/NoteSection'
import { useLocationContext } from 'src/contexts/LocationContext'
import useMhContext from 'src/contexts/MhContext'
import { Button } from 'src/stories/Button'
import { formatPhoneNumber, generateLocationUrl, slugify } from 'src/utils'

const Container = styled.div(({ theme }) => ({
  padding: theme.space(6),
  paddingTop: theme.space(3),
  overflowY: 'auto',
  height: 'auto',
}))

const Divider = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.base_10}`,
  marginTop: theme.space(4),
  marginBottom: theme.space(4),
}))

// Sections

const Section = styled.div(() => ({}))

const SectionHeader = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

const SectionHeaderLabel = styled.p(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.colors.base_40,
  fontWeight: 500,
  fontSize: '1rem',
}))
const SectionContent = styled.div(() => ({}))

interface SectionInterface {
  visible: boolean
  label: string
  link?: { onClick?: () => void; href?: string; label?: string } | false
  content: ReactElement
}

// Component

const generateDataCy = (label: string) => `mh-contact-details-${label}`

const ContactDetailsPane: React.FC = () => {
  const theme = useTheme()
  const { locationId } = useLocationContext()

  const listsRef = useRef<HTMLButtonElement>(null)

  const { segments, openConversationContactDetails } = useMhContext()

  const showContactPageLink = true

  const [isEditingLifecycle, setIsEditingLifecycle] = useState(false)
  const [isEditingTextArea, setIsEditingTextArea] = useState(false)

  const customerProfileUrl = useMemo(
    () =>
      generateLocationUrl(
        locationId,
        `/contacts/${openConversationContactDetails.id ?? ''}`
      ),
    [openConversationContactDetails, locationId]
  )

  const contactTagAttributesUrl = useMemo(
    () => generateLocationUrl(locationId, `/settings/messaging-hub`),
    [locationId]
  )

  const generateNotesButtonLabels = (isEditing: boolean, notes?: string) => {
    if (notes) {
      return isEditing ? 'Cancel' : 'Edit'
    }

    return isEditing ? 'Cancel' : 'Add'
  }

  useEffect(() => {
    setIsEditingLifecycle(false)
    setIsEditingTextArea(false)
  }, [openConversationContactDetails])

  const sections = useMemo<SectionInterface[]>(
    () =>
      [
        {
          visible: !!openConversationContactDetails.id,
          label: 'Tags',
          link: {
            href: contactTagAttributesUrl,
            label: 'Edit Tags',
          },
          content: (
            <ContactsTagsSection
              contactId={openConversationContactDetails.id!}
              contactTagIds={openConversationContactDetails.tagIds ?? []}
            />
          ),
        },
        {
          visible: true,
          label: 'Contact Info',
          link: showContactPageLink && {
            href: customerProfileUrl,
          },
          content: (
            <ContactInfoSection
              locationId={locationId}
              contactId={openConversationContactDetails.id!}
              name={openConversationContactDetails.name}
              firstName={openConversationContactDetails.firstName}
              lastName={openConversationContactDetails.lastName}
              hasWrittenName={!!openConversationContactDetails.hasWrittenName}
              email={openConversationContactDetails.email || ''}
              phone={formatPhoneNumber(
                openConversationContactDetails.phoneNumber || ''
              )}
            />
          ),
        },
        {
          visible: false,
          label: 'Lifecycle',
          link: {
            label: isEditingLifecycle ? 'Cancel' : 'Edit',
            onClick: () => setIsEditingLifecycle((prev) => !prev),
          },
          content: (
            <LifecycleSection
              contactId={openConversationContactDetails.id!}
              isEditingLifecycle={isEditingLifecycle}
              lifecycleStage={
                openConversationContactDetails.lifecycleStage || 'UNKNOWN'
              }
            />
          ),
        },
        {
          visible: false,
          label: 'Contact Lists',
          link: { label: 'Edit' },
          content: (
            <ContactLists
              contactId={openConversationContactDetails.id!}
              editRef={listsRef}
              segments={segments}
              contactSegments={openConversationContactDetails.segmentIds ?? []}
              primarySegmentId={
                openConversationContactDetails.primarySegmentId ?? -1
              }
            />
          ),
        },
        {
          visible: false,
          label: 'Note',
          link: {
            label: generateNotesButtonLabels(
              isEditingTextArea,
              openConversationContactDetails.notes
            ),
            onClick: () => setIsEditingTextArea(!isEditingTextArea),
          },
          content: (
            <NoteSection
              text={openConversationContactDetails.notes}
              lastUpdated={`${openConversationContactDetails.updatedAt!}`}
              isEditingTextArea={isEditingTextArea}
            />
          ),
        },
      ].filter((section) => section.visible),
    [
      locationId,
      segments,
      customerProfileUrl,
      openConversationContactDetails,
      isEditingLifecycle,
      isEditingTextArea,
      showContactPageLink,
      contactTagAttributesUrl,
    ]
  )

  return (
    <Container data-cy={generateDataCy('content-container')}>
      {sections.map((section, idx) => {
        const sectionName = slugify(section.label)

        if (section.visible) {
          return (
            <React.Fragment key={section.label}>
              {idx !== 0 && (
                <Divider data-cy={generateDataCy(`divider-${idx}`)} />
              )}
              <Section data-cy={generateDataCy(sectionName)}>
                <SectionHeader
                  data-cy={generateDataCy(`${sectionName}-header`)}
                >
                  <SectionHeaderLabel
                    data-cy={generateDataCy(`${sectionName}-label`)}
                  >
                    {section.label}
                  </SectionHeaderLabel>
                  {section.link !== false && (
                    <Button
                      reference={
                        section.label === 'Contact Lists' ? listsRef : undefined
                      }
                      baseDataAttribute={generateDataCy(
                        `${
                          section.link?.label?.toLocaleLowerCase() ||
                          'contact-page'
                        }-${sectionName}-link`
                      )}
                      displayAsText
                      label={section.link?.label || 'Contact page'}
                      style={{ height: theme.space(8) }}
                      href={section.link?.href}
                      onClick={section.link?.onClick}
                    />
                  )}
                </SectionHeader>
                <SectionContent
                  data-cy={generateDataCy(`${sectionName}-content`)}
                >
                  {section.content}
                </SectionContent>
              </Section>
            </React.Fragment>
          )
        }

        return null
      })}
    </Container>
  )
}

export default ContactDetailsPane
