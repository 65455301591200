import React, { useCallback, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components'

import Conversation from './Conversation'
import MessageSend from './MessageSend'
import ZeroState from './ZeroState'
import { Conversation as ConversationType } from 'src/api'
import {
  ActiveConversationMessages,
  HandleTabConversationChangeFn,
  StoreActiveConversationParams,
} from 'src/containers/MessagingHub/types'
import { canMarkConversationAsRead } from 'src/containers/MessagingHub/utils'
import useAuthContext from 'src/contexts/AuthContext'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import { DEFAULT_ZERO_STATE_CONVERSATION_ID } from 'src/contexts/ConversationsListContext/utils'
import { useLocationContext } from 'src/contexts/LocationContext'
import useMhContext from 'src/contexts/MhContext'
import { Button } from 'src/stories/Button'
import { Col } from 'src/stories/Layout'
import { ArrowIcon } from 'src/stories/assets'
import logger from 'src/utils/logger'

const StyledConversationsBox = styled(Col)((props) => ({
  minWidth: 0,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

interface StyledConversationsWrapperContainerProps {
  isMediumDesktop: boolean
}

const StyledConversationsWrapperContainer =
  styled.div<StyledConversationsWrapperContainerProps>(
    ({ isMediumDesktop, theme }) => ({
      height: !isMediumDesktop ? `calc(100% - ${theme.space(4)})` : '100%',
      minWidth: 0,
      display: 'flex',
      flexDirection: 'column',
    })
  )

const StyledBackButtonContainer = styled.div(({ theme }) => ({
  padding: `${theme.space(2)} ${theme.space(4)}`,
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.colors.base_10}`,
}))

interface Props {
  activeConversations: ActiveConversationMessages
  storeActiveConversation: (params: StoreActiveConversationParams) => void
  activeConversation?: ConversationType
  handleTabConversationChange: HandleTabConversationChangeFn
}

export type CalculateMessageBoxHeight = (
  el: React.KeyboardEvent<HTMLTextAreaElement>,
  attachmentsAreDisplayed: boolean
) => void

const MessagesPane: React.FC<Props> = ({
  activeConversations,
  storeActiveConversation,
  activeConversation,
  handleTabConversationChange,
}) => {
  const { user } = useAuthContext()
  const { preventUiUpdates } = useConversationsListContext()
  const {
    loadingConversationMessages,
    isOpenConversationChangeInFlight,
    contentValidColWidth,
    openConversationId,
    isUnreplyable,
    // docTypeToReply,
    isZeroState,
    handleSendMessage,
    mutateConversationRead,
    mutateConversationArchived,
    isMediumDesktop,
  } = useMhContext()
  const { locationId } = useLocationContext()

  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const theme = useTheme()

  const [computedTextboxHeight, setComputedTextboxHeight] = useState(
    theme.space(32)
  )

  // Calculates the height of the text box, as well as the remaining space to be removed from the messages
  // container, by using the textbox current scroll height. It helps keep the relationship between the
  // custom behavior of the textbox and the messages list as the user types into the box
  const calculateMessageBoxHeight: CalculateMessageBoxHeight = (
    el,
    attachmentsAreDisplayed
  ) => {
    const boxAbsoluteHeightWoTextbox = 36

    if (textAreaRef.current) {
      let baseHeight = 0

      if (attachmentsAreDisplayed) baseHeight = 5

      if (el.currentTarget.scrollTop === 0) {
        setComputedTextboxHeight(theme.space(baseHeight + 32))
        textAreaRef.current.style.height = theme.space(0)
        textAreaRef.current.style.overflowY = 'hidden'
      }

      if (
        el.currentTarget.scrollHeight > 30 &&
        el.currentTarget.scrollHeight <= 100
      ) {
        setComputedTextboxHeight(
          theme.space(
            baseHeight +
              boxAbsoluteHeightWoTextbox +
              el.currentTarget.scrollHeight / 4
          )
        )
        textAreaRef.current.style.height = `${el.currentTarget.scrollHeight}px`
        textAreaRef.current.style.overflowY = 'hidden'
      } else if (el.currentTarget.scrollHeight > 100) {
        setComputedTextboxHeight(theme.space(baseHeight + 54))
        textAreaRef.current.style.height = theme.space(25)
        textAreaRef.current.style.overflowY = 'scroll'
      }
    }
  }

  const markConversationAsRead = useCallback(() => {
    if (
      activeConversation?.mostRecentEvent &&
      !activeConversation?.mostRecentEvent.isRead &&
      !!openConversationId &&
      canMarkConversationAsRead(user.roles, openConversationId)
    ) {
      void mutateConversationRead({
        locationId,
        conversationId: openConversationId,
        conversationEventId: activeConversation.mostRecentEvent.id,
        isRead: true,
      })
    }
  }, [
    activeConversation,
    locationId,
    mutateConversationRead,
    openConversationId,
    user.roles,
  ])

  const displayZeroState =
    isZeroState || openConversationId === DEFAULT_ZERO_STATE_CONVERSATION_ID

  const goBackToConversationsListOnMobile = () => {
    handleTabConversationChange({
      mode: 'conversation',
      doNavigate: false,
    })
  }

  if (!openConversationId) {
    return null
  }

  return (
    <StyledConversationsBox
      w={contentValidColWidth}
      unspaced
      data-cy="messages-pane"
    >
      {!isMediumDesktop && (
        <StyledBackButtonContainer>
          <Button
            label="Back to Conversations"
            displayAsText
            onClick={goBackToConversationsListOnMobile}
            size="medium"
            icon={ArrowIcon}
          />
        </StyledBackButtonContainer>
      )}

      <StyledConversationsWrapperContainer isMediumDesktop={isMediumDesktop}>
        {displayZeroState && (
          <ZeroState
            greeting={user.firstName ? `Hi, ${user.firstName}. ` : ''}
          />
        )}

        {!displayZeroState && (
          <StyledConversationsWrapperContainer
            isMediumDesktop={isMediumDesktop}
          >
            {/* Conversation/Messages list */}
            <Conversation textboxHeight={computedTextboxHeight} />
            {/* Text box, attach and send */}
            <MessageSend
              onAttach={() => logger.debug('Attach')}
              onSend={async (message, media, sendVCard) => {
                // TODO: Handle unsubscribed contacts
                await handleSendMessage(message, media, sendVCard, false)
                if (activeConversation?.isArchived)
                  void mutateConversationArchived({
                    locationId,
                    conversationId: openConversationId,
                    isArchived: true,
                  }).catch((e) => {
                    toast.error('Failed to unarchive conversation')
                  })
              }}
              activeConversations={activeConversations}
              storeActiveConversation={storeActiveConversation}
              errorMessage=""
              textAreaRef={textAreaRef}
              calculateHeight={calculateMessageBoxHeight}
              forceDisable={
                loadingConversationMessages ||
                isOpenConversationChangeInFlight ||
                preventUiUpdates ||
                isUnreplyable
              }
              onFocusTextBox={markConversationAsRead}
              onChangeTextBox={markConversationAsRead}
            />
          </StyledConversationsWrapperContainer>
        )}
      </StyledConversationsWrapperContainer>
    </StyledConversationsBox>
  )
}

export default MessagesPane
