import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { ChannelColumn, ChannelRow } from './styled'
import { Channel, Contact, useGetContact } from 'src/api'
import WafLayout from 'src/components/WafLayout'
import PageLayout from 'src/components/WafLayout/PageLayout'
import { useLocationContext } from 'src/contexts/LocationContext'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import SectionContainer from 'src/stories/SectionContainer'
import { Body } from 'src/stories/typography'
import { formatPhoneNumber } from 'src/utils'

interface Props {
  isMultiLocation: boolean
}

const splitContacts = (contact?: Contact) => {
  const primaryPhoneNumber = contact?.phoneNumber
  const primaryEmailAddress = contact?.emailAddress

  return (contact?.channels ?? []).reduce<{
    emails: Channel[]
    phones: Channel[]
  }>(
    (a, c) => {
      if (c.emailAddress) {
        if (c.emailAddress === primaryEmailAddress) {
          a.emails.unshift(c)
        } else {
          a.emails.push(c)
        }
      }
      if (c.phoneNumber) {
        if (c.phoneNumber === primaryPhoneNumber) {
          a.phones.unshift(c)
        } else {
          a.phones.push(c)
        }
      }

      return a
    },
    { emails: [], phones: [] }
  )
}

const ViewContact: React.FC<Props> = ({ isMultiLocation }) => {
  const { contactId } = useParams()
  const { locationId } = useLocationContext()
  const theme = useTheme()

  const { data: contact, isFetching } = useGetContact({ locationId, contactId })

  const { emails, phones } = useMemo(() => splitContacts(contact), [contact])

  const rows = [
    {
      label: 'Email:',
      columns: emails.map((channel) => ({
        id: channel.id,
        el: (
          <a href={`mailto:${channel.emailAddress!}`}>
            {channel.emailAddress!}
          </a>
        ),
      })),
    },
    {
      label: 'Phone:',
      columns: phones.map((channel) => ({
        id: channel.id,
        el: (
          <a href={`tel:${channel.phoneNumber!}`}>
            {formatPhoneNumber(channel.phoneNumber!)}
          </a>
        ),
      })),
    },
  ]

  return (
    <WafLayout
      layoutTitle="View contact"
      styleOptions={{
        withMultiLocationHeader: isMultiLocation,
        showDropShadow: true,
      }}
    >
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        contact && (
          <PageLayout
            baseDataAttribute="contacts-view-contact"
            title={[contact.firstName, contact.lastName]
              .filter(Boolean)
              .join(' ')}
          >
            <SectionContainer
              childrenStyle={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.space(5),
              }}
            >
              {rows.map((row) => (
                <ChannelRow key={row.label}>
                  <Body as="p" size="medium" fontWeight="bolder" color="darker">
                    {row.label}
                  </Body>
                  <ChannelColumn>
                    {row.columns.map((column) => (
                      <Body key={column.id} as="p" size="medium">
                        {column.el}
                      </Body>
                    ))}
                  </ChannelColumn>
                </ChannelRow>
              ))}
            </SectionContainer>
          </PageLayout>
        )
      )}
    </WafLayout>
  )
}

export default ViewContact
