import { Navigate, Route, Routes } from 'react-router-dom'

import Integrations from './Integrations'
import LocationMessagingHubSettings from './Location/MessagingHub'
import LocationPhoneSettings from './Location/Phone'
import { LocationUsers } from './Location/Users'
import { useGetLocationFeatureFlag } from 'src/api'
import WafLayout, { WafLayoutProps } from 'src/components/WafLayout'
import Billing from 'src/containers/Settings/Billing'
import Business from 'src/containers/Settings/Business'
import SmsRegistration from 'src/containers/Settings/Business/SmsRegistration'
import InstantResponders from 'src/containers/Settings/InstantResponders'
import useAccountContext from 'src/contexts/AccountContext'
import { useLocationContext } from 'src/contexts/LocationContext'
import Constants from 'src/lib/Constants'
import { useSidebarRoutes } from 'src/routes/hooks'
import { BaseLegacyPageProps } from 'src/utils/interfaces'

type SettingsProps = BaseLegacyPageProps

const Settings: React.FC<SettingsProps> = ({ isMultiLocation }) => {
  const { locationId } = useLocationContext()
  const { locations } = useAccountContext()

  const { data: textingEnabledFeatureFlag } = useGetLocationFeatureFlag({
    locationId,
    featureFlagName: Constants.FeatureFlags.isTextingEnabled,
    defaultValue: 0,
  })
  const isTextingEnabled = textingEnabledFeatureFlag.value === 1
  const { settingSidebarRoutes } = useSidebarRoutes(locationId, locations)

  const wafLayoutProps: Omit<WafLayoutProps, 'children'> = {
    sidebarTitle: 'Settings',
    sidebarRoutes: settingSidebarRoutes,
    styleOptions: {
      removePadding: false,
      withMultiLocationHeader: isMultiLocation,
      invertedBackground: true,
    },
  }

  return (
    <Routes>
      <Route path="business/sms-registration" element={<SmsRegistration />} />
      <Route
        path="business"
        element={
          <WafLayout {...wafLayoutProps} displayFooter>
            <Business />
          </WafLayout>
        }
      />

      <Route
        path="billing"
        element={
          <WafLayout {...wafLayoutProps}>
            <Billing />
          </WafLayout>
        }
      />

      <Route
        path="team"
        element={<LocationUsers layoutProps={wafLayoutProps} />}
      />

      {isTextingEnabled && (
        <Route
          path="phone"
          element={
            <WafLayout {...wafLayoutProps}>
              <LocationPhoneSettings />
            </WafLayout>
          }
        />
      )}

      <Route
        path="messaging-hub"
        element={
          <WafLayout {...wafLayoutProps}>
            <LocationMessagingHubSettings />
          </WafLayout>
        }
      />

      {isTextingEnabled && (
        <Route
          path="instant-responders"
          element={
            <WafLayout {...wafLayoutProps}>
              <InstantResponders />
            </WafLayout>
          }
        />
      )}

      {isTextingEnabled && (
        <Route
          path="integrations"
          element={
            <WafLayout {...wafLayoutProps}>
              <Integrations />
            </WafLayout>
          }
        />
      )}

      <Route path="*" element={<Navigate to="./business" replace />} />
    </Routes>
  )
}

export default Settings
