export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface Sort {
  direction?: SortDirection
  field?: string
}

export interface Pagination {
  take?: number
  skip?: number
}

export interface Paginated<T> {
  total: number
  data: T[]
}

export enum Role {
  ACCOUNT_USER = 'ACCOUNT_USER',
  ACCOUNT_READONLY = 'ACCOUNT_READONLY',
  INTERNAL_ADMIN = 'INTERNAL_ADMIN',
  INTERNAL_READONLY = 'INTERNAL_READONLY',
}

export interface ListResourceParams {
  locationId: number
  search?: string
  pagination: Required<Pagination>
  sort?: Sort
}

export interface AuthorizedUser {
  id: number
  emailAddress: string
  firstName: string
  lastName: string
  phoneNumber: string
  roles: Role[]
}

export interface OperatingHour {
  dayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6
  from: string
  to: string
  isClosed: boolean
  isInvalid?: boolean
}

export type OperatingHours = [
  OperatingHour,
  OperatingHour,
  OperatingHour,
  OperatingHour,
  OperatingHour,
  OperatingHour,
  OperatingHour
]

export interface Account {
  id: number
  name: string
}

export enum LocationPermission {
  MANAGE_LOCATION = 'MANAGE_LOCATION',
  READ_LOCATION = 'READ_LOCATION',
}

export enum InternalPermission {
  WRITE_ALL = 'WRITE_ALL',
  READ_ALL = 'READ_ALL',
}

export const Permissions = {
  ...LocationPermission,
  ...InternalPermission,
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Permissions = LocationPermission | InternalPermission

export interface UserLocationPermission {
  permission: Permissions[]
}

export interface User {
  id: number
  emailAddress: string
  firstName: string
  lastName: string
  phoneNumber: string
}

export enum LocationBusinessType {
  PARTNERSHIP = 'PARTNERSHIP',
  CORPORATION = 'CORPORATION',
  CO_OPERATIVE = 'CO_OPERATIVE',
  LLC = 'LLC',
  NON_PROFIT = 'NON_PROFIT',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
}

export interface LocationPhoneNumber {
  id: number
  locationId: number
  phoneNumber: string
  messagingServiceId: number
}

export interface Location {
  id: number
  accountId: number
  name: string
  addressStreet1: string
  addressStreet2?: string
  addressCity: string
  addressState: string
  addressZipCode: string
  phoneNumber: string
  primaryRentedPhoneNumber?: string
  primaryRentedPhoneNumberId?: number
  description?: string
  website?: string
  operatingHours: OperatingHours
  employerIdentificationNumber?: number
  primaryVCardId?: number
  primaryVCard?: VCard
  businessType?: LocationBusinessType
  /** only available via `activeLocation` in `LocationsContext` */
  registrationStatus?: SmsRegistrationStatus
}

export interface Channel {
  id: number
  emailAddress?: string
  phoneNumber?: string
  addedAt: string
}

export interface Contact {
  id: number
  firstName: string
  lastName: string
  emailAddress?: string
  phoneNumber?: string
  addedAt: string
  channels: Channel[]
  tagIds: number[]
}

export interface Invoice {
  id: number
  startDate: string
  endDate: string
  included?: number
  payAmount?: number
  paid?: string
  s3Path?: string
  signedUrl?: string
}

export interface LrSummaryCallNumbersByWrapupCode {
  callBackRequest: number
  scheduling: number
  solicitor: number
  billing: number
  quote: number
  complaint: number
  other: number
}

export interface LrSummaryCallsByWeekday {
  sunday: number
  monday: number
  tuesday: number
  wednesday: number
  thursday: number
  friday: number
  saturday: number
}

export interface LrSummary {
  /** ISO Date */
  startDate: string
  /** ISO Date */
  endDate: string
  numOfCalls: number
  numOfSolicitorCalls: number
  estimatedMinutesSavedFromSolicitorCalls: number
  planUsageSeconds: number
  avgDurationSeconds: number
  estimatedMinutesSavedPerSolicitorCall: number
  callNumbersByWrapupCode: LrSummaryCallNumbersByWrapupCode
  /**
   * Warning: As of 2023-08-01, the order of these
   * attributes seems to matter to how they're displayed in the
   * widget. Sunday must come first.
   */
  callsByWeekday: LrSummaryCallsByWeekday
}

export enum ConversationEventTypeEnum {
  MH_WELCOME_MESSAGE_EVENT = 'MH_WELCOME_MESSAGE_EVENT',
  // Actual Conversation Event Types
  ANGI_LEAD_EVENT = 'ANGI_LEAD_EVENT',
  FACEBOOK_EVENT = 'FACEBOOK_EVENT',
  LR_SUMMARY_EVENT = 'LR_SUMMARY_EVENT',
  LR_VOICEMAIL_EVENT = 'LR_VOICEMAIL_EVENT',
  PHONE_CALL_EVENT = 'PHONE_CALL_EVENT',
  SMS_EVENT = 'SMS_EVENT',
}

export type ConversationEventType = keyof typeof ConversationEventTypeEnum

export enum ConversationEventDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

interface BaseConversationEventDetail {
  id: number
  timestamp: string
}

export interface LrRecording {
  endTime: string
  id: number
  path: string
  startTime: string
  url?: string
}

interface LrSummaryConversationEventDetail extends BaseConversationEventDetail {
  displayFieldsJson: Record<string, unknown>[]
  recordings: LrRecording[]
}

interface LrVoicemailConversationEventDetail
  extends BaseConversationEventDetail {
  recordings: LrRecording[]
}

type MhWelcomeConversationEventDetail = BaseConversationEventDetail

interface SmsConversationEventDetail extends BaseConversationEventDetail {
  attachments: {
    url: string
    contentType: string
  }[]
}

interface FacebookConversationEventDetail extends BaseConversationEventDetail {
  status?: string
  externalId?: string
  timestamp: string
}

interface PhoneCallConversationEventDetail extends BaseConversationEventDetail {
  status?: string
}

interface AngiLeadsConversationEventDetailInterview {
  question: string
  answer: string
}

export interface AngiLeadsConversationEventDetailAppointment {
  appointmentOid: string
  status: string
  type: string
  start: string
  end: string
  externalId: string
}

interface AngiLeadsConversationEventDetail extends BaseConversationEventDetail {
  firstName: string
  lastName: string
  primaryPhone: string
  phoneExt?: string
  secondaryPhone?: string
  secondaryPhoneExt?: string
  email: string
  address: string
  city: string
  stateProvince: string
  postalCode: string
  taskName: string
  comments: string
  interview: AngiLeadsConversationEventDetailInterview[]
  leadSource?: string
  matchType: string
  fee: string
  leadDescription: string
  crmKey?: string
  leadId: number
  contactStatus?: string
  appointment?: AngiLeadsConversationEventDetailAppointment[]
}

export type ConversationEventDetails =
  | BaseConversationEventDetail
  | MhWelcomeConversationEventDetail
  // Actual Conversation Event Details
  | AngiLeadsConversationEventDetail
  | FacebookConversationEventDetail
  | LrSummaryConversationEventDetail
  | LrVoicemailConversationEventDetail
  | PhoneCallConversationEventDetail
  | SmsConversationEventDetail

export interface ConversationEvent {
  id: number
  message: string
  errorMessage?: string
  channelId: number
  isRead: boolean
  isInstantResponse: boolean
  type: ConversationEventTypeEnum
  details?: ConversationEventDetails
  /** ISO String */
  timestamp: string
  direction: ConversationEventDirection
}

export type ConversationEventDetailType<T extends ConversationEventType> = {
  MH_WELCOME_MESSAGE_EVENT: MhWelcomeConversationEventDetail
  // Actual Conversation Event Details
  ANGI_LEAD_EVENT: AngiLeadsConversationEventDetail
  FACEBOOK_EVENT: FacebookConversationEventDetail
  LR_SUMMARY_EVENT: LrSummaryConversationEventDetail
  LR_VOICEMAIL_EVENT: LrVoicemailConversationEventDetail
  PHONE_CALL_EVENT: PhoneCallConversationEventDetail
  SMS_EVENT: SmsConversationEventDetail
}[T]

export type TypedConversationEvent<T extends ConversationEventType> =
  ConversationEvent & {
    details?: ConversationEventDetailType<T>
  }

export const isConversationEvent = <T extends ConversationEventType>(
  event: ConversationEvent,
  eventType: T
): event is TypedConversationEvent<T> => event.type === eventType

export interface Conversation {
  id: number
  contactId: number
  isArchived: boolean
  isSpam: boolean
  isDeleted: boolean
  contactDisplayName: string
  mostRecentEvent: ConversationEvent
  statusId?: number
}

export interface ConversationStatusAttributes {
  id: number
  label: string
  /** Hex Color */
  color: string
}

export interface ContactTagAttributes {
  id: number
  label: string
}

export interface WebhookPageEventInterface {
  conversationId: number
  locationId: number
}

export type WebSocketPageEvent = WebhookPageEventInterface[]

export interface BillingCycleUsage {
  startDate: string
  endDate: string
  plan: string
  daysLeftInCycle: number
  usage: number
  total: number
}

export enum UserPosition {
  EMPLOYEE = 'EMPLOYEE',
  OWNER = 'OWNER',
}

export enum SmsRegistrationRepresentativePosition {
  DIRECTOR = 'DIRECTOR',
  GM = 'GM',
  VP = 'VP',
  CEO = 'CEO',
  CFO = 'CFO',
  GENERAL_COUNSEL = 'GENERAL_COUNSEL',
  OTHER = 'OTHER',
}

export enum SmsRegistrationStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
  FAILED = 'FAILED',
}

export enum SmsRegistrationBusinessIndustry {
  AUTOMOTIVE = 'AUTOMOTIVE',
  AGRICULTURE = 'AGRICULTURE',
  BANKING = 'BANKING',
  CONSUMER = 'CONSUMER',
  EDUCATION = 'EDUCATION',
  ENGINEERING = 'ENGINEERING',
  ENERGY = 'ENERGY',
  OIL_AND_GAS = 'OIL_AND_GAS',
  FAST_MOVING_CONSUMER_GOODS = 'FAST_MOVING_CONSUMER_GOODS',
  FINANCIAL = 'FINANCIAL',
  FINTECH = 'FINTECH',
  FOOD_AND_BEVERAGE = 'FOOD_AND_BEVERAGE',
  GOVERNMENT = 'GOVERNMENT',
  HEALTHCARE = 'HEALTHCARE',
  HOSPITALITY = 'HOSPITALITY',
  INSURANCE = 'INSURANCE',
  LEGAL = 'LEGAL',
  MANUFACTURING = 'MANUFACTURING',
  MEDIA = 'MEDIA',
  ONLINE = 'ONLINE',
  RAW_MATERIALS = 'RAW_MATERIALS',
  REAL_ESTATE = 'REAL_ESTATE',
  RELIGION = 'RELIGION',
  RETAIL = 'RETAIL',
  JEWELRY = 'JEWELRY',
  TECHNOLOGY = 'TECHNOLOGY',
  TELECOMMUNICATIONS = 'TELECOMMUNICATIONS',
  TRANSPORTATION = 'TRANSPORTATION',
  TRAVEL = 'TRAVEL',
  ELECTRONICS = 'ELECTRONICS',
  NOT_FOR_PROFIT = 'NOT_FOR_PROFIT',
}

export enum SmsRegistrationSoleProprietorshipBusinessIndustry {
  AGRICULTURE = 'AGRICULTURE',
  COMMUNICATION = 'COMMUNICATION',
  CONSTRUCTION = 'CONSTRUCTION',
  EDUCATION = 'EDUCATION',
  ENERGY = 'ENERGY',
  ENTERTAINMENT = 'ENTERTAINMENT',
  FINANCIAL = 'FINANCIAL',
  GAMBLING = 'GAMBLING',
  GOVERNMENT = 'GOVERNMENT',
  HEALTHCARE = 'HEALTHCARE',
  HOSPITALITY = 'HOSPITALITY',
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
  INSURANCE = 'INSURANCE',
  LEGAL = 'LEGAL',
  MANUFACTURING = 'MANUFACTURING',
  NGO = 'NGO',
  POLITICAL = 'POLITICAL',
  POSTAL = 'POSTAL',
  PROFESSIONAL = 'PROFESSIONAL',
  REAL_ESTATE = 'REAL_ESTATE',
  RETAIL = 'RETAIL',
  TECHNOLOGY = 'TECHNOLOGY',
  TRANSPORTATION = 'TRANSPORTATION',
}

export enum UserPhoneNumberType {
  OFFICE = 'OFFICE',
  MOBILE = 'MOBILE',
  HOME = 'HOME',
}

export enum UserPhoneCarrier {
  AT_T = 'AT&T',
  VERIZON = 'Verizon',
  T_MOBILE = 'T-Mobile',
  SPRINT = 'Sprint',
  US_CELLULAR = 'US Cellular',
  BOOST_MOBILE = 'Boost Mobile',
  METRO_PCS = 'Metro PCS',
  CRICKET = 'Cricket',
  TRACFONE = 'Tracfone',
  XFINITY_MOBILE = 'Xfinity Mobile',
  GOOGLE_VOICE = 'Google Voice',
  OTHER = 'Other',
}

export interface LocationUserEmailAddress {
  id: number
  emailAddress: string
  canLogin: boolean
  shouldReceiveLrSummaries: boolean
  shouldReceiveUpdates: boolean
  isVerified?: boolean
}

export interface LocationUserPhoneNumber {
  id: number
  phoneNumber: string
  type: UserPhoneNumberType
  shouldReceiveLrSummaries: boolean
  shouldReceiveUpdates: boolean
  carrier?: string
}

export interface LocationUserList {
  id: number
  listId: number
}

export interface LocationUser {
  id: number
  firstName: string
  lastName: string
  emailAddresses: LocationUserEmailAddress[]
  phoneNumbers: LocationUserPhoneNumber[]
  lists: LocationUserList[]
  notes?: string
  position: UserPosition
}

export interface LocationList {
  id: number
  name: string
  type: 'USER' | 'CONTACT'
  emailAddresses: string[]
  phoneNumbers: string[]
  uniqueId: string
}

export type ResponderName = 'sms' | 'angi-leads'

export interface InstantResponseConfig {
  id?: number
  responderId: number
  responderName: ResponderName
  template?: string
  isEnabled: boolean
  sendVCard: boolean
}

export interface VCard {
  id: number
  locationId: number
  firstName: string
  lastName: string
  emailAddress: string
  addressStreet1: string
  addressStreet2?: string
  addressCity: string
  addressState: string
  addressZipCode: string
  uniqueId: string
}

export interface PhoneNumberForwardingStatus {
  forward: boolean
  forwardingPhoneNumberId?: number
  forwardingPhoneNumber?: string
}

export interface ForwardingPhoneNumber {
  id: number
  phoneNumber: string
}

export enum IntegrationMappingType {
  ANGI_LEADS = 'ANGI_LEADS',
  FACEBOOK = 'FACEBOOK',
}

export enum IntegrationMappingStatus {
  READY = 'READY',
  PENDING = 'PENDING',
}

export interface IntegrationStatus {
  status?: IntegrationMappingStatus
}
