import { format } from 'date-fns'
import InfiniteScroll from 'react-infinite-scroll-component'
// import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import AngiLeadsMessageAppointment from './AngiLeadsMessageAppointment'
import AngiLeadsMessageContent from './AngiLeadsMessageContent'
import LiveReceptionistMessageRecording from './LiveReceptionistMessageRecording'
import { MessageBubbleIcon } from './MessageBubble/hooks'
import {
  ConversationEventDirection,
  ConversationEventTypeEnum,
  isConversationEvent,
} from 'src/api'
// import HomeAdvisorMessageContent from 'src/components/MessagingHub/MessagesPane/HomeAdvisorMessageContent'
import LiveReceptionistMessageContent from 'src/components/MessagingHub/MessagesPane/LiveReceptionistMessageContent'
import MessageBubble from 'src/components/MessagingHub/MessagesPane/MessageBubble'
import { useConversationMessagesContext } from 'src/contexts/ConversationMessagesContext'
// import PhoneCallRecordingPlayer from 'src/components/MessagingHub/MessagesPane/PhoneCallRecordingPlayer'
// import ThumbtackLeadContent from 'src/components/MessagingHub/MessagesPane/ThumbtackLeadContent'
// import { extractFeedbackOutcome } from 'src/components/MessagingHub/styled'
// import useAccountContext from 'src/contexts/AccountContext'
import { useLocationContext } from 'src/contexts/LocationContext'
import useMhContext from 'src/contexts/MhContext'
import LoadingSpinner from 'src/stories/LoadingSpinner'

// import Constants from 'src/lib/Constants'
// import { formatDurationFromSeconds } from 'src/utils'
// import { UseLocationRouteParams } from 'src/utils/interfaces'

const StyledMessagesContainer = styled.div<{ $isDesktop: boolean }>(
  ({ theme, $isDesktop }) => ({
    height: '100%',
    overflowY: 'scroll',
    scrollbarGutter: 'stable',
    display: 'flex',
    flexDirection: 'column-reverse',
    paddingLeft: $isDesktop ? theme.space(4) : theme.space(3),
    paddingRight: theme.space(2),
  })
)

// const StyledLabel = styled.strong(() => ({ margin: 0 }))
// const StyledParagraph = styled.p(() => ({ margin: 0 }))

const MessagesList: React.FC = () => {
  const { activeLocation } = useLocationContext()
  const {
    openConversationId,
    messages,
    infoEvents,
    isLargeDesktop: isDesktop,
    openConversationContactDetails,
  } = useMhContext()
  const { getMore, hasMoreMessages } = useConversationMessagesContext()

  const locationName = activeLocation.name
  const { name: contactName } = openConversationContactDetails

  return (
    <StyledMessagesContainer
      id="scrollableMessagesList"
      data-cy="messages-list"
      $isDesktop={isDesktop}
    >
      <InfiniteScroll
        dataLength={messages.length}
        next={getMore}
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          overflow: 'hidden',
        }}
        inverse={true}
        hasMore={hasMoreMessages}
        loader={
          <div>
            <LoadingSpinner />
          </div>
        }
        scrollableTarget="scrollableMessagesList"
      >
        {infoEvents.map((ie, idx) => {
          return (
            <MessageBubble
              key={`individual-info-event-${idx}`}
              showAsLoading={false}
              body={ie.message}
              date={format(Date.now(), 'yyyy-MM-dd HH:mm:ss')}
              contactName={contactName}
              sourceType="system"
              isWarning={true}
              isSimplified={true}
              isAutomatedMessage={false}
              icon={undefined}
              isEmptyBackground={false}
              dataCy={`mh-messages-list-message-bubble-info-event${idx}`}
            />
          )
        })}
        {
          messages.reduce(
            (a, m, idx) => {
              if (
                ![
                  ConversationEventTypeEnum.ANGI_LEAD_EVENT,
                  ConversationEventTypeEnum.FACEBOOK_EVENT,
                  ConversationEventTypeEnum.LR_SUMMARY_EVENT,
                  ConversationEventTypeEnum.LR_VOICEMAIL_EVENT,
                  ConversationEventTypeEnum.PHONE_CALL_EVENT,
                  ConversationEventTypeEnum.SMS_EVENT,
                ].includes(m.type)
              ) {
                return a
              }

              const isLrSummaryEvent = isConversationEvent(
                m,
                ConversationEventTypeEnum.LR_SUMMARY_EVENT
              )
              const isLrVoicemailEvent = isConversationEvent(
                m,
                ConversationEventTypeEnum.LR_VOICEMAIL_EVENT
              )
              const isAngiLeadEvent = isConversationEvent(
                m,
                ConversationEventTypeEnum.ANGI_LEAD_EVENT
              )
              const isPhoneCallEvent = isConversationEvent(
                m,
                ConversationEventTypeEnum.PHONE_CALL_EVENT
              )
              const isSmsEvent = isConversationEvent(
                m,
                ConversationEventTypeEnum.SMS_EVENT
              )

              const timestamp = (
                m.timestamp ? new Date(m.timestamp) : new Date()
              ).toISOString()

              // Push recordings first since the list is rendered from bottom to top (in reverse)
              if (
                openConversationId &&
                (isLrSummaryEvent || isLrVoicemailEvent)
              ) {
                a.messages.push(
                  <LiveReceptionistMessageRecording
                    message={m}
                    conversationId={openConversationId}
                    contactName={contactName}
                    baseDataAttribute={`mh-messages-list-message-bubble-${idx}`}
                  />
                )
              }

              // Push appointment messages first since the list is rendered from bottom to top (in reverse)
              if (isAngiLeadEvent && m.details?.appointment?.length) {
                a.messages.push(
                  ...m.details.appointment.map(
                    (appointment, appointmentIdx) => (
                      <MessageBubble
                        key={`individual-message-${idx}-appointment-${appointmentIdx}`}
                        dataCy={`mh-messages-list-message-bubble-${idx}-appointment-${appointmentIdx}`}
                        date={timestamp}
                        contactName=""
                        sourceType={m.direction}
                        isSimplified
                      >
                        <AngiLeadsMessageAppointment
                          appointment={appointment}
                        />
                      </MessageBubble>
                    )
                  )
                )
              }

              let body = m.message
              let bodyDescription: string | undefined = undefined
              let _contactName =
                m.direction === ConversationEventDirection.OUTGOING
                  ? locationName
                  : contactName
              let customIcon: MessageBubbleIcon = m.type
              let isAutomatedMessage = false
              let media: { contentType: string; url: string }[] = []

              if (m.isInstantResponse) {
                isAutomatedMessage = true
                bodyDescription = 'Instant Response:'
                customIcon = 'Signpost'
              } else if (isPhoneCallEvent) {
                const status = (m.details?.status ?? '').replaceAll('-', ' ')

                body = status.charAt(0).toUpperCase() + status.slice(1)
                _contactName = 'Signpost Number Outbound Call'
              } else if (isLrVoicemailEvent) {
                bodyDescription = 'Live Receptionist Spam Voicemail:'
              } else if (isSmsEvent) {
                media = m.details?.attachments ?? []
              }

              a.messages.push(
                <MessageBubble
                  key={`individual-message-${idx}`}
                  dataCy={`mh-messages-list-message-bubble-${idx}`}
                  bodyDescription={bodyDescription}
                  body={body}
                  date={timestamp}
                  media={media}
                  errorMessage={m.errorMessage}
                  contactName={_contactName}
                  sourceType={m.direction}
                  icon={customIcon}
                  isAutomatedMessage={isAutomatedMessage}
                >
                  {isAngiLeadEvent && <AngiLeadsMessageContent message={m} />}
                  {isLrSummaryEvent && (
                    <LiveReceptionistMessageContent message={m} />
                  )}
                </MessageBubble>
              )

              return a
            },
            {
              messages: [] as JSX.Element[],
            }
          ).messages
        }
      </InfiniteScroll>
    </StyledMessagesContainer>
  )
}

export default MessagesList
