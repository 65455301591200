import React from 'react'
import styled from 'styled-components'

import { useGetInstantResponseConfigs } from 'src/api'
import PageLayout from 'src/components/WafLayout/PageLayout'
import {
  AngiLeadsInstantResponseSettings,
  SMSInstantResponseSettings,
} from 'src/containers/Settings/InstantResponders/components/InstantResponseSettings'
import { useLocationContext } from 'src/contexts/LocationContext'

const StyledSeparator = styled.div(({ theme }) => ({
  height: theme.space(8),
}))

const InstantResponders: React.FC = () => {
  const { locationId } = useLocationContext()
  const { data: instantResponseConfigs = [] } = useGetInstantResponseConfigs({
    locationId,
  })

  return (
    <PageLayout
      title="Instant Responders"
      subtitle="Communicate automatically with incoming calls and leads - respond even when you are busy."
      baseDataAttribute="settings-instant-responders"
    >
      {instantResponseConfigs.map((config, idx) => {
        let responder: JSX.Element | null

        switch (config.responderName) {
          case 'sms':
            responder = <SMSInstantResponseSettings config={config} />
            break
          case 'angi-leads':
            responder = <AngiLeadsInstantResponseSettings config={config} />
            break
          default:
            responder = null
        }

        return (
          <React.Fragment key={config.responderId}>
            {idx > 0 && <StyledSeparator />}
            {responder}
          </React.Fragment>
        )
      })}
    </PageLayout>
  )
}

export default InstantResponders
