import { useCallback } from 'react'
import { toast } from 'react-toastify'

import { ResponderName, useUpsertInstantResponseConfig } from 'src/api'
import logger from 'src/utils/logger'

interface UseInstantResponderParams {
  isEnabled: boolean
  responderId: number
  locationId: number
  source: ResponderName
}

interface UseInstantResponderReturn {
  onToggle: () => Promise<void>
  isToggling: boolean
  onUpdateMessage: (message: string, sendVCard: boolean) => Promise<void>
  isUpdatingMessage: boolean
}

export const useInstantResponder = ({
  isEnabled,
  locationId,
  responderId,
  source,
}: UseInstantResponderParams): UseInstantResponderReturn => {
  const { mutateAsync: upsertIrConfig, isPending } =
    useUpsertInstantResponseConfig(locationId)

  const onToggle = useCallback(async () => {
    try {
      await upsertIrConfig({
        isEnabled: !isEnabled,
        locationId,
        responderId,
      })
    } catch (error) {
      toast.error(
        `Instant responder was not ${
          !isEnabled ? 'enabled' : 'disabled'
        }, something went wrong. Please try again.`
      )
      logger.debug(`Fail on useInstantResponder onToggle - ${source} - '`, {
        error,
      })
    }
  }, [locationId, upsertIrConfig, responderId, source, isEnabled])

  const onUpdateTemplate = useCallback(
    async (message: string, sendVCard: boolean) => {
      try {
        await upsertIrConfig({
          template: message,
          locationId,
          responderId,
          sendVCard,
        })

        toast.success('Message saved')
      } catch (error) {
        toast.error(
          'Instant response was not updated, something went wrong. Please try again.'
        )

        logger.debug(
          `Error on useInstantResponder onUpdateTemplate - ${source} -`,
          {
            error,
          }
        )
      }
    },
    [locationId, upsertIrConfig, responderId, source]
  )

  return {
    onToggle,
    onUpdateMessage: onUpdateTemplate,
    isToggling: isPending,
    isUpdatingMessage: isPending,
  }
}

export * from './smsHooks'
export * from './angiLeadsHooks'
