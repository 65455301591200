import { formatRelative } from 'date-fns'
import Linkify from 'linkify-react'
import React from 'react'
import styled from 'styled-components'

import { MessageBubbleIcon, useGetMessageBubbleIcon } from './hooks'
import { ConversationEventDirection } from 'src/api'
import DisplayMedia from 'src/components/DisplayMedia'
import { displayUserFriendlyMessage } from 'src/contexts/MhContext/utils'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

type SourceType = 'system' | ConversationEventDirection

const sourceTypeConfig = {
  system: {
    alignment: 'center',
  },
  [ConversationEventDirection.OUTGOING]: {
    alignment: 'flex-end',
  },
  [ConversationEventDirection.INCOMING]: {
    alignment: 'flex-start',
  },
}

interface SharedProps {
  sourceType: SourceType
  isAutomatedMessage: boolean
  isDesktop?: boolean
  errorMessage?: string
  isWarning?: boolean
  isEmptyBackground?: boolean
  isSimplified?: boolean
  multipleImages?: boolean
}

interface StyledErrorMessageProps {
  alignRight: boolean
}

const StyledMessageItemContainer = styled.div<SharedProps>(
  ({ theme, sourceType, isDesktop, isSimplified }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: sourceTypeConfig[sourceType].alignment,
    marginTop: theme.space(isSimplified ? 3 : 6),
    maxWidth: isDesktop ? '70%' : '95%',
  })
)

const StyledMessageName = styled.h2<SharedProps>(
  ({ theme, isAutomatedMessage }) => ({
    margin: 0,
    fontWeight: 500,
    marginRight: theme.space(3),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    maxWidth: 'calc(80%)',
    color: isAutomatedMessage ? theme.colors.primary_1 : 'inherit',
  })
)

const StyledTimestamp = styled.p(({ theme }) => ({
  margin: 0,
  color: theme.colors.base_40,
}))

const StyledBubbleTitle = styled.p<SharedProps>(
  ({ theme, isAutomatedMessage }) => ({
    marginTop: 0,
    marginBottom: theme.space(1),
    color: isAutomatedMessage ? theme.colors.primary_1 : theme.colors.base_100,
    fontWeight: 500,
  })
)

const StyledMessageHeader = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  marginBottom: theme.space(2),
}))

const StyledBubble = styled.div<SharedProps>(
  ({
    theme,
    sourceType,
    isAutomatedMessage,
    errorMessage,
    isWarning,
    isEmptyBackground,
    multipleImages,
  }) => ({
    display: `${multipleImages ? 'flex' : 'block'}`,
    flexDirection: `${multipleImages ? 'column' : 'row'}`,
    alignSelf: sourceTypeConfig[sourceType].alignment,
    padding: `${theme.space(3)} ${theme.space(4)}`,
    borderRadius: '8px',
    fontSize: '1.4rem',
    lineHeight: '20px',
    border: `${errorMessage ? '2px' : '1px'} solid ${
      errorMessage
        ? theme.colors.accent_2
        : isAutomatedMessage
        ? theme.colors.primary_2
        : sourceType === ConversationEventDirection.OUTGOING
        ? theme.colors.accent_3
        : isWarning
        ? theme.colors.accent_2
        : theme.colors.base_40
    }`,
    backgroundColor: isEmptyBackground
      ? theme.colors.base_0
      : isAutomatedMessage
      ? theme.colors.primary_1_10
      : sourceType === ConversationEventDirection.OUTGOING
      ? theme.colors.accent_3_15
      : isWarning
      ? theme.colors.accent_2_15
      : theme.colors.base_5,
    color: isAutomatedMessage
      ? theme.colors.base_40
      : sourceType === ConversationEventDirection.OUTGOING
      ? theme.colors.accent_3
      : isWarning
      ? theme.colors.accent_2
      : theme.colors.base_40,
  })
)

const StyledLinkify = styled(Linkify)(({ theme }) => ({
  a: {
    color: theme.colors.primary_2,
    lineBreak: 'anywhere',
  },
  margin: 0,
  whiteSpace: 'pre-wrap',
}))

const StyledErrorMessage = styled.p<StyledErrorMessageProps>(
  ({ theme, alignRight }) => ({
    color: theme.colors.accent_2,
    marginBottom: theme.space(2),
    marginTop: theme.space(1),
    fontSize: '1.2rem',
    fontWeight: '500',
    textAlign: alignRight ? 'right' : 'left',
  })
)

interface MessageBubbleProps extends React.PropsWithChildren {
  dataCy: string
  contactName: string
  date: string
  /**
   * It can be system, outgoing or incoming message
   */
  sourceType: SourceType
  media?: { contentType: string; url: string }[]
  errorMessage?: string
  isWarning?: boolean
  isSimplified?: boolean
  isEmptyBackground?: boolean
  isAutomatedMessage?: boolean
  icon?: MessageBubbleIcon
  showAsLoading?: boolean

  // at least one of `bodyDescription` or `body` must be provided
  bodyDescription?: string
  body?: string | JSX.Element
}

const MessageBubble: React.FC<MessageBubbleProps> = React.forwardRef<
  HTMLDivElement,
  MessageBubbleProps
>(
  (
    {
      body,
      dataCy,
      contactName,
      date,
      sourceType,
      bodyDescription,
      media,
      errorMessage,
      isAutomatedMessage = false,
      isWarning = false,
      isSimplified = false,
      isEmptyBackground = false,
      icon,
      showAsLoading = false,
      children,
    },
    ref
  ) => {
    const { isLargeScreen: isLargeDesktop } = useScreenSizes()
    const failureMessage = displayUserFriendlyMessage(errorMessage)

    const titleIcon = useGetMessageBubbleIcon(icon)

    return (
      <StyledMessageItemContainer
        data-cy={dataCy}
        isDesktop={isLargeDesktop}
        sourceType={sourceType}
        isAutomatedMessage={isAutomatedMessage}
        isSimplified={isSimplified}
        errorMessage={errorMessage}
        ref={ref}
      >
        {!isSimplified && (
          <StyledMessageHeader>
            <StyledMessageName
              sourceType={sourceType}
              isAutomatedMessage={isAutomatedMessage}
              data-cy={dataCy + '-name'}
            >
              {titleIcon} {contactName}
            </StyledMessageName>
            <StyledTimestamp data-cy={dataCy + '-timestamp'}>
              {formatRelative(new Date(date), new Date())}
            </StyledTimestamp>
          </StyledMessageHeader>
        )}
        <StyledBubble
          data-cy={dataCy + '-message'}
          isWarning={isWarning}
          sourceType={sourceType}
          isAutomatedMessage={isAutomatedMessage}
          errorMessage={errorMessage}
          isEmptyBackground={isEmptyBackground}
          multipleImages={!!(media?.length && media.length > 1)}
        >
          {showAsLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {bodyDescription && (
                <StyledBubbleTitle
                  sourceType={sourceType}
                  isAutomatedMessage={isAutomatedMessage}
                >
                  {bodyDescription}
                </StyledBubbleTitle>
              )}
              {media?.length && media.length > 0 ? (
                <div>
                  {body && (
                    <StyledLinkify tagName="p" options={{ target: '_blank' }}>
                      {body}
                    </StyledLinkify>
                  )}
                  {media.map((md, idx) => (
                    <React.Fragment key={`${dataCy}-media-${idx}`}>
                      {md.url && (
                        <DisplayMedia
                          url={md.url}
                          contentType={md.contentType}
                          dataCy={`${dataCy}-media-${idx}`}
                          includesText={!!body}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </div>
              ) : React.Children.toArray(children).some((child) =>
                  React.isValidElement(child)
                ) ? (
                <>{children}</>
              ) : (
                <StyledLinkify tagName="p" options={{ target: '_blank' }}>
                  {body}
                </StyledLinkify>
              )}
            </>
          )}
        </StyledBubble>

        {errorMessage && (
          <StyledErrorMessage
            alignRight={sourceType === ConversationEventDirection.OUTGOING}
          >
            {failureMessage}
          </StyledErrorMessage>
        )}
      </StyledMessageItemContainer>
    )
  }
)

export default MessageBubble
