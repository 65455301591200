import React, { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import NavbarActions from './NavbarActions'
import MobileNav from 'src/components/Header/MobileNav'
import useAccountContext from 'src/contexts/AccountContext'
import useAuthContext from 'src/contexts/AuthContext'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import { useLocationContext } from 'src/contexts/LocationContext'
import { hiddenNavbarPagesRegex } from 'src/lib/Constants'
import { useSidebarRoutes } from 'src/routes/hooks'
import Navbar, { RouteItem } from 'src/stories/Navbar'
import RoundDot from 'src/stories/RoundDot'
import { EmailIcon } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { generateLocationUrl } from 'src/utils'

const NavbarContainer = styled.div(({ theme }) => ({
  height: theme.heights.navbar,
  display: 'flex',
}))

const StyledNotificationContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: theme.space(6),
  cursor: 'pointer',
  marginLeft: theme.space(2),
}))

const StyledEmailIcon = styled(EmailIcon)(() => ({
  cursor: 'pointer',
}))

interface HeaderProps {
  pageType: 'Location' | 'MultiLocation' | ''
}

const Header: React.FC<HeaderProps> = ({ pageType }) => {
  const { locationId } = useLocationContext()
  const { pathname } = useLocation()
  const { user } = useAuthContext()
  const { locations } = useAccountContext()

  const theme = useTheme()
  const { isLargeScreen: isDesktop } = useScreenSizes()

  const { settingSidebarRoutes } = useSidebarRoutes(locationId, locations)

  const { conversations } = useConversationsListContext()

  const MessageNotification = useCallback(() => {
    const hasUnreadConversations = conversations.some(
      (c) => !c.mostRecentEvent.isRead
    )

    return (
      <StyledNotificationContainer>
        <StyledEmailIcon fill={theme.colors.base_0} />
        {hasUnreadConversations && <RoundDot />}
      </StyledNotificationContainer>
    )
  }, [conversations, theme])

  const routes = useMemo<RouteItem[]>(() => {
    if (user && locations && locations.length > 0) {
      let evaluatedPageType = pageType

      if (hiddenNavbarPagesRegex.some((r) => r.test(pathname))) {
        evaluatedPageType = ''
      }

      switch (evaluatedPageType) {
        case 'Location':
          let createdRoutes: RouteItem[] = [
            {
              to: generateLocationUrl(locationId, '/messaging-hub'),
              label: 'Messaging Hub',
              rightIcon: MessageNotification(),
            },
            {
              to: generateLocationUrl(locationId, '/insights'),
              label: 'Insights',
            },
            {
              to: generateLocationUrl(locationId, '/contacts'),
              label: 'Contacts',
            },
            {
              to: generateLocationUrl(locationId, '/settings/business'),
              label: 'Settings',
              sidebarRoutes: settingSidebarRoutes,
              additionalRoutes: ['/settings'],
            },
            {
              to: generateLocationUrl(locationId, '/integrations'),
              label: 'Integrations',
              disable: true,
            },
            {
              to: '',
              label: '',
              isSpacer: true,
            },
            {
              to: '',
              label: '',
              render: <NavbarActions key="navbar-actions" />,
            },
          ]

          return createdRoutes.filter((r) => !r.disable)
        case 'MultiLocation':
          return []
        // return pageUser
        //   ? [
        //       {
        //         to: generateUserUrl(pageUser.id, '/'),
        //         label: 'Overview',
        //       },
        //       {
        //         to: generateUserUrl(pageUser.id, '/locations'),
        //         label: 'Locations',
        //       },
        //       {
        //         to: generateUserUrl(pageUser.id, '/feedback?content=written'),
        //         label: 'Feedback',
        //       },
        //       {
        //         to: generateUserUrl(pageUser.id, '/reviews'),
        //         label: 'Reviews',
        //       },
        //       {
        //         to: generateUserUrl(
        //           pageUser.id,
        //           '/scheduled-campaigns/scheduled'
        //         ),
        //         label: 'Campaigns',
        //       },
        //       {
        //         to: '',
        //         label: '',
        //         isSpacer: true,
        //       },
        //       {
        //         to: '',
        //         label: '',
        //         render: accountDropdown(user.id),
        //       },
        //     ]
        //   : []
        default:
          return []
      }
    } else {
      return []
    }
  }, [
    user,
    locations,
    pageType,
    pathname,
    locationId,
    MessageNotification,
    settingSidebarRoutes,
  ])

  if (!routes.length) return null

  return isDesktop ? (
    <Navbar routes={routes} dark={pageType === 'MultiLocation'} />
  ) : (
    <NavbarContainer data-cy="navbar-container">
      <MobileNav routes={routes} />
      <Navbar
        routes={[
          {
            to: '',
            label: '',
            isSpacer: true,
          },
          {
            to: '',
            label: '',
            render: <NavbarActions key="navbar-actions" />,
          },
        ]}
        dark={pageType === 'MultiLocation'}
      />
    </NavbarContainer>
  )
}

export default Header
