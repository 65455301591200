import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import SharedIntegrationSettings from './SharedIntegrationSettings'
import { useExtendFacebookToken } from 'src/api'
import { useLocationContext } from 'src/contexts/LocationContext'
import { Button } from 'src/stories/Button'
import { FacebookIcon } from 'src/stories/assets'
import {
  fbLogin,
  fbLogout,
  getFacebookLoginStatus,
  initFacebookSdk,
} from 'src/utils/facebook-sdk'
import logger from 'src/utils/logger'

const FacebookIntegrationSettings: React.FC = () => {
  const theme = useTheme()
  const { locationId } = useLocationContext()
  const [isConnected, setIsConnected] = useState(false)
  const [isConnecting, setIsConnecting] = useState(false)
  // Note: This is a placeholder for the actual access token
  // that could be used to make requests to the Facebook API if needed.
  const [, setAccessToken] = useState<string>()
  const {
    mutateAsync: extendFacebookToken,
    isPending: isExtendingFacebookToken,
  } = useExtendFacebookToken()

  useEffect(() => {
    const initFacebook = async () => {
      setIsConnecting(true)
      initFacebookSdk()
      const response = await getFacebookLoginStatus()

      if (response.status === 'connected') {
        setIsConnected(true)
      }

      setIsConnecting(false)
    }

    void initFacebook()
  }, [])

  const login = async () => {
    try {
      setIsConnecting(true)
      setIsConnected(false)

      const loginStatus = await getFacebookLoginStatus()

      if (loginStatus.status === 'connected') {
        setIsConnected(true)

        return
      }

      const response = await fbLogin({
        scope: [
          'public_profile',
          'email',
          'pages_messaging',
          'pages_show_list',
        ].join(','),
      })

      if (response.status === 'connected') {
        setIsConnected(true)
        if (response.authResponse?.accessToken) {
          try {
            const requestResponse = await extendFacebookToken({
              locationId,
              accessToken: response.authResponse.accessToken,
            })

            setAccessToken(requestResponse.accessToken)
          } catch (error) {
            setAccessToken(undefined)
          }
        }
      }
    } catch (error: unknown) {
      logger.error('Error logging in with Facebook', {
        error,
      })
    } finally {
      setIsConnecting(false)
    }
  }

  const logout = async () => {
    try {
      await fbLogout()
      setIsConnected(false)
    } catch (error: unknown) {
      logger.error('Error logging out of Facebook', {
        error,
      })
    }
  }

  const isLoading = isConnecting || isExtendingFacebookToken

  return (
    <SharedIntegrationSettings
      icon={FacebookIcon}
      title="Facebook"
      description="Sync contacts, content, engagement data, and more from your other apps and platforms."
    >
      {!isConnected && (
        <Button
          action="primary"
          label="Connect"
          maxHeight={theme.space(12)}
          maxWidth={theme.space(40)}
          disabled={isLoading}
          onClick={login}
        />
      )}

      {isConnected && (
        <Button
          action="danger"
          label="Disconnect"
          style={{ height: theme.space(8) }}
          displayAsText
          onClick={logout}
        />
      )}
    </SharedIntegrationSettings>
  )
}

export default FacebookIntegrationSettings
