import React from 'react'

import LeadInstantResponseSettings from './LeadInstantResponseSettings'
import { InstantResponseSettingsProps } from './types'
import {
  useAngiLeadsIntegrationData,
  useInstantResponder,
} from 'src/containers/Settings/InstantResponders/hooks'
import { useLocationContext } from 'src/contexts/LocationContext'
import { formatPhoneNumber } from 'src/utils'

type SMSInstantResponseSettingsProps = InstantResponseSettingsProps

const SMSInstantResponseSettings: React.FC<SMSInstantResponseSettingsProps> = ({
  config: { isEnabled, responderId, responderName, template, sendVCard },
}) => {
  const { activeLocation, locationId } = useLocationContext()

  const { onToggle, onUpdateMessage, isToggling, isUpdatingMessage } =
    useInstantResponder({
      isEnabled,
      locationId,
      source: responderName,
      responderId,
    })
  const { isEnabled: shouldEnable } = useAngiLeadsIntegrationData({
    locationId,
  })

  const tooltipMessage = `Responses will be send as SMS Text Message`
  let leadSourceName = 'Angi Leads instant response'
  const enableToggling =
    shouldEnable && !!activeLocation.primaryRentedPhoneNumberId

  return (
    <LeadInstantResponseSettings
      leadSourceName={leadSourceName}
      connectable={false}
      responderName={responderName}
      enabled={shouldEnable && isEnabled}
      message={template}
      onEnablementChange={onToggle}
      isEnabling={isToggling}
      onUpdateMessage={onUpdateMessage}
      isUpdatingMessage={isUpdatingMessage}
      messageLabel="Text response to incoming Angi Leads:"
      maxLength={160}
      isToggleDisabled={!enableToggling}
      tooltipMessage={tooltipMessage}
      sendVCard={sendVCard}
      snippets={[
        {
          label: 'Location Name',
          value: `{{locationName}}`,
          tooltip: activeLocation.name,
        },
        {
          label: 'Location Phone Number',
          value: `{{locationPhoneNumber}}`,
          tooltip: formatPhoneNumber(activeLocation.phoneNumber),
        },
      ]}
    />
  )
}

export default SMSInstantResponseSettings
