import { yupResolver } from '@hookform/resolvers/yup'
import { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components'
import * as yup from 'yup'

import { DetailsLabel, DetailsText } from './styled'
import { useCreateContactChannel, useUpdateContact } from 'src/api'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import Input from 'src/stories/Input'
// import {
//   AgentzIcon,
//   AngiLeadsIcon,
//   AppointmentsIcon,
//   CloverIcon,
//   ConstantContactIcon,
//   EmailIcon,
//   FacebookIcon,
//   FreshbooksIcon,
//   GoogleIcon,
//   HousecallProIcon,
//   JobnimbusIcon,
//   LeapIcon,
//   MailchimpIcon,
//   ManuallyAddedIcon,
//   MobilePhoneIcon,
//   OfferLandingPageIcon,
//   PaypalIcon,
//   ApiIcon,
//   QuickbooksIcon,
//   ReferralIcon,
//   CompanyIcon,
//   SquareIcon,
//   StripeIcon,
//   SynchroteamIcon,
//   ThumbtackIcon,
//   TransactionIcon,
//   UploadedListIcon,
//   ZapierIcon,
//   PhoneIcon,
//   WebchatIcon,
// } from 'src/stories/assets'
import {
  formatPhoneNumber,
  isPhoneNumberValid,
  slugify,
  unFormatPhoneNumber,
} from 'src/utils'
import logger from 'src/utils/logger'

// const getIcon = (iconName = '') => {
//   const icons: Record<
//     string,
//     React.FunctionComponent<React.SVGProps<SVGSVGElement>>
//   > = {
//     agentz: AgentzIcon,
//     'constant-contact': ConstantContactIcon,
//     email: EmailIcon,
//     facebook: FacebookIcon,
//     freshbooks: FreshbooksIcon,
//     'angi-leads': AngiLeadsIcon,
//     'phone-forwarding': MobilePhoneIcon,
//     quickbooks: QuickbooksIcon,
//     signpost: CompanyIcon,
//     google: GoogleIcon,
//     thumbtack: ThumbtackIcon,
//     appointments: AppointmentsIcon,
//     housecallpro: HousecallProIcon,
//     jobnimbus: JobnimbusIcon,
//     'offer-landing-page': OfferLandingPageIcon,
//     leap: LeapIcon,
//     mailchimp: MailchimpIcon,
//     'manually-added': ManuallyAddedIcon,
//     paypal: PaypalIcon,
//     'public-api': ApiIcon,
//     referral: ReferralIcon,
//     square: SquareIcon,
//     stripe: StripeIcon,
//     transaction: TransactionIcon,
//     'uploaded-list': UploadedListIcon,
//     zapier: ZapierIcon,
//     clover: CloverIcon,
//     synchroteam: SynchroteamIcon,
//     phone: PhoneIcon,
//     webchat: WebchatIcon,
//   }

//   const Icon = icons[iconName]

//   return Icon ? <Icon fill="current" /> : null
// }

const contactFormSchema = yup
  .object({
    firstName: yup.string(),
    lastName: yup.string(),
    phone: yup
      .string()
      .test('Is valid phone', 'Please enter a valid phone number', (value) => {
        if (!value) {
          return true
        }

        return isPhoneNumberValid(value || '')
      }),
    email: yup.string().email('Please check your email address and try again.'),
  })
  .required()

// const SourceTypeSpan = styled.span(({ theme }) => ({
//   height: theme.space(3),
// }))

const StyledButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}))

const StyledNameValueContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}))

const generateDataCy = (label: string, formPanel?: boolean) =>
  `mh-contact-details-contact-info-${formPanel ? 'form-' : ''}${label}`

type ContactFormType = 'name' | 'email' | 'phone'

interface ContactForm {
  type: ContactFormType
  locationId: number
  contactId: number
  hasWrittenName: boolean
  firstName: string
  lastName: string
  phone: string
  email: string
  reloadConversations: () => Promise<void>
}

const ContactInfoSectionForm: React.FC<ContactForm> = ({
  type,
  locationId,
  contactId,
  hasWrittenName,
  firstName,
  lastName,
  phone,
  email,
  reloadConversations,
}) => {
  const theme = useTheme()
  const { closeModal } = useModalNotificationsContext()
  const { mutateAsync: updateContact } = useUpdateContact(locationId, contactId)
  const { mutateAsync: createChannel } = useCreateContactChannel(
    locationId,
    contactId
  )

  const {
    handleSubmit,
    formState: { errors: formErrors, isSubmitting, dirtyFields },
    watch,
    register,
    setValue,
  } = useForm<ContactForm>({
    reValidateMode: 'onBlur',
    resolver: yupResolver(contactFormSchema),
    defaultValues: {
      firstName: hasWrittenName ? firstName : '',
      lastName: hasWrittenName ? lastName : '',
      phone,
      email,
    },
  })

  const formWatcher = watch()

  const onSubmit = handleSubmit(async (data) => {
    if (
      (dirtyFields.firstName && data.firstName?.length === 0) ||
      (dirtyFields.lastName && data.lastName?.length === 0) ||
      (dirtyFields.email && data.email?.length === 0) ||
      (dirtyFields.phone && data.phone?.length === 0)
    ) {
      toast.error('Empty contact information is not allowed')

      return
    }

    try {
      let updated: string | undefined

      if (dirtyFields.firstName || dirtyFields.lastName) {
        updated = 'name'

        await updateContact({
          firstName: data.firstName,
          lastName: data.lastName,
        })
      }

      if (dirtyFields.email || dirtyFields.phone) {
        await createChannel({
          emailAddress: data.email,
          phoneNumber: data.phone ? unFormatPhoneNumber(data.phone) : undefined,
        })

        updated = 'phone|email'
      }

      if (!!updated) {
        await reloadConversations()
        toast.success('Contact updated successfully')
        closeModal()
      }
    } catch (error) {
      logger.error('MH - Error updating customer', { error })
      toast.error('There was an error trying to update the customer.')
    }
  })

  useEffect(() => {
    const { phone: formPhone } = formWatcher

    if (formPhone?.length === 10) {
      setValue('phone', formatPhoneNumber(formPhone))
    }
  }, [formWatcher, setValue])

  return (
    <>
      <form onSubmit={onSubmit} style={{ width: '100%' }}>
        {type === 'name' && (
          <>
            <Input
              label="First Name"
              autoComplete="firstName"
              data-cy={generateDataCy('firstName', true)}
              errors={formErrors}
              {...register('firstName')}
            />
            <Input
              label="Last Name"
              autoComplete="lastName"
              data-cy={generateDataCy('lastName', true)}
              errors={formErrors}
              {...register('lastName')}
            />
          </>
        )}
        {type === 'phone' && (
          <Input
            label="Phone"
            autoComplete="phone"
            data-cy={generateDataCy('phone', true)}
            errors={formErrors}
            {...register('phone')}
          />
        )}
        {type === 'email' && (
          <Input
            label="Email"
            autoComplete="email"
            data-cy={generateDataCy('email', true)}
            errors={formErrors}
            {...register('email')}
          />
        )}
        <StyledButtonsContainer>
          <Button
            action="secondary"
            type="button"
            label="Cancel"
            baseDataAttribute={generateDataCy('cancel', true)}
            style={{ marginRight: theme.space(6) }}
            disabled={isSubmitting}
            onClick={closeModal}
          />

          <Button
            action="primary"
            type="submit"
            baseDataAttribute={generateDataCy('merge-save', true)}
            label={'Save'}
            disabled={isSubmitting}
          />
        </StyledButtonsContainer>
      </form>
    </>
  )
}

interface Item {
  label: string
  value: string | ReactElement
}

interface Props {
  locationId: number
  contactId: number
  name: string
  firstName: string
  lastName: string
  hasWrittenName: boolean
  phone: string
  email: string
  // source: string
}

const ContactInfoSection: React.FC<Props> = ({
  locationId,
  contactId,
  name,
  firstName,
  lastName,
  hasWrittenName,
  email,
  phone,
  // source,
}) => {
  const theme = useTheme()
  const { showModal } = useModalNotificationsContext()
  const { refetch: refetchConversationsList } = useConversationsListContext()

  const formButton = useCallback(
    (type: ContactFormType, label: string) => (
      <Button
        style={{ width: theme.space(6) }}
        displayAsText
        label={label}
        baseDataAttribute={generateDataCy(`${type}-${label}-edit`, false)}
        onClick={() =>
          showModal({
            title: 'Contact Info',
            height: 'auto',
            dataCy: 'mh-contact-details-contact-info',
            width: theme.space(110),
            hideActionButtons: true,
            customBody: (
              <ContactInfoSectionForm
                type={type}
                firstName={firstName}
                lastName={lastName}
                phone={phone}
                email={email}
                hasWrittenName={hasWrittenName}
                locationId={locationId}
                contactId={contactId}
                reloadConversations={refetchConversationsList}
              />
            ),
          })
        }
      />
    ),
    [
      locationId,
      contactId,
      email,
      hasWrittenName,
      firstName,
      lastName,
      phone,
      refetchConversationsList,
      showModal,
      theme,
    ]
  )

  // const Icon = getIcon(Constants.CUSTOMERS.MappedSourceTypes[source]?.iconName)
  const items: Item[] = useMemo(() => {
    return [
      {
        label: 'Name',
        value: (
          <>
            {hasWrittenName ? (
              <StyledNameValueContainer>
                <>{name}</>
                <>{formButton('name', 'Edit')}</>
              </StyledNameValueContainer>
            ) : (
              formButton('name', 'Add')
            )}
          </>
        ),
      },
      {
        label: 'Phone',
        value: <>{phone ? phone : formButton('phone', 'Add')}</>,
      },
      {
        label: 'Email',
        value: <>{email ? email : formButton('email', 'Add')}</>,
      },
      // {
      //   label: 'Source',
      //   value: (
      //     <>
      //       {Icon}
      //       <SourceTypeSpan>
      //         {Constants.CUSTOMERS.MappedSourceTypes[source]?.displayName}
      //       </SourceTypeSpan>
      //     </>
      //   ),
      // },
    ]
  }, [hasWrittenName, name, formButton, phone, email])

  return (
    <>
      {items.map((item) => {
        const slugified = slugify(item.label)

        return (
          <div key={item.label} data-cy={generateDataCy(slugified)}>
            <DetailsLabel data-cy={`${generateDataCy(slugified)}-label`}>
              {item.label}:
            </DetailsLabel>
            <DetailsText data-cy={`${generateDataCy(slugified)}-text`}>
              {item.value}
            </DetailsText>
          </div>
        )
      })}
    </>
  )
}

export default ContactInfoSection
