import { useMemo } from 'react'
import { useTheme } from 'styled-components'

import { ConversationEventType } from 'src/api'
import {
  AngiLeadsIcon,
  PhoneIcon,
  CompanyIcon,
  FacebookMessengerIcon,
  SmsIcon,
  ReceptionistIcon,
} from 'src/stories/assets'

export type MessageBubbleIcon = ConversationEventType | 'Signpost'

export const useGetMessageBubbleIcon = (
  icon?: MessageBubbleIcon
): JSX.Element | null => {
  const theme = useTheme()

  const titleIcon = useMemo<JSX.Element | null>(() => {
    const iconStyles: React.CSSProperties = {
      position: 'relative',
      top: '2px',
    }

    const icons: Record<MessageBubbleIcon, JSX.Element> = {
      ANGI_LEAD_EVENT: <AngiLeadsIcon style={iconStyles} />,
      FACEBOOK_EVENT: <FacebookMessengerIcon style={iconStyles} />,
      LR_SUMMARY_EVENT: (
        <ReceptionistIcon style={iconStyles} fill={theme.colors.base_100} />
      ),
      LR_VOICEMAIL_EVENT: (
        <ReceptionistIcon style={iconStyles} fill={theme.colors.base_100} />
      ),
      MH_WELCOME_MESSAGE_EVENT: <CompanyIcon style={iconStyles} />,
      PHONE_CALL_EVENT: (
        <PhoneIcon style={iconStyles} fill={theme.colors.base_100} />
      ),
      Signpost: <CompanyIcon style={iconStyles} />,
      SMS_EVENT: <SmsIcon style={iconStyles} fill={theme.colors.base_100} />,
    }

    return (icon && icons[icon]) || null
  }, [icon, theme])

  return titleIcon
}
